import {
  backgroundGrey,
  linkLightBlue,
  ruleGrey
} from '../../colors'
import {
  lvpPadding,
  minimalPadding,
  mvpPadding,
  svpMargins,
  svpPadding
} from '../../spacing'
import {bold} from '../../font'
import {buttons} from '../../base-styles'
import {labels} from '../../base-styles/labels'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const tourCardTitle = {
  root: {
    ...labels.title,
    [svpTheme.breakpoints.between('md', 'lg')]: {
      paddingBottom: mvpPadding
    },
    [svpTheme.breakpoints.up('lg')]: {
      paddingBottom: lvpPadding
    },
    [svpTheme.breakpoints.down('sm')]: {
      paddingBottom: minimalPadding
    },
    fontWeight: bold
  }
}

export const tourCardButton = {
  outlined: {
    ...buttons.invertedHover,
    border: `${pxToRem(2)} solid ${linkLightBlue}`,
    height: 50,
    [svpTheme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: svpMargins
    },
    marginLeft: svpMargins,
    width: '95%'
  }
}

export const tourCardDescription = {
  container: {
    [svpTheme.breakpoints.up('md')]: {
      borderRight: `solid ${pxToRem(1)} ${ruleGrey}`
    }
  }
}

export const tourCard = {
  root: {
    backgroundColor: backgroundGrey,
    [svpTheme.breakpoints.up('md')]: {
      padding: svpPadding
    },
    [svpTheme.breakpoints.down('sm')]: {
      marginBottom: '1rem'
    },
    padding: mvpPadding
  }
}
