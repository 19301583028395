import React, {PureComponent} from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import {ReactComponent as UserIcon} from '../../img/User.svg'

class SignIn extends PureComponent {
  static propTypes = {
    ButtonComponent: PropTypes.func,
    isRedirect: PropTypes.bool,
    readySignIn: PropTypes.func.isRequired,
    signInOutContainerProps: PropTypes.shape({})
  }
  static defaultProps = {
    ButtonComponent: Button,
    isRedirect: false
  }
  render () {
    const {
      ButtonComponent,
      isRedirect,
      readySignIn,
      signInOutContainerProps,
      ...otherProps
    } = this.props
    return (
      <ButtonComponent
        {...otherProps}>
        <Grid container direction="column" {...signInOutContainerProps}>
          <Hidden smDown>
            <UserIcon fontSize="large" />
          </Hidden>
          Sign In
        </Grid>
      </ButtonComponent>
    )
  }
}

export {SignIn}
