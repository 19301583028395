import {lvpPadding, noPadding} from '../spacing'
import {bold} from '../font'
const benefit = ({palette, typography, breakpoints}) => ({
  label: {
    color: palette.primary.dark,
    fontFamily: typography.button.fontFamily,
    fontWeight: bold,
    fontSize: typography.button.fontSize,
    padding: '.75rem 0 .5rem',
    textAlign: 'center',
    width: '8.75rem',
    [breakpoints.up('md')]: {
      width: '100%'
    }
  },
  description: {
    color: palette.text.primary,
    fontFamily: typography.fontFamily.globalFont,
    fontSize: typography.body.fontSize,
    textAlign: 'center'
  },
  icon: {
    fill: palette.primary.dark
  },
  container: {
    backgroundColor: palette.background.grey,
    height: '16.5rem',
    marginTop: '1rem',
    paddingTop: '2rem',
    [breakpoints.up('md')]: {
      marginTop: '0',
      padding: [[lvpPadding * 2, lvpPadding, noPadding, lvpPadding]]
    }
  }
})

export {benefit}
