import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'
import {linkBlue} from '../../colors'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

/* eslint-disable camelcase */
export const calendarTheme = {...DefaultTheme,
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottomFocused: 'none'
      }
    },
    color: {
      ...DefaultTheme.reactDates.color,
      core: {
        ...DefaultTheme.reactDates.color.core,
        primary: linkBlue
      },
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: linkBlue,
        backgroundColor_active: linkBlue,
        backgroundColor_hover: linkBlue,
        borderColor: linkBlue,
        borderColor_active: linkBlue,
        borderColor_hover: linkBlue
      },
      selectedSpan: {
        ...DefaultTheme.reactDates.color.selectedSpan,
        backgroundColor: linkBlue,
        backgroundColor_active: linkBlue,
        backgroundColor_hover: linkBlue,
        borderColor: linkBlue,
        borderColor_active: linkBlue,
        borderColor_hover: linkBlue
      }
    },
    font: {
      size: 14,
      captionSize: 18,
      input: {
        size: 19,
        lineHeight: '26px',
        size_small: 15,
        lineHeight_small: '18px',
        letterSpacing_small: '0.2px',
        styleDisabled: 'italic'
      }
    },
    sizing: {
      inputWidth: 130,
      inputWidth_small: 97,
      arrowWidth: 24,
      [svpTheme.breakpoints.down('sm')]: {
        inputWidth: '100%',
        inputWidth_small: '100%'
      }
    }
  }
}

export const calendarStyles = {
  root: {
    height: pxToRem(50),
    '& div[role=button]': {
      border: 'none'
    },
    '& div[role=presentation]': {
      '& > div': {
        borderTop: `${pxToRem(1)} solid`,
        borderBottom: `${pxToRem(1)} solid`,
        '& > ul': {
          paddingTop: `${pxToRem(4)}`,
          paddingBottom: `${pxToRem(4)}`,
          '& > li': {
            textTransform: 'uppercase',
            fontFamily: svpTheme.typography.fontFamily
          }
        }
      }
    },
    '& table[role=presentation]': {
      marginTop: `${pxToRem(10)}`,
      '& td[role=button]': {
        border: 0,
        fontFamily: svpTheme.typography.fontFamily
      }
    },
    '& div[role=region]': {
      '& strong': {
        fontFamily: svpTheme.typography.fontFamily
      }
    }
  }
}
