import {
  backgroundGrey,
  ruleGrey,
  wyzardSuitBlue
} from '../colors'
import {forms, labels} from '../base-styles'
import {
  lvpGutters,
  mvpGutters,
  svpMargins
} from '../spacing'
import {globalFont} from '../font'
import {
  svpTheme
} from '../theme'

const {pxToRem} = svpTheme.typography

export const travelerInformation = {
  formContainer: {
    root: {
      ...forms.cardForm,
      [svpTheme.breakpoints.down('md')]: {
        padding: [[0, mvpGutters, mvpGutters, mvpGutters]]
      },
      [svpTheme.breakpoints.up('lg')]: {
        padding: [[0, lvpGutters, lvpGutters, lvpGutters]]
      },
      [svpTheme.breakpoints.down('sm')]: {
        padding: 0
      },
      borderBottom: `${pxToRem(1)} solid ${ruleGrey}`,
      margin: 0,
      marginTop: 0
    }
  },
  labelTitle: {
    root: {
      ...labels.title,
      color: wyzardSuitBlue,
      textTransform: 'uppercase',
      paddingTop: 16
    }
  },
  primaryContainer: {
    root: {
      background: backgroundGrey
    }
  },
  radioButton: {
    root: {
      color: wyzardSuitBlue,
      '&$checked': {
        color: wyzardSuitBlue
      }
    },
    checked: {}
  },
  mainTitle: {
    title: {
      ...labels.leadingLabel,
      fontFamily: globalFont,
      margin: 16
    },
    travelersContainer: {
      '& div:last-child': {
        borderBottom: 'none'
      }
    }
  },
  requiredLabel: {
    root: {
      paddingTop: svpMargins,
      [svpTheme.breakpoints.down('sm')]: {
        paddingTop: 0
      },
      ...labels.formRequiredLabel
    }
  }
}
