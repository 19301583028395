import {black, linkBlue} from '../colors'
import {bold, globalFont, priceSymbolSize} from '../font'
import {minimalMargin, minimalPadding, noPadding} from '../spacing'
import {ruleGrey} from '../index'
import {svpTheme} from '../theme'

const cardBorder = `1px solid ${ruleGrey}`

export const card = {
  name: {
    color: linkBlue,
    padding: 10,
    height: 125,
    fontFamily: globalFont,
    textTransform: 'none',
    borderTop: cardBorder,
    borderLeft: cardBorder,
    borderRight: cardBorder,
    fontSize: svpTheme.typography.body1.fontSize,
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: svpTheme.typography.pxToRem(20)
    }
  },
  actions: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    height: 65,
    border: cardBorder,
    justifyContent: 'center',
    width: '100%'
  },
  amount: {
    display: 'inline-block',
    fontFamily: globalFont,
    fontWeight: bold,
    color: black,
    paddingLeft: minimalMargin
  },
  prefix: {
    alignSelf: 'start',
    color: black,
    display: 'inline-block',
    fontFamily: globalFont,
    fontSize: priceSymbolSize,
    fontWeight: bold,
    lineHeight: 2
  },
  suffix: {
    display: 'inline-block',
    fontFamily: globalFont,
    fontSize: priceSymbolSize,
    fontWeight: bold,
    color: black,
    paddingLeft: minimalMargin
  },
  label: {
    width: '100%',
    color: black,
    lineHeight: 1,
    fontSize: priceSymbolSize
  },
  pointsAmount: {
    display: 'inline-block',
    fontFamily: globalFont,
    fontWeight: bold,
    color: black,
    padding: noPadding
  },
  dividerLabel: {
    color: black,
    fontSize: priceSymbolSize,
    fontWeight: bold
  },
  cardButton: {
    [svpTheme.breakpoints.down('sm')]: {
      padding: [[minimalPadding, noPadding]]
    }
  }
}
