import {linkBlue, ruleGrey, titaniumMember} from '../../colors'
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme'
import {bold} from '../../font'
import {mvpColumns} from '../../spacing'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

/* eslint-disable camelcase */
export const modalTheme = {
  ...DefaultTheme,
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottomFocused: 'none'
      }
    },
    color: {
      ...DefaultTheme.reactDates.color,
      core: {
        ...DefaultTheme.reactDates.color.core,
        primary: linkBlue
      },
      selected: {
        ...DefaultTheme.reactDates.color.selected,
        backgroundColor: linkBlue,
        backgroundColor_active: linkBlue,
        backgroundColor_hover: linkBlue,
        borderColor: linkBlue,
        borderColor_active: linkBlue,
        borderColor_hover: linkBlue
      },
      selectedSpan: {
        ...DefaultTheme.reactDates.color.selectedSpan,
        backgroundColor: linkBlue,
        backgroundColor_active: linkBlue,
        backgroundColor_hover: linkBlue,
        borderColor: linkBlue,
        borderColor_active: linkBlue,
        borderColor_hover: linkBlue
      }
    },
    font: {
      size: 14,
      captionSize: 20,
      input: {
        ...DefaultTheme.reactDates.font.size.input,
        size: 14
      }
    },
    sizing: {
      ...DefaultTheme.reactDates.sizing,
      inputWidth: 100,
      inputWidth_small: 20
    }
  }
}

export const modalCalendarStyles = {
  root: {
    '& input': {
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: 16,
        width: 'auto'
      }
    },
    '& > div': {
      [svpTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    '& svg[role=presentation]': {
      display: 'none'
    },
    '& div[role=presentation]': {
      '& > svg': {
        height: 16,
        width: 16
      },
      '& > div': {
        [svpTheme.breakpoints.down('sm')]: {
          width: '100%'
        },
        '& > ul': {
          [svpTheme.breakpoints.down('sm')]: {
            borderTop: `${pxToRem(1)} solid ${ruleGrey}`,
            borderBottom: `${pxToRem(1)} solid ${ruleGrey}`,
            padding: [[mvpColumns, 0, mvpColumns, 0]],
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between'
          },
          '& > li': {
            color: titaniumMember,
            textTransform: 'uppercase',
            fontFamily: svpTheme.typography.fontFamily,
            fontWeight: bold
          }
        }
      }
    },
    '& div[style="left: 0px;"]': {
      [svpTheme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    '& div[role=application][style="width: 319px;"]': {
      boxShadow: 'none',
      [svpTheme.breakpoints.down('sm')]: {
        width: '100% !important',
        '& > div > div': {
          width: '100% !important',
          '& div[role=region] > div[style^="width: 318px"]': {
            width: '100% !important',
            '& > div[style*="width: 900px;"]': {
              width: '100% !important',
              '& div + div': {
                width: '100%'
              }
            }
          }
        }
      }
    },
    '& div[role=button]': {
      border: 'none'
    },
    '& table[role=presentation]': {
      marginTop: `${pxToRem(10)}`,
      [svpTheme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: `${pxToRem(26)}`
      },
      '& td[role=button]': {
        border: 0,
        fontFamily: svpTheme.typography.fontFamily
      }
    },
    '& div[role=region]': {
      '& strong': {
        fontFamily: svpTheme.typography.fontFamily
      }
    }
  }
}
