
import React, {PureComponent} from 'react'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {buttons} from '../../styles/index'
import {withStyles} from '@material-ui/core/styles'

class UpdateButton extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    classes: PropTypes.shape({
      disabledModalButton: PropTypes.string,
      modalButton: PropTypes.string
    })
  }

  render () {
    const {
      disabled,
      classes: {disabledModalButton, modalButton},
      ...restButtonProps} = this.props
    const buttonStyles = disabled ? disabledModalButton : modalButton

    return (<Button
      disabled={disabled}
      component={Link} className={buttonStyles} {...restButtonProps}/>
    )
  }
}

const enhancer = withStyles(buttons)

export default enhancer(UpdateButton)
