import {CoreDivider, CoreImageLoader, CorePoints, CorePrice} from '@iseatz/ams-core'
import {Injectable, componentType, createRoute, withBoundary, withDependencies} from '@iseatz/framework'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import {compose} from 'redux'
import {formatNumberToLocale} from '@iseatz/ams-activities'
import {payWithPointsConverter} from '../utils'

@Injectable()
class SearchCardBase extends React.PureComponent {
  static propTypes = {
    ActionsComponent: componentType,
    ActionsProps: PropTypes.shape({}),
    ButtonComponent: componentType,
    ButtonProps: PropTypes.shape({}),
    ContainerComponent: componentType,
    ContainerProps: PropTypes.shape({}),
    ContentComponent: componentType,
    ContentProps: PropTypes.shape({}),
    ImageComponent: componentType,
    /* eslint-disable camelcase */
    item: PropTypes.shape({
      from_price: PropTypes.shape({
        cents: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
        symbol: PropTypes.string.isRequired
      }).isRequired,
      has_special_offer: PropTypes.bool,
      name: PropTypes.string.isRequired,
      photos: PropTypes.arrayOf(PropTypes.shape({
        caption: PropTypes.string,
        url: PropTypes.string.isRequired
      })).isRequired,
      xid: PropTypes.string
    }).isRequired,
    /* eslint-enable camelcase */
    LinkComponent: PropTypes.func,
    LinkProps: PropTypes.shape({}),
    NameComponent: componentType,
    NameProps: PropTypes.shape({}),
    PointsComponent: componentType,
    PointsProps: PropTypes.shape({}),
    DividerComponent: componentType,
    DividerProps: PropTypes.shape({}),
    PriceComponent: componentType,
    PriceProps: PropTypes.shape({}),
    ImageLoader: componentType,
    ImageProps: PropTypes.shape({})
  }

  static defaultProps = {
    ActionsComponent: CardActions,
    ActionsProps: {},
    ButtonComponent: Button,
    ContentComponent: CardContent,
    ContentProps: {},
    ContainerComponent: Card,
    ContainerProps: {},
    ImageComponent: CoreImageLoader,
    ImageProps: {},
    /* eslint-disable camelcase */
    item: {
      from_price: {
        cents: 0,
        currency: 'MAD',
        symbol: '#'
      },
      has_special_offer: false,
      name: '',
      photos: []
    },
    /* eslint-enable camelcase */
    LinkComponent: Link,
    LinkProps: {},
    NameComponent: Typography,
    NameProps: {},
    PointsComponent: CorePoints,
    PointsProps: {},
    DividerComponent: CoreDivider,
    DividerProps: {
      type: 'vertical'
    },
    PriceComponent: CorePrice,
    PriceProps: {}
  }

  render () {
    const {
      ActionsComponent,
      ActionsProps,
      ButtonComponent,
      ContainerComponent,
      ContainerProps,
      ContentComponent,
      ContentProps,
      item,
      LinkComponent,
      NameComponent,
      NameProps,
      PointsComponent,
      PointsProps,
      DividerComponent,
      DividerProps,
      PriceComponent,
      PriceProps,
      ImageComponent,
      ImageProps
    } = this.props

    const DetailsLink = Props => <LinkComponent
      to={createRoute('route:path.activities.details', {activityId: item.xid})}
      {...Props} />

    let imageElement = null
    if (item.photos.length > 0) {
      const photo = item.photos[0]
      imageElement = (
        <ImageComponent
          {...ImageProps}
          src={photo.url.replace(/^https?:/, '')}
          alt={photo.caption || name}
          title={photo.caption || name} />
      )
    }
    const formattedPointsAmount = formatNumberToLocale(payWithPointsConverter(item.from_price.cents))

    return (
      <ButtonComponent component={DetailsLink}>
        <ContainerComponent className="ams-activity-card" {...ContainerProps}>
          <ContentComponent className="ams-activity-card__content" {...ContentProps}>
            {imageElement}
            <NameComponent className="ams-activity-card__name" {...NameProps}>{item.name}</NameComponent>
          </ContentComponent>
          <ActionsComponent className="ams-activity-card__actions" {...ActionsProps}>
            <PointsComponent {...PointsProps} formattedPointsAmount={formattedPointsAmount} />
            <DividerComponent {...DividerProps} />
            <PriceComponent className="ams-activity-card__price" {...PriceProps} price={item.from_price} />
          </ActionsComponent>
        </ContainerComponent>
      </ButtonComponent>
    )
  }
}

const dependencies = {
  ActionsComponent: 'node:ams.activities.activityCard.actions',
  ButtonComponent: 'node:ams.activities.activityCard.button',
  ContentComponent: 'node:ams.activities.activityCard.content',
  NameComponent: 'node:ams.activities.activityCard.name',
  PointsComponent: 'node:ams.activities.activityCard.points',
  DividerComponent: 'node:ams.activities.activityCard.divider',
  PriceComponent: 'node:ams.activities.activityCard.price',
  LinkComponent: 'node:ams.activities.activityCard.link',
  ImageComponent: 'node:ams.activities.activityCard.image',
  ContainerComponent: 'node:ams.activities.activityCard.container'
}

const enhance = compose(
  withDependencies(dependencies),
  withBoundary
)

const SearchCard = enhance(SearchCardBase)

export {SearchCard}
