import React, {PureComponent} from 'react'
import {backgroundLightGrey, wyzardSuitBlue} from '../styles'
import {lvpPadding, mvpPadding, noPadding, svpPadding} from '../styles/spacing'
import {ActivitiesBanner} from '@iseatz/ams-activities'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import dictionary from '../dictionary/en'
import {getKey} from '../libs/dictionary'
import {svpTheme} from '../styles/theme'
import {withStyles} from '@material-ui/core/styles'

const StyledActivitiesBanner = withStyles({container: {
  width: 'auto',
  background: wyzardSuitBlue
}})(ActivitiesBanner)

const StyledContainer = withStyles({root: {
  background: backgroundLightGrey,
  padding: [[svpPadding, lvpPadding]],
  [svpTheme.breakpoints.down('md')]: {
    padding: [[mvpPadding, svpPadding, noPadding, svpPadding]]
  }
}})(Paper)

const StyledGrid = withStyles({root: {
  background: backgroundLightGrey
}})(Grid)

const StyledDivider = withStyles({root: {
  marginTop: '1rem'
}})(Divider)

export default class WyndhamActivitiesBanner extends PureComponent {
  render () {
    return (
      <StyledContainer>
        <StyledActivitiesBanner
          title={getKey(dictionary, 'sections.activity.components.banner.header')}
          description={getKey(dictionary, 'sections.activity.components.banner.description')}
        />
        <Hidden mdUp>
          <StyledGrid>
            <StyledDivider />
          </StyledGrid>
        </Hidden>
      </StyledContainer>
    )
  }
}
