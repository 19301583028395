import {bold, mediumTextLetterSpacing} from '../font'
import {minimalPadding} from '../spacing'
import {white} from '../colors'

export const cartBreakdownPoints = {
  points: {
    root: {
      fontWeight: bold,
      color: white,
      fontSize: 15,
      letterSpacing: mediumTextLetterSpacing
    }
  },
  amount: {
    root: {
      fontWeight: bold,
      color: white,
      fontSize: 17,
      letterSpacing: mediumTextLetterSpacing,
      padding: [[0, minimalPadding]]
    }
  },
  container: {
    container: {
      justifyContent: 'flex-start'
    }
  }
}
