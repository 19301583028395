import {
  TinyTextLetterSpacing,
  bodyTextSize,
  bold,
  buttonTextSize,
  headerTextSize,
  heroSmallTextSize,
  highlightsTextSize,
  mediumTextLetterSpacing,
  offerTextSize,
  priceSymbolSize
} from '../../font'
import {bodyGrey, wyzardSuitBlue} from '../../colors'
import {
  lvpPadding,
  lvpPageGutters,
  mvpPadding,
  superScriptPadding,
  svpPadding
} from '../../spacing'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const productDetails = {
  priceContainer: {
    container: {
      justifyContent: 'flex-end',
      '& section': {
        textAlign: 'center'
      },
      [svpTheme.breakpoints.down('sm')]: {
        marginBottom: pxToRem(mvpPadding),
        paddingTop: pxToRem(lvpPageGutters),
        justifyContent: 'flex-start',
        '& section': {
          display: 'flex'
        }
      }
    }
  },
  priceAmount: {
    root: {
      fontWeight: bold,
      color: wyzardSuitBlue,
      fontSize: headerTextSize,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: highlightsTextSize,
        lineHeight: 0.9
      }
    }
  },
  priceCents: {
    root: {
      color: wyzardSuitBlue,
      fontWeight: bold,
      fontSize: bodyTextSize,
      marginTop: superScriptPadding,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: bodyTextSize,
        marginTop: 0,
        marginRight: superScriptPadding,
        lineHeight: mediumTextLetterSpacing
      }
    }
  },
  priceLabel: {
    root: {
      color: bodyGrey,
      fontSize: offerTextSize,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: priceSymbolSize
      }
    }
  },
  pricePrefix: {
    root: {
      color: wyzardSuitBlue,
      fontWeight: bold,
      fontSize: bodyTextSize,
      marginTop: superScriptPadding,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: bodyTextSize,
        marginTop: 0,
        marginLeft: superScriptPadding,
        lineHeight: mediumTextLetterSpacing
      }
    }
  },
  priceSuffix: {
    root: {
      color: bodyGrey,
      fontSize: offerTextSize,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: priceSymbolSize
      }
    }
  },
  subtitle: {
    root: {
      color: wyzardSuitBlue,
      fontSize: heroSmallTextSize,
      letterSpacing: TinyTextLetterSpacing - 0.06,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: pxToRem(26)
      }
    }
  },
  summaryContainer: {
    container: {
      [svpTheme.breakpoints.down('sm')]: {
        marginBottom: svpPadding
      },
      [svpTheme.breakpoints.up('md')]: {
        marginBottom: mvpPadding
      },
      [svpTheme.breakpoints.up('lg')]: {
        marginBottom: lvpPadding
      }
    }
  },
  title: {
    root: {
      color: wyzardSuitBlue,
      fontWeight: bold,
      textTransform: 'uppercase',
      fontSize: buttonTextSize,
      letterSpacing: 0.56,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: pxToRem(19)
      }
    }
  }
}
