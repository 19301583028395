import {bold, headerNavFontSize} from '../font'
import {
  lvpMargins,
  lvpPadding,
  lvpPageGutters,
  mvpPadding,
  mvpSvpPageGutters
} from '../spacing'

export const loggedTitles = ({palette, typography, breakpoints}) => ({
  container: {
    padding: [[lvpPadding, lvpPageGutters]],
    [breakpoints.only('md')]: {
      padding: [[lvpMargins, mvpSvpPageGutters]]
    },
    [breakpoints.down('sm')]: {
      padding: [[mvpPadding, 0]]
    }
  },
  subTitleContainer: {
    display: 'block',
    [breakpoints.up('md')]: {
      padding: [[20, 0]]
    },
    [breakpoints.down('sm')]: {
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexGrow: 1,
      marginTop: '0.65rem'
    }
  },
  title: {
    fontSize: 48,
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    lineHeight: 1,
    fontWeight: bold,
    [breakpoints.down('sm')]: {
      fontSize: typography.h5.fontSize,
      '&:last-child': {
        marginLeft: '0.5rem'
      }
    }
  },
  smallTitle: {
    fontSize: typography.pxToRem(headerNavFontSize),
    color: palette.common.white,
    fontFamily: typography.fontFamily,
    lineHeight: 1,
    fontWeight: bold,
    [breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  mainContentContainer: {
    [breakpoints.down('sm')]: {
      padding: '.5rem'
    }
  }
})
