import React, {PureComponent} from 'react'
import {getDropdownButtonStyles, legalGrey, linkBlue} from '../styles'
import PropTypes from 'prop-types'

class DropdownAdd extends PureComponent {
  static propTypes = {
    background: PropTypes.string,
    border: PropTypes.string,
    disabled: PropTypes.bool,
    inner: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    background: 'none',
    border: linkBlue,
    disabled: false,
    inner: linkBlue,
    size: 24
  }

  constructor (props) {
    super(props)
    this.state = {
      active: false
    }
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
  }

  handleMouseDown () {
    this.setState({
      active: true
    })
  }

  handleMouseUp () {
    this.setState({
      active: false
    })
  }

  render () {
    const {disabled, size} = this.props
    const styles = getDropdownButtonStyles(this.state)

    return (
      <svg
        version="1.1"
        id="Dropdown_Plus"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        height={size}
        width={size}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseUp}>
        <g id="Background_1">
          <g>
            <path fill={disabled ? 'none' : styles.background} d="M1,23h22V1H1V23z" />
          </g>
        </g>
        <polygon
          id="Icon"
          fill={disabled ? legalGrey : styles.inner}
          points="11.4,7.9 11.4,11.4 7.9,11.4 7.9,12.6 11.4,12.6 11.4,16.1 12.6,16.1 12.6,12.6 16.1,12.6 16.1,11.4 12.6,11.4 12.6,7.9" // eslint-disable-line max-len
        />
        <g id="Border">
          <g id="Page-1">
            <g>
              <path fill={disabled ? legalGrey : styles.border} d="M24,24H0V0h24V24z M1,23h22V1H1V23z" />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export {DropdownAdd}
