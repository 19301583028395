import {
  legalGrey,
  linkBlue
} from '../colors'
import {
  lvpPageGutters,
  minimalPadding,
  mvpSvpPageGutters
} from '../spacing'
import {globalFont} from '../font'
import {svpTheme} from '../theme'

const offset = 2

export const breadcrumbs = {
  separator: {
    root: {
      paddingLeft: minimalPadding,
      paddingRight: minimalPadding,
      color: legalGrey
    }
  },
  breadcrumbsLeadingItem: {
    color: linkBlue,
    margin: [[0, 5, 2, 8]],
    fontFamily: globalFont
  },
  bar: {
    root: {
      minHeight: '2rem',
      marginLeft: lvpPageGutters + offset,
      [svpTheme.breakpoints.only('md')]: {
        marginLeft: mvpSvpPageGutters + offset
      },
      [svpTheme.breakpoints.down('sm')]: {
        marginLeft: offset,
        padding: 0
      }
    }
  },
  item: {
    root: {
      fontSize: 12,
      color: legalGrey
    }
  }
}
