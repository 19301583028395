import {formatDate, isNil} from '@iseatz/framework'
import PropTypes from 'prop-types'
import {enableFoodDelivery} from './config'
import moment from 'moment'
import {svpTheme} from './styles'

export const priceWithLocale = price => (price.cents / 100)
  .toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
export const roundedPriceWithLocale = price => (price.cents / 100)
  .toLocaleString('en-US', {maximumFractionDigits: 0})
export const formatNumberToLocale = (num, format) => Number(num).toLocaleString(format)
export const payWithPointsConverter = cents => Math.floor(((cents / 100) / 0.0035).toPrecision(12))

export const {pxToRem} = svpTheme.typography

export const dateFormat = 'YYYY-MM-DD'

/**
 * Takes a startDate and an endDate. Returns a pair
 * if both startDate and endDate are provided returns as is
 * when startDate is provided and no endDate defaults endDate to startDate
 * when endDate is provided and startDate is not provided defaults startDate to today
 *
 * @param {string} startDate date as string
 * @param {string} endDate date as string
 * @returns {Array} pair
 */
export const defaultToStartDateEndDate = (startDate, endDate) => {
  const today = formatDate(dateFormat)
  if (startDate && endDate) {
    return [
      startDate,
      endDate
    ]
  } else if (startDate && !endDate) {
    return [
      startDate,
      startDate
    ]
  } else if (!startDate && endDate) {
    return [
      today,
      endDate
    ]
  }
  return [today, today]
}

export const whenFoodDeliveryEnabled = element => {
  if (enableFoodDelivery) {
    return element
  }

  return null
}

const aYearFromNow = moment().add(1, 'year')

export const dateIsAfterYearFromNow = day => day.isAfter(aYearFromNow)

export const searchContainsMandatoryParams = params => !(isNil(params.startDate) && isNil(params.endDate))

export const addDateParamsIfNotPresent = params => {
  const [startDate, endDate] = defaultToStartDateEndDate(params.startDate, params.endDate)
  const {category, priceRange, specialOffers, ...restParams} = params
  return {
    ...restParams,
    startDate,
    endDate,
    facets: {
      categories: [].concat(category),
      priceRanges: [].concat(priceRange),
      special: [].concat(specialOffers ? 'specialOffers' : [])
    }
  }
}

export const itemType = PropTypes.shape({
  age_bands: PropTypes.arrayOf(PropTypes.shape({ // eslint-disable-line camelcase
    participants_count: PropTypes.number.isRequired, // eslint-disable-line camelcase
    name: PropTypes.string.isRequired,
    price_per_participant: PropTypes.shape({ // eslint-disable-line camelcase
      cents: PropTypes.number.isRequired,
      currency: PropTypes.string.isRequired,
      symbol: PropTypes.string.isRequired
    }),
    xid: PropTypes.string.isRequired
  })),
  price: PropTypes.shape({
    cents: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired
  })
})

export const paymentTypeKeys = {
  creditCard: 'credit_card',
  points: 'points'
}
