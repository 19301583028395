import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {modalCalendarStyles} from '../../styles/component-styles/calendar'
import {withStyles} from '@material-ui/core/styles'

class ModalWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.element,
    classes: PropTypes.shape({
      root: PropTypes.string
    })
  }
  render () {
    const {
      children,
      classes
    } = this.props

    return (
      <div className={classes.root}>
        {children}
      </div>
    )
  }
}

export const ModalCalendarWrapper = withStyles(modalCalendarStyles)(ModalWrapper)
