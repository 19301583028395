import React, {PureComponent} from 'react'
import {matchPath, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {modules} from './util'

export const withModule = WrappedComponent => {
  class WithModule extends PureComponent {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string
      }).isRequired
    }

    constructor (props) {
      super(props)
      this.getModule = this.getModule.bind(this)
    }

    getModule () {
      const {
        location
      } = this.props
      return modules.find(route => matchPath(location.pathname, {path: route.link}))
    }

    render () {
      const module = this.getModule()
      return (
        <WrappedComponent
          module={module}
          {...this.props} />
      )
    }
  }

  return compose(withRouter)(WithModule)
}
