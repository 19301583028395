import React, {PureComponent} from 'react'
import Hidden from '@material-ui/core/Hidden'
import {SearchSummary} from '@iseatz/ams-activities'

export class WyndhamSearchSummary extends PureComponent {
  render () {
    return (
      <Hidden mdUp>
        <SearchSummary/>
      </Hidden>
    )
  }
}
