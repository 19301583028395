import React, {PureComponent} from 'react'
import FoodDeliveryOrderNowForm from '../food-delivery-order-now-form/food-delivery-order-now-form'
import {OrderNowButton} from './order-now-button'
import PropTypes from 'prop-types'

class OrderNowButtonForm extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      username: PropTypes.string
    })
  }
  static defaultProps ={
    submit: false
  }

  render () {
    const {user: {username}} = this.props
    return (
      <FoodDeliveryOrderNowForm>
        <input type="hidden" name="reference_id" value={username} />
        <OrderNowButton isLoggedIn submit/>
      </FoodDeliveryOrderNowForm>
    )
  }
}

export {OrderNowButtonForm}
