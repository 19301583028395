import React, {PureComponent} from 'react'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import {searchField as styles} from '../../styles/component-styles/search-field'
import {withStyles} from '@material-ui/core/styles'

class SearchField extends PureComponent {
   static propTypes = {
     classes: PropTypes.object,
     label: PropTypes.string,
     InputProps: PropTypes.object
   }
   render () {
     const {classes, label, InputProps} = this.props

     return (
       <Paper className={classes.root} elevation={0}>
         <InputBase className={classes.input} {...InputProps} placeholder={label}/>
       </Paper>
     )
   }
}

SearchField.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SearchField)
