
import React, {PureComponent} from 'react'
import Benefit from './benefit'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {benefit as styles} from '../../styles/component-styles/benefit-section'
import {withStyles} from '@material-ui/core/styles'

class Container extends PureComponent {
    static propTypes = {
      possibilities: PropTypes.arrayOf(PropTypes.object),
      classes: PropTypes.object.isRequired
    }
    render () {
      const {possibilities, classes} = this.props
      const possibilityGrid = possibilities.map(possibility => (
        <Grid item key={possibility.label} md={3} xs={6}>
          <Benefit {...possibility} key={possibility.label}/>
        </Grid>
      ))

      return (
        <Grid container className={classes.container}>
          { possibilityGrid }
        </Grid>
      )
    }
}

export default withStyles(styles)(Container)
