import React, {Fragment, PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import dictionary from '../dictionary/en'
import {formatNumberToLocale} from '../utils'
import {getKey} from '../libs/dictionary'
import {userInformation as styles} from '../styles/component-styles/user-information'
import {withStyles} from '@material-ui/core/styles'

export class UserInformationBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    containerProps: PropTypes.shape({}),
    innerContainerProps: PropTypes.shape({}),
    firstname: PropTypes.string,
    username: PropTypes.string,
    pointbalance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pointsLabel: PropTypes.string
  }
  render () {
    const {firstname, username, pointbalance, classes, containerProps, innerContainerProps, pointsLabel} = this.props
    return (
      <Fragment>
        <Grid item md={4} xs={12} className={classes.root}>
          <Grid container alignItems="flex-end" className={classes.textContainer} {...containerProps}>
            <Grid item>
              <Grid container {...innerContainerProps}>
                <Grid item>
                  <Typography className={classes.userName}>
                    {getKey(dictionary, 'sections.activity.components.userInformation.greeting')} {firstname}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.memberNumber}>
                    #{username}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item >
              <Grid container direction="row">
                <Grid item>
                  <Typography className={classes.pointsAmount}>
                    {formatNumberToLocale(pointbalance)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.pointsLabel}>
                    {pointsLabel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

const UserInformation = withStyles(styles)(UserInformationBase)

export {UserInformation}
