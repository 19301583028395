import {Injectable, componentType, withBoundary, withDependencies} from '@iseatz/framework'
import React, {Fragment, PureComponent} from 'react'
import {checkout, selectors as rootSelectors} from '@iseatz/ams-activities'
import {payWithPointsConverter, paymentTypeKeys} from '../utils'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {selectors as profileSelectors} from '@iseatz/ams-profile'
const {actions, selectors} = checkout

const shouldDisablePoints = (pointBalance, price) => {
  const points = Number(pointBalance)
  let disablePoints = true
  if (!Number.isNaN(points) && points > payWithPointsConverter(price.cents)) {
    disablePoints = false
  }
  return disablePoints
}

@Injectable()
class PaymentToggle extends PureComponent {
  static propTypes = {
    CardRadioProps: PropTypes.shape({}),
    ContainerProps: PropTypes.shape({}),
    ControlComponent: componentType,
    disablePoints: PropTypes.bool,
    DividerComponent: PropTypes.string,
    DividerProps: PropTypes.shape({}),
    formState: ImmutablePropTypes.map.isRequired,
    GridComponent: componentType,
    isLoggedIn: PropTypes.bool,
    message: PropTypes.string,
    MessageComponent: componentType,
    MessageProps: PropTypes.shape({}),
    MessageContainer: componentType,
    MessageContainerProps: PropTypes.shape({}),
    onChange: PropTypes.func.isRequired,
    pointsBalance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    PointsRadioProps: PropTypes.shape({}),
    price: PropTypes.shape({
      cents: PropTypes.number,
      currency: PropTypes.string,
      symbol: PropTypes.string
    }),
    RadioGroupProps: PropTypes.shape({}),
    title: PropTypes.string,
    TitleComponent: componentType,
    TitleProps: PropTypes.shape({})
  }

  static defaultProps = {
    CardRadioProps: {},
    ContainerProps: {},
    ControlComponent: Radio,
    disablePoints: false,
    DividerComponent: Divider,
    DividerProps: {},
    GridComponent: Grid,
    isLoggedIn: false,
    message: 'We\'re sorry, you don\'t have enough points to pay.',
    MessageComponent: Typography,
    MessageProps: {},
    MessageContainer: Grid,
    MessageContainerProps: {},
    pointsBalance: 0,
    PointsRadioProps: {},
    RadioGroupProps: {},
    title: 'I\'d like to Pay With:',
    TitleComponent: Typography,
    TitleProps: {}
  }

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    const {onChange} = this.props
    const {target} = event
    onChange(target.value)
  }

  componentDidMount () {
    // TODO: This is a temporary fix until we have implemented a clean up between route changes.
    // When we do, this lines should be removed after checking that payment method is being
    // changed to "new" state
    const {
      disablePoints,
      onChange,
      formState
    } = this.props
    if (disablePoints && formState === paymentTypeKeys.points) {
      onChange(paymentTypeKeys.creditCard)
    }
  }

  render () {
    const {
      CardRadioProps,
      ContainerProps,
      ControlComponent,
      disablePoints,
      DividerComponent,
      DividerProps,
      formState,
      GridComponent,
      isLoggedIn,
      message,
      MessageComponent,
      MessageProps,
      MessageContainer,
      MessageContainerProps,
      pointsBalance,
      PointsRadioProps,
      price,
      RadioGroupProps,
      title,
      TitleComponent,
      TitleProps
    } = this.props

    if (!isLoggedIn) {
      return null
    }

    // eslint-disable-next-line react/prop-types
    const insufficientPoints = shouldDisablePoints(pointsBalance, price.toJS())
    const messageSection = insufficientPoints
      ? <Fragment>
        <DividerComponent {...DividerProps} />
        <MessageContainer container {...MessageContainerProps}>
          <MessageComponent {...MessageProps}>
            {message}
          </MessageComponent>
        </MessageContainer>
      </Fragment>
      : null

    return (
      <Fragment>
        <TitleComponent {...TitleProps}>{title}</TitleComponent>
        <GridComponent container
          {...ContainerProps}>
          <RadioGroup {...RadioGroupProps}
            value={formState}
            onChange={this.handleChange}>
            <FormControlLabel value={paymentTypeKeys.creditCard}
              control={<ControlComponent checked={formState === paymentTypeKeys.creditCard} />}
              {...CardRadioProps} />
            <FormControlLabel value={paymentTypeKeys.points}
              control={<ControlComponent checked={formState === paymentTypeKeys.points} />}
              disabled={disablePoints || insufficientPoints}
              {...PointsRadioProps} />
          </RadioGroup>
        </GridComponent>
        {messageSection}
      </Fragment>
    )
  }
}

export {PaymentToggle}

const dependencies = {
  GridComponent: 'node:ams.activities.paymentToggle.container',
  MessageContainer: 'node:ams.activities.paymentToggle.messageContainer',
  ControlComponent: 'node:ams.activities.paymentToggle.control',
  TitleComponent: 'node:ams.activities.paymentToggle.title',
  MessageComponent: 'node:ams.activities.paymentToggle.message',
  DividerComponent: 'node:ams.activities.paymentToggle.divider'
}

const dependencyHoc = withDependencies(dependencies)

const mapStateToProps = state => ({
  formState: selectors.getPaymentMethod(state),
  isLoggedIn: profileSelectors.isLoggedIn(state),
  pointsBalance: profileSelectors.getPointBalance(state),
  price: rootSelectors.getProductPrice(state)
})

const mapDispatchToProps = {
  onChange: actions.updatePaymentMethod
}

const enhance = compose(
  withBoundary,
  connect(mapStateToProps, mapDispatchToProps),
  dependencyHoc
)

const ActivitiesPaymentToggle = enhance(PaymentToggle)

export {ActivitiesPaymentToggle}
