import React, {PureComponent} from 'react'
import {bindActionCreators, compose} from 'redux'
import Button from '@material-ui/core/Button'
import {Dialog} from '@iseatz/ams-core'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {ResizedClearIcon} from '../../img/clearResize'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {connect} from 'react-redux'
import {reservation} from '@iseatz/ams-activities'
import {reservationPage} from '../../styles/component-styles'
import {withStyles} from '@material-ui/core/styles'

const {actions, selectors} = reservation

class EmailModelCompactBase extends PureComponent {
  static propTypes = {
    buttonText: PropTypes.string,
    classes: PropTypes.object,
    email: PropTypes.shape({
      state: PropTypes.string,
      valid: PropTypes.bool
    }),
    emailLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    sendEmail: PropTypes.func.isRequired,
    setEmail: PropTypes.func.isRequired,
    titleText: PropTypes.string
  }
  static defaultProps = {
    buttonText: 'Submit',
    classes: {},
    email: {},
    emailLabel: 'Email Address',
    isOpen: false,
    titleText: 'Please Send My Confirmation to:'
  }

  constructor (props) {
    super(props)
    this.handleClose = this.handleClose.bind(this)
    this.handleEmailInput = this.handleEmailInput.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleClose () {
    const {
      closeModal,
      setEmail
    } = this.props
    setEmail({email: ''})
    closeModal()
  }

  handleEmailInput (event) {
    const {setEmail} = this.props
    const email = event.target.value
    setEmail({email})
  }

  handleSubmit () {
    const {
      closeModal,
      sendEmail,
      email
    } = this.props
    if (!email) {
      return
    }

    closeModal()
    sendEmail()
  }

  render () {
    const {
      buttonText,
      classes,
      email: {
        state: emailState,
        valid: emailValid
      },
      emailLabel,
      isOpen,
      titleText
    } = this.props

    const title = props => (
      <Grid container item xs={10} alignItems="center">
        <Typography {...props}/>
      </Grid>
    )

    const footer = () => (
      <Grid className={classes.buttonContainer}>
        <Button
          disabled={!emailValid}
          onClick={this.handleSubmit}
          className={classes.modalButton}>
          {buttonText}
        </Button>
      </Grid>
    )

    return (
      <Dialog
        aria-labelledby="email-booking-dialog-title"
        aria-describedby="email-booking-dialog-description"
        className={`ams-activity-email-compact-booking-modal__container ${classes.dialog}`}
        TitleComponent={title}
        TitleProps={{
          className: `ams-activity-email-compact-booking-modal__title ${classes.dialogTitle}`
        }}
        title={titleText}
        onClose={this.handleClose}
        open={isOpen}
        CloseIconComponent={ResizedClearIcon}
        CloseButtonComponent={Button}
        CloseButtonProps={{
          className: classes.closeButton
        }}
        CloseContainerProps={{
          xs: 'auto'
        }}
        FooterContent={footer}
        HeaderProps={{
          className: classes.header,
          container: true,
          wrap: 'nowrap'
        }}>
        <TextField
          autoFocus
          label={emailLabel}
          required
          fullWidth
          className={`ams-activity-email-booking-modal__input ${classes.emailInput}`}
          variant="outlined"
          onChange={this.handleEmailInput}
          value={emailState} />
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  email: {
    state: selectors.getEmail(state),
    valid: selectors.emailIsValid(state)
  }
})

const mapDispatchToProps = dispatch => bindActionCreators({
  sendEmail: actions.sendEmail,
  setEmail: actions.setEmail
}, dispatch)

const modalStyles = {
  buttonContainer: {
    ...reservationPage.emailModal.buttonContainer.container
  },
  closeButton: {
    ...reservationPage.emailModal.closeButton.root
  },
  dialog: {
    ...reservationPage.emailModal.dialog.paper
  },
  dialogTitle: {
    ...reservationPage.emailModal.dialogTitle.root
  },
  emailInput: {
    ...reservationPage.emailModal.emailInput.root
  },
  header: {
    ...reservationPage.emailModal.header.container
  },
  modalButton: {
    ...reservationPage.emailModal.modalButton.root
  }
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(modalStyles)
)

const ReservationEmailCompactModal = enhance(EmailModelCompactBase)

export {ReservationEmailCompactModal, EmailModelCompactBase}
