import {bodyGrey, ruleGrey} from '../colors'
import {globalFont} from '../font'
import {svpTheme} from '../theme'

export const searchField = {
  root: {
    padding: [[2, 4]],
    display: 'flex',
    alignItems: 'center',
    [svpTheme.breakpoints.up('md')]: {
      border: `1px solid ${ruleGrey}`
    },
    [svpTheme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${ruleGrey}`
    },
    height: 50
  },
  input: {
    margin: [[15, 0, 15, 13]],
    fontFamily: globalFont,
    color: bodyGrey,
    fontWeight: 700,
    fontSize: 16,
    width: '100%',
    '& ::placeholder': {
      fontWeight: 'normal'
    }
  }
}
