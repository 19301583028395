import {MuiThemeProvider, withStyles} from '@material-ui/core/styles'
import React, {Component} from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import {SessionToken, withSession} from '@iseatz/ams-profile'
import {calendarTheme, detailsCalendarTheme, modalTheme} from './styles/component-styles/calendar'
import {enableFoodDelivery, enableSvp} from './config'
import {lvpTheme, mvpTheme, svpTheme} from './styles/index'
import AmsActivities from '@iseatz/ams-activities'
import CssBaseline from '@material-ui/core/CssBaseline'
import {FoodDeliveryHomePage} from './pages/food-delivery/home'
import {NotFoundPage} from './pages/not-found'
import PropTypes from 'prop-types'
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import {TopScrollRoute} from '@iseatz/ams-core'
import WyndhamActivitiesBanner from './layout/activities-banner'
import {WyndhamActivitiesHomePage} from './pages/activities/home'
import WyndhamGlobalFooter from './layout/footer/footer-container'
import {WyndhamHeader} from './layout/header/header'
import {WyndhamSearchSummary} from './layout/svp'
import {compose} from 'redux'
import {isDetails} from '../src/layout/header/util'
import {resolve} from '@iseatz/framework'
import {withTracking} from '@iseatz/analytics'
import withWidth from '@material-ui/core/withWidth'

const maybeMinWidth = enableSvp ? {} : {minWidth: 768}

const globalComponentStyles = theme => ({
  root: {
    maxWidth: 1300,
    margin: 'auto',
    touchAction: 'manipulation',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden'
    },
    ...maybeMinWidth
  }
})

const GlobalComponent = props => (<div className={props.classes.root}>{props.children}</div>)
GlobalComponent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string
  })
}
const BaseComponent = withStyles(globalComponentStyles)(GlobalComponent)

const activitiesPath = resolve('route:path.activities')
const foodDeliveryPath = resolve('route:path.foodDelivery')
const primaryCategory = resolve('config:ams.activities.analytics.primaryCategory')

class AppBase extends Component {
  static propTypes = {
    location: PropTypes.shape({}),
    width: PropTypes.string
  }

  getThemes () {
    const {location, width} = this.props
    const widthBasedThemes = {
      md: {
        dateTheme: calendarTheme,
        theme: mvpTheme
      },
      lg: {
        dateTheme: calendarTheme,
        theme: lvpTheme
      },
      xl: {
        dateTheme: calendarTheme,
        theme: lvpTheme
      }
    }

    let {
      dateTheme = modalTheme,
      theme = svpTheme // eslint-disable-line prefer-const
    } = widthBasedThemes[width] || {}

    if (isDetails(location)) {
      dateTheme = detailsCalendarTheme
    }
    return {
      dateTheme,
      theme
    }
  }

  render () {
    const {
      dateTheme,
      theme
    } = this.getThemes()

    ThemedStyleSheet.registerTheme(dateTheme)

    const foodDelivery = enableFoodDelivery ? (
      <TopScrollRoute path={foodDeliveryPath} component={FoodDeliveryHomePage} />
    ) : (
      <Redirect to={activitiesPath} />
    )

    return (
      <BaseComponent>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Route render={props => <WyndhamHeader {...props} />} />
          <Route
            path={resolve('route:path.activities.search')}
            component={WyndhamSearchSummary}
          />
          <Route
            path={resolve('route:path.activities.search')}
            component={WyndhamActivitiesBanner}
          />
          <Switch>
            <TopScrollRoute exact path={activitiesPath} component={withTracking({
              pageInstanceID: 'WHG | Home Page | Activities',
              pageID: 'Home Page',
              primaryCategory,
              subCategory1: 'Activities',
              pageType: 'WHG Global | Home Page'
            })(WyndhamActivitiesHomePage)}/>
            <Route path={activitiesPath} component={AmsActivities} />
            {foodDelivery}
            <Route exact path="/">
              <Redirect to={activitiesPath} />
            </Route>
            <Route exact path={'/session-token'} component={SessionToken} />
            <TopScrollRoute component={NotFoundPage} />
          </Switch>
          <WyndhamGlobalFooter/>
        </MuiThemeProvider>
      </BaseComponent>
    )
  }
}

const enhance = compose(
  withRouter,
  withWidth(),
  withSession
)

export default enhance(AppBase)
