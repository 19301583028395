import {matchPath} from 'react-router-dom'
import {resolve} from '@iseatz/framework'

const tourRootRoute = resolve('route:path.activities')
const foodDeliveryRootPath = resolve('route:path.foodDelivery')

export const resolveHomeURL = location => {
  if (matchPath(location.pathname, {path: tourRootRoute})) {
    return tourRootRoute
  }
  return foodDeliveryRootPath
}
