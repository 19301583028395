import Paper from '@material-ui/core/Paper'
import {inputs} from '../../styles/base-styles'
import {withStyles} from '@material-ui/core/styles'

const AutocompleteBase = withStyles({
  root: {
    ...inputs.relativeSizePopover
  }
})(Paper)

const AutocompleteBaseProps = {
  PopoverProps: {
    PaperProps: {
      component: AutocompleteBase
    },
    style: {
      position: 'relative'
    }
  },
  useContainerRef: true
}

export {AutocompleteBase, AutocompleteBaseProps}
