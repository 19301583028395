import React, {Fragment, PureComponent} from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {selectors} from '@iseatz/ams-profile'

class WhenLoggedIn extends PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    orElseRender: PropTypes.element,
    loggedInComponent: PropTypes.func,
    user: ImmutablePropTypes.contains({})
  }
  render () {
    const {isLoggedIn, user, loggedInComponent: LoggedInComponent, orElseRender} = this.props
    if (isLoggedIn) {
      return (
        <LoggedInComponent user={{...user.toJS()}} />
      )
    }
    return (<Fragment>
      {orElseRender}
    </Fragment>)
  }
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  user: selectors.getUser(state)
})

export default connect(mapStateToProps)(WhenLoggedIn)
