import {buttons, labels} from '../base-styles'
import {mvpColumns, svpMargins, svpPadding} from '../spacing'
import {bold} from '../font'

export const doordashDelivery = ({palette, typography, breakpoints}) => ({
  container: {
    backgroundColor: palette.common.white,
    padding: svpPadding,
    marginTop: '2.25rem',
    marginBottom: '2.5rem',
    [breakpoints.down('md')]: {
      maxWidth: '20rem',
      marginBottom: '1.5 rem',
      marginTop: 0

    },
    [breakpoints.down('sm')]: {
      border: `1px solid ${palette.secondary.light}`,
      maxWidth: 'none'
    }
  },

  bottomText: {
    [breakpoints.down('md')]: {
      fontSize: typography.body.fontSize
    }
  },

  errorMessageContainer: {
    marginTop: svpPadding
  },

  joinTodayContainer: {
    marginTop: '1rem',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center'
    }
  },

  title: {
    color: palette.primary.dark,
    fontFamily: typography.button.fontFamily,
    fontWeight: bold,
    padding: [[mvpColumns, 0]],
    letterSpacing: 1.5,
    [breakpoints.up('lg')]: {
      marginBottom: '1rem'
    }
  },

  subtitle: {
    ...typography.subtitle1,
    [breakpoints.down('md')]: {
      fontSize: typography.body.fontSize
    }
  },

  spacedContainer: {
    marginTop: '.25rem'
  },

  textField: {
    marginTop: '.5rem',
    marginBottom: '1.25rem'
  },

  divider: {
    margin: [[svpMargins, 'auto']],
    maxWidth: '10rem'
  },

  foodDeliveryLink: {
    ...labels.hoverLink,
    fontSize: 12,
    color: palette.primary,
    fontFamily: typography.fontFamily,
    margin: 'auto 0 auto 0.5rem'
  },
  orderNowButton: {
    ...buttons.invertedHover,
    height: typography.pxToRem(50),
    border: `.125rem solid ${palette.primary.light}`,
    fontSize: 15,
    [breakpoints.only('md')]: {
      fontSize: 12
    },
    marginTop: '.5rem'
  },
  orderAndEarn: {
    [breakpoints.up('lg')]: {
      paddingRight: '1rem',
      borderRight: '1px solid #D2D2D2'
    }
  },
  orderNow: {
    [breakpoints.up('lg')]: {
      paddingLeft: '1rem'
    }
  }
})
