import React, {PureComponent} from 'react'
import {actions, selectors} from '@iseatz/ams-profile'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'
import {SignIn} from './sign-in'
import {SignOut} from './sign-out'
import {connect} from 'react-redux'
import {profileButton} from '../../styles/component-styles/profile-button'
import {signInUrl} from '../../config'
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'

const styleProfileButton = withStyles(profileButton)

class ProfileBase extends PureComponent {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  static propTypes = {
    isLoggedIn: PropTypes.bool,
    isRedirect: PropTypes.bool,
    readySignIn: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    history: PropTypes.shape({}),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
    }),
    match: PropTypes.shape({}),
    staticContext: PropTypes.shape({}),
    onClose: PropTypes.func
  }

  static defaultProps = {
    isLoggedIn: false,
    isRedirect: false
  }

  handleClick () {
    const {signOut, onClose} = this.props
    signOut()
    onClose()
  }

  render () {
    const {
      isLoggedIn,
      isRedirect,
      readySignIn,
      signOut,
      history,
      location,
      match,
      staticContext,
      ...otherProps
    } = this.props

    const ProfileButton = styleProfileButton(isLoggedIn
      ? stylingProps => <SignOut
        onClick={this.handleClick}
        {...otherProps}
        {...stylingProps}
      />
      : stylingProps => <SignIn
        href={`${signInUrl()}?destinationLocation=${encodeURIComponent(`${location.pathname}${location.search}`)}`}
        isRedirect={isRedirect}
        readySignIn={readySignIn}
        {...otherProps}
        {...stylingProps}
      />)
    return (
      <ProfileButton />
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  isRedirect: selectors.isRedirect(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  readySignIn: actions.readySignIn,
  signOut: actions.signOut
}, dispatch)

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)

const Profile = enhance(ProfileBase)

export {Profile, ProfileBase}
