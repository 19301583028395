import React, {PureComponent} from 'react'
import {lvpPageGutters, mvpSvpPageGutters, svpTheme} from '../../styles'
import {CoreLink} from '@iseatz/ams-core'
import Disclaimer from '../../layout/disclaimer/disclaimer-container'
import FoodDeliveryBanner from '../../layout/food-delivery-banner'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import {OrderNowButtonForm} from '../../layout/food-delivery-order-now-button/index'
import OrderNowContainer from '../../layout/food-delivery-order-now-container/food-delivery-order-now-container'
import PropTypes from 'prop-types'
import RewardContainer from '../../layout/rewards/container'
import Typography from '@material-ui/core/Typography'
import WhenLoggedIn from '../../layout/when-logged-in/when-logged-in'
import {compose} from 'redux'
import {loggedTitles} from '../../styles/component-styles/food-delivery-logged-main-content'
import {rewards} from './rewards'
import {rewardsContainerFoodDelivery} from '../../styles/component-styles/rewards'
import {rewardsLearnMoreUrl} from '../../config'
import {withStyles} from '@material-ui/core'

// IE-11 Block
const GridBlock = withStyles({
  root: {
    [svpTheme.breakpoints.only('md')]: {
      padding: [[0, mvpSvpPageGutters]]
    },
    [svpTheme.breakpoints.up('lg')]: {
      padding: [[0, lvpPageGutters]]
    }
  }
})(({classes, children}) => (<div className={classes.root}>{children}</div>))

const SvpOrderNowContainer = (
  <Hidden mdUp>
    <OrderNowContainer/>
  </Hidden>)

const RewardsWithLessMargin = withStyles(rewardsContainerFoodDelivery)(RewardContainer)

class FoodDeliveryHomePageBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      mainContentContainer: PropTypes.string
    })
  }

  render () {
    const {classes} = this.props
    return (
      <Grid container direction="column" alignItems="center">
        <GridBlock>
          <Grid item className={classes.mainContentContainer}>
            <WhenLoggedIn
              orElseRender={SvpOrderNowContainer}
              loggedInComponent={({user}) => (
                <Hidden mdUp>
                  <OrderNowButtonForm user={user} />
                </Hidden>)}
            >
            </WhenLoggedIn>
            <FoodDeliveryBanner />
          </Grid>
        </GridBlock>
        <GridBlock>
          <RewardsWithLessMargin
            mainTitle="Wyndham Rewards"
            subTitle="Enjoy exclusive member perks and earn points toward
            free nights."
            learnMoreCaption="Learn More"
            learnMoreTo={rewardsLearnMoreUrl}
            rewards={rewards}
          />
        </GridBlock>
        <GridBlock>
          <Disclaimer>
            <Typography variant="caption" paragraph gutterBottom>
              <sup>1</sup>
              You must order through this page to earn Wyndham Rewards points.
              Food service providers and delivery services provided to Wyndham Rewards
              members are provided by third parties and not Wyndham Rewards, Inc. and
              therefore Wyndham Rewards, Inc. disclaims any and all liability in connection
              with a Wyndham Rewards member’s purchase and use of any products or services
              provided by such food service providers or delivery services.
            </Typography>
            <Typography variant="caption" paragraph gutterBottom>
              Points only awarded for each order that is for $2.50 inclusive of fees/taxes/delivery
              fees and before discounts and credits are applied. Must create (in the case of the new
              customer offer)/have (in the case of the 250 points per eligible order offer) a valid
              DoorDash account with a valid form of accepted payment on file. Please allow up to 1
              week after completion of food delivery for points to be deposited into your Wyndham
              Rewards account. New DoorDash customer point bonus is limited to one time per Wyndham
              Rewards Member. (This limit applies regardless of bonus amount at any given time.) Visit&nbsp;
              <CoreLink
                LinkProps={{target: '_blank'}}
                to="https://dasherhelp.doordash.com/offer-terms-conditions">
                https://dasherhelp.doordash.com/offer-terms-conditions
              </CoreLink>&nbsp;for more information.
            </Typography>
            <Typography variant="caption" paragraph gutterBottom>
              <sup>2</sup>
              Participating properties only. Go Free awards at hotels require 7,500 - 30,000 points; at vacation
              club resorts and vacation rentals, 15,000 - 30,000 points per bedroom are required. Go Fast awards
              at hotels require 1,500 - 6,000 points, plus a cash payment; at vacation club resorts and vacation
              rentals, 3,000 - 6,000 points per bedroom, plus a cash payment, are required. Member must have enough
              points for all bedrooms in desired accommodation to book award nights. Award nights at hotels are for
              standard rooms. <strong>Per night resort fees may be payable, even for award nights; points can't be
              used to pay them.</strong> Cancellation policies vary by property. Subject to availability; blackout
              dates/rates, minimum length of stay requirements & other restrictions apply. Most properties: applies
              to room rate only (go free: including taxes, go fast: excluding taxes). Amount of cash required for go
              fast nights varies by property & other advance fees also typically payable at vacation club resorts and
              vacation rentals for these nights. See &nbsp;
              <CoreLink
                LinkProps={{target: '_blank'}}
                to="https://www.wyndhamrewards.com/terms">
                www.WyndhamRewards.com/terms
              </CoreLink>
              &nbsp;for more information.
            </Typography>
            <Typography variant="caption" paragraph gutterBottom>
              <sup>3</sup>
              At most properties where points can be earned, they are earned for dollars spent on the room rate only.
              Qualified Stays can be booked through the call centers or web sites of Wyndham Hotels & Resorts, directly
              with the properties or through the Wyndham Rewards app and are subject to certain restrictions, including,
              without limitation, that such stays cannot be booked via any third party website. See &nbsp;
              <CoreLink
                LinkProps={{target: '_blank'}}
                to="https://www.wyndhamrewards.com/terms">
                www.WyndhamRewards.com/terms
              </CoreLink>
              &nbsp;for more information on Qualified Stays.
            </Typography>
          </Disclaimer>
        </GridBlock>
      </Grid>
    )
  }
}

const enhance = compose(
  withStyles(loggedTitles)
)

const FoodDeliveryHomePage = enhance(FoodDeliveryHomePageBase)

export {FoodDeliveryHomePage, FoodDeliveryHomePageBase}
