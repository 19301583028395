import React, {PureComponent} from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import {ReactComponent as UserIcon} from '../../img/User.svg'

class SignOut extends PureComponent {
  static propTypes = {
    ButtonComponent: PropTypes.func,
    signInOutContainerProps: PropTypes.shape({})
  }
  static defaultProps = {
    ButtonComponent: Button
  }

  render () {
    const {
      ButtonComponent,
      signInOutContainerProps,
      ...otherProps
    } = this.props

    return (
      <ButtonComponent
        {...otherProps}>
        <Grid container direction="column" {...signInOutContainerProps}>
          <Hidden smDown>
            <UserIcon fontSize="large" />
          </Hidden>
          Sign Out
        </Grid>
      </ButtonComponent>
    )
  }
}

export {SignOut}
