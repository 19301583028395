import {DatePicker, START_DATE} from '@iseatz/datepicker'
import {Dialog, SummaryEdit} from '@iseatz/ams-core'
import React, {PureComponent} from 'react'
import {areSome, isNil, log, omit, withBoundary} from '@iseatz/framework'
import {bindActionCreators, compose} from 'redux'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {ModalCalendarWrapper} from '../calendar-wrapper/index'
import PropTypes from 'prop-types'
import UpdateButton from './update-button'
import {connect} from 'react-redux'
import {dateFormat} from '../../config'
import {dateIsAfterYearFromNow} from '../../utils'
import {dateModal} from '../../styles'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import moment from 'moment'
import {search} from '@iseatz/ams-activities'
import {withStyles} from '@material-ui/core/styles'
const {actions, selectors, utils} = search
const {createSearchUrl} = utils

class SearchDateModalBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      action: PropTypes.string,
      buttonContainer: PropTypes.string,
      closeIcon: PropTypes.string,
      dateContainer: PropTypes.string,
      dialog: PropTypes.string,
      header: PropTypes.string,
      locationButton: PropTypes.string,
      locationContainer: PropTypes.string,
      locationTitle: PropTypes.string,
      title: PropTypes.string
    }),
    criteria: ImmutablePropTypes.mapContains({}),
    nextSearchCriteria: ImmutablePropTypes.mapContains({}),
    location: PropTypes.string,
    monthsToDisplay: PropTypes.number,
    newUrl: PropTypes.string,
    onClose: PropTypes.func,
    onSwitch: PropTypes.func,
    open: PropTypes.bool,
    onChangeCriteria: PropTypes.func.isRequired
  }

  static defaultProps = {
    location: '',
    monthsToDisplay: 1,
    newUrl: '',
    onClose: () => null,
    open: false
  }

  constructor (props) {
    super(props)

    this.handleChangeDates = this.handleChangeDates.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
  }

  handleChangeDates (dates) {
    const {onChangeCriteria} = this.props
    const {endDate, startDate} = dates

    const formattedEndDate = (endDate) ? endDate.format(dateFormat) : null
    const formattedStartDate = (startDate) ? startDate.format(dateFormat) : null

    onChangeCriteria({
      endDate: formattedEndDate,
      startDate: formattedStartDate
    })
  }

  isDateInPast (day) {
    log.debug(day.format(dateFormat), moment().isAfter(day, 'day'))
    return moment().isAfter(day, 'day')
  }

  handleSwitch () {
    const {onSwitch} = this.props

    onSwitch()
  }

  render () {
    const {
      classes,
      criteria,
      location,
      monthsToDisplay,
      onClose,
      onSwitch,
      nextSearchCriteria,
      open,
      ...rest
    } = this.props

    const {endDate, startDate, location: locationCriteria} = criteria.toJS()
    const title = getKey(
      dictionary, 'sections.activity.components.activitiesSearchHeroHome.dateModal.title'
    )
    const footer = () =>
      <Grid container justify="flex-end" className={classes.buttonContainer}>
        <UpdateButton
          disabled={isNil(locationCriteria)}
          to={createSearchUrl(nextSearchCriteria)}
          onClick={onClose}>
          {getKey(
            dictionary, 'sections.activity.components.activitiesSearchHeroHome.dateModal.button'
          )}
        </UpdateButton>
      </Grid>

    return (
      <Dialog
        className={classes.dialog}
        ActionProps={{className: classes.action}}
        CloseIconComponent={CloseIcon}
        CloseIconProps={{className: classes.closeIcon}}
        HeaderProps={{
          alignItems: 'center',
          className: classes.header,
          container: true
        }}
        open={open}
        FooterContent={footer}
        onClose={onClose}
        title={title}
        TitleProps={{className: classes.title, inline: true}}
        {...omit(['onChangeCriteria'], rest)}
      >
        <Grid item className={classes.dateContainer}>
          <DatePicker
            readOnly={true}
            initialFocus={START_DATE}
            noBorder
            showCalendarOnly
            startDate={startDate ? moment(startDate) : null}
            endDate={endDate ? moment(endDate) : null}
            isOutsideRange={areSome([this.isDateInPast].concat(dateIsAfterYearFromNow))}
            onDatesChange={this.handleChangeDates}
            monthsToDisplay={monthsToDisplay}
            WrapperComponent={ModalCalendarWrapper}
          />
        </Grid>
        <Grid item className={classes.locationContainer}>
          <SummaryEdit
            title={getKey(
              dictionary, 'sections.activity.components.activitiesSearchHeroHome.dateModal.searchEdit'
            )}
            TitleComponentProps={{className: classes.locationTitle}}
            subtitle={location}
            ActionComponent={() => <Button onClick={this.handleSwitch} className={classes.locationButton}>
              {getKey(
                dictionary, 'sections.activity.components.activitiesSearchHeroHome.dateModal.locationButton'
              )}
            </Button>
            }
          />
        </Grid>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  criteria: selectors.getSearchCriteria(state),
  location: selectors.getFullLocationText(state),
  nextSearchCriteria: selectors.getNextSearchCriteria(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  onChangeCriteria: actions.changeCriteria
}, dispatch)

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withBoundary,
  withStyles(dateModal)
)

export const SearchDateModal = enhance(SearchDateModalBase)
