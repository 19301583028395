import {
  bodyGrey,
  errorRed,
  linkBlue,
  titaniumMember,
  transparentErrorRed,
  wyzardSuitBlue
} from '../colors'
import {
  bodyTextSize,
  bold,
  buttonTextSize,
  formBlockLabel,
  heroSmallTextSize,
  logoTextSize,
  mediumTextLetterSpacing
} from '../font'
import {lvpGutters, svpGutters} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography
const border = `solid ${pxToRem(1)} ${svpTheme.palette.secondary.light}`

export const labels = {
  formBlockLabel: {
    color: wyzardSuitBlue,
    fontWeight: bold,
    fontSize: formBlockLabel,
    textTransform: 'uppercase'
  },
  leadingLabelContainer: {
    marginTop: lvpGutters
  },
  leadingLabel: {
    color: wyzardSuitBlue,
    fontSize: heroSmallTextSize,
    letterSpacing: mediumTextLetterSpacing
  },
  price: {
    color: wyzardSuitBlue,
    fontWeight: bold,
    fontSize: bodyTextSize
  },
  title: {
    color: titaniumMember,
    fontWeight: bold,
    fontSize: buttonTextSize
  },
  body: {
    color: bodyGrey,
    fontSize: buttonTextSize
  },
  smallerBody: {
    fontSize: bodyTextSize
  },
  dividerLabel: {
    fontSize: bodyTextSize,
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: logoTextSize
    }
  },
  hoverLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  link: {
    color: linkBlue,
    fontSize: bodyTextSize
  },
  logo: {
    fontSize: logoTextSize
  },
  errorMessage: {
    backgroundColor: transparentErrorRed,
    color: errorRed,
    padding: svpGutters
  },
  svpSearch: {
    subtitle: {
      color: titaniumMember
    },
    title: {
      color: titaniumMember,
      fontWeight: bold
    }
  },
  modalHeader: {
    borderBottom: border,
    height: pxToRem(70),
    justifyContent: 'space-between',
    margin: 0,
    padding: 0
  },
  formRequiredLabel: {
    fontSize: svpTheme.typography.body.fontSize,
    letterSpacing: 0.51,
    textAlign: 'right'
  },
  errorContainer: {
    boxShadow: 'none'
  },
  errorText: {
    alignItems: 'center',
    color: errorRed,
    display: 'flex'
  }
}
