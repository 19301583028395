import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {calendarStyles} from '../../styles/component-styles/calendar'
import {withStyles} from '@material-ui/core/styles'

class SimpleWrapper extends PureComponent {
  static propTypes ={
    classes: PropTypes.shape({
      root: PropTypes.string
    }),
    children: PropTypes.element
  }
  render () {
    const {children, classes} = this.props
    return (
      <div className={classes.root}>
        {children}
      </div>
    )
  }
}

export const CalendarWrapper = withStyles(calendarStyles)(SimpleWrapper)
