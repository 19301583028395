import React, {PureComponent} from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {createRoute} from '@iseatz/framework'
import {destinationCard as styles} from '../styles/component-styles/featured-destinations'
import {withStyles} from '@material-ui/core/styles'

export class DestinationCard extends PureComponent {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      item: PropTypes.shape({
        id: PropTypes.string,
        category: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
        subTitle: PropTypes.string,
        title: PropTypes.string
      })
    }
    render () {
      const {classes, item: {id, category, latitude, longitude, title, subTitle = ''}} = this.props
      const linkURL = createRoute('route:path.activities.search', {latitude, longitude, category})
      return (
        <Card elevation={0}>
          <CardContent className={classes.rootContent}>
            <Link to={linkURL} className={classes.link}>
              <Grid
                container
                direction="column"
                justify="flex-end"
                className={`${classes.cardContent} ${classes[id]}`}>
                <Grid className={classes.titleContainer}>
                  <Typography className={classes.titleBar}>{title}</Typography>
                  <Typography className={classes.subTitleBar}>{subTitle}</Typography>
                </Grid>
              </Grid>
            </Link>
          </CardContent>
        </Card>
      )
    }
}

export default withStyles(styles)(DestinationCard)
