import {backgroundGrey, white} from '../colors'
import {bold, globalFont, headerNavFontSize} from '../font'
import {mediumPadding, mvpColumns, mvpSvpPageGutters} from '../spacing'
import chicago from '../../img/chicago.jpeg'
import clearwater from '../../img/clearwater.jpeg'
import napa from '../../img/napa.jpeg'
import {svpTheme} from '../theme'
import vegas from '../../img/vegas.jpeg'

export const container = ({breakpoints, palette, typography}) => ({
  root: {
    backgroundColor: palette.background.grey,
    padding: [[mediumPadding, '4.25rem', '5rem']],
    marginTop: '6.25rem',
    [breakpoints.down('md')]: {
      marginTop: '5.25rem',
      padding: [[mvpColumns, '1.25rem', '3rem']]
    },
    [breakpoints.down('sm')]: {
      marginTop: '4.25rem',
      padding: mvpSvpPageGutters
    }
  },
  title: {
    [breakpoints.down('sm')]: {
      fontSize: typography.subtitle2.fontSize
    },
    color: palette.primary.dark,
    fontFamily: typography.globalFont,
    fontSize: typography.title.fontSize,
    lineHeight: 3
  },
  cardsContainer: {
    [breakpoints.down('sm')]: {
      overflowX: 'scroll',
      width: '100%'
    }
  },
  horizontalContainer: {
    [breakpoints.down('sm')]: {
      width: 800
    }
  }
})

const cardHeight = 266

export const destinationCard = ({breakpoints, typography}) => ({
  root: {
    backgroundColor: backgroundGrey,
    boxShadow: 'none',
    padding: 0
  },
  rootContent: {
    padding: 0,
    backgroundColor: backgroundGrey
  },
  titleBar: {
    [svpTheme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: 1
    },
    background: 'none',
    lineHeight: 2,
    color: white,
    fontWeight: bold,
    fontSize: headerNavFontSize
  },
  subTitleBar: {
    [svpTheme.breakpoints.down('md')]: {
      fontSize: typography.body.fontSize,
      lineHeight: 1.5
    },
    fontFamily: globalFont,
    fontSize: 15,
    color: white,
    lineHeight: 2
  },
  titleContainer: {
    [svpTheme.breakpoints.down('sm')]: {
      padding: [[0, 15, 8, 8]]
    },
    [svpTheme.breakpoints.between('md', 'lg')]: {
      padding: [[0, 0, 20, 8]]
    },
    padding: [[0, 15, 20, 15]],
    background: `linear-gradient(0deg, rgba(0,0,0,0.65) 30%, rgba(0,0,0,0) 100%)`
  },
  cardContent: {
    listStyle: 'none'
  },
  link: {
    textDecoration: 'none'
  },
  NV: {
    [breakpoints.down('md')]: {
      height: 360
    },
    [breakpoints.down('sm')]: {
      backgroundPositionY: 420,
      height: 195,
      width: 190
    },
    height: 566,
    width: 568,
    background: `url(${vegas})`
  },
  CA: {
    [breakpoints.down('md')]: {
      height: 170,
      width: 560
    },
    [breakpoints.down('sm')]: {
      height: 195,
      width: 190
    },
    height: cardHeight,
    background: `url(${napa})`
  },
  IL: {
    [breakpoints.down('md')]: {
      height: 170,
      width: 268
    },
    [breakpoints.down('sm')]: {
      height: 195,
      width: 190
    },
    height: cardHeight,
    background: `url(${chicago})`
  },
  FL: {
    [breakpoints.down('md')]: {
      height: 170,
      width: 268
    },
    [breakpoints.down('sm')]: {
      height: 195,
      width: 190
    },
    height: cardHeight,
    background: `url(${clearwater})`
  }
})
