import {linkBlue, white} from '../colors'
import {buttons} from '../base-styles/buttons'
import {lvpGutters} from '../spacing'

const reservationButtons = {
  getTickets: {
    button: {
      root: {
        ...buttons.actionButton,
        marginTop: lvpGutters,
        textAlign: 'center',
        '&:hover': {
          backgroundColor: linkBlue,
          color: white,
          textDecorationLine: 'none'
        }
      }
    }
  },
  cancelBookingButton: {
    button: {
      root: {
        ...buttons.invertedButton,
        ...buttons.invertedHover
      }
    }
  }
}

export {reservationButtons}
