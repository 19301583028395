import '@babel/polyfill'
import './dependencies'
import './injections'
import './styles/fonts.css'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter, Route} from 'react-router-dom'
import App from './App'
import {MuiThemeProvider} from '@material-ui/core/styles'
import {Provider} from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import {actions} from '@iseatz/ams-profile'
import {createStore} from '@iseatz/framework'
import {svpTheme} from './styles/index'

const store = createStore()

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={svpTheme}>
      <BrowserRouter>
        <Route component={App} />
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'))

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)

  window.callFakeLogin = points => {
    store.dispatch(actions.signIn())
    store.dispatch(actions.internal.setProfile({
      token: '',
      user: {
        pointbalance: points
      }
    }))
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
