import {lvpPadding, mvpPadding} from '../../spacing'
import {labels} from '../../base-styles/labels'
import {svpTheme} from '../../theme'

export const tourGridTitle = {
  root: {
    ...labels.leadingLabel,
    paddingBottom: mvpPadding,
    [svpTheme.breakpoints.down('sm')]: {
      marginLeft: mvpPadding,
      marginTop: lvpPadding
    }
  }
}
