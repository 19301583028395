import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {disclaimerStyles} from '../../styles/component-styles'
import {withStyles} from '@material-ui/core/styles'

export class Disclaimer extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string
    }),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
  }
  render () {
    const {classes, children} = this.props

    return (
      <div className={classes.root}>
        {children}
      </div>
    )
  }
}

export default withStyles(disclaimerStyles)(Disclaimer)
