import React, {Fragment} from 'react'
import {rewardDescription, rewardDivider} from '../../styles'
import Divider from '@material-ui/core/Divider'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core'
const StyledTypography = withStyles(rewardDescription)(Typography)
const StyledDivider = withStyles(rewardDivider)(Divider)

export const rewards = [
  {
    id: 1,
    title: 'go free',
    subscript: 'SM',
    descriptionSection: (
      <Fragment>
        <StyledTypography>
          Book free nights at thousands of Wyndham Rewards Hotels <strong>starting at just 7,500 points.</strong>
          <sup>2</sup>
        </StyledTypography>
        <Hidden mdUp>
          <StyledDivider />
        </Hidden>
      </Fragment>
    )
  },
  {
    id: 2,
    title: 'go fast',
    subscript: 'SM',
    descriptionSection: (
      <Fragment>
        <StyledTypography>
          Get a discounted night <strong>starting at just 1,500 points.</strong><sup>2</sup>
        </StyledTypography>
        <Hidden mdUp>
          <StyledDivider />
        </Hidden>
      </Fragment>
    )
  },
  {
    id: 3,
    title: 'go get \'em',
    subscript: 'SM',
    descriptionSection: (
      <Typography>
        For every qualified stay, earn <strong>
          10 points per dollar or 1,000 points</strong>—whichever is more.<sup>3</sup>
      </Typography>
    )
  }
]
