import React, {Fragment, PureComponent} from 'react'
import {CoreBanner} from '@iseatz/ams-core'
import {ReactComponent as DoorDashLogo} from '../../img/door-dash-logo.svg'
import PropTypes from 'prop-types'
import TitleComponent from './title-container'
import Typography from '@material-ui/core/Typography'
import {compose} from 'redux'
import {resolve} from '@iseatz/framework'
import {bannerContainer as styles} from '../../styles/component-styles/cross-sell'
import {withStyles} from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

class CrossSellBase extends PureComponent {
    static propTypes = {
      buttonCaption: PropTypes.string.isRequired,
      descriptionText: PropTypes.arrayOf(PropTypes.string),
      classes: PropTypes.object.isRequired,
      descriptionTitle: PropTypes.string.isRequired,
      mainSubTitle: PropTypes.string.isRequired,
      topTitle: PropTypes.string.isRequired,
      width: PropTypes.string
    }
    render () {
      const {
        buttonCaption,
        descriptionText,
        classes,
        descriptionTitle,
        topTitle,
        width
      } = this.props

      const isSvp = (width === 'sm' || width === 'xs')
      const doorDashLogo = isSvp
        ? <DoorDashLogo height="20px" />
        : <DoorDashLogo height="34px"/>

      const FoodTitleComponent = props => <TitleComponent classes={classes} {...props} />

      const titleFragment = (
        <Fragment>
          <Typography variant="body1" align="center" gutterBottom className={classes.topTitle}>
            {topTitle}
          </Typography>
          {doorDashLogo}
          <Typography className={classes.separationLine}></Typography>
        </Fragment>
      )
      const descriptionTextAsTypography = descriptionText.map(descriptionLine => (
        <Typography align="center" className={classes.descriptionText} key={descriptionLine}>
          {descriptionLine}
        </Typography>))

      const descriptionFragment = (
        <Fragment>
          <Typography variant="body1" align="center" gutterBottom className={classes.descriptionTitle}>
            {descriptionTitle}
          </Typography>
          {descriptionTextAsTypography}
        </Fragment>
      )

      const foodDeliveryPath = resolve('route:path.foodDelivery')

      return (<CoreBanner
        className={classes.root}
        TitleComponent={FoodTitleComponent}
        title={titleFragment}
        DescriptionComponent={TitleComponent}
        description={descriptionFragment}
        link={{
          external: true,
          text: buttonCaption,
          href: foodDeliveryPath
        }}
        justify="center"
        alignItems="center"
        ContentProps={{xs: 'auto'}}
        ButtonProps={{variant: 'outlined', className: `ams-banner__link ${classes.button}`}}
        LinkProps={{md: 'auto', xs: 'auto', container: true, justify: 'center'}}
      />)
    }
}

export const CrossSell = compose(withStyles(styles), withWidth())(CrossSellBase)
