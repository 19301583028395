import {bodyTextSize, bold, globalFont, heroSmallTextSize} from '../font'
import {headerInfoColor, ruleGrey, wyzardSuitBlue} from '../colors'
import {
  lvpPageGutters,
  mvpColumns,
  mvpSvpPageGutters
} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const userInformation = {
  root: {
    [svpTheme.breakpoints.up('lg')]: {
      paddingRight: lvpPageGutters
    },
    [svpTheme.breakpoints.only('md')]: {
      paddingRight: mvpSvpPageGutters
    }
  },
  textContainer: {
    margin: [[mvpColumns, 0, mvpColumns, 0]],
    '& > div': {
      fontSize: bodyTextSize,
      fontFamily: globalFont,
      color: headerInfoColor,
      borderRight: `${pxToRem(1)} solid ${ruleGrey}`,
      padding: [[0, mvpColumns, 0, mvpColumns]],
      [svpTheme.breakpoints.down('sm')]: {
        borderRight: 'none',
        padding: 0
      }
    },
    '& div:last-child': {
      borderRight: 'none'
    }
  },
  pointsAmount: {
    fontFamily: globalFont,
    fontWeight: bold,
    marginRight: 4,
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: heroSmallTextSize,
      color: wyzardSuitBlue,
      marginRight: 6
    }
  },
  pointsLabel: {
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: heroSmallTextSize,
      color: wyzardSuitBlue
    }
  },
  tierContainer: {
    height: pxToRem(2)
  },
  userName: {
    padding: [[0, mvpColumns, 0, mvpColumns]],
    borderRight: `${pxToRem(1)} solid ${ruleGrey}`,
    [svpTheme.breakpoints.down('sm')]: {
      borderRight: 'none',
      fontSize: bodyTextSize,
      padding: 0
    }
  },
  memberNumber: {
    paddingLeft: mvpColumns,
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: bodyTextSize,
      padding: 0
    }
  }
}
