/* eslint-disable max-lines */
import * as config from '../config'
import {ActivitiesPaymentToggle, CheckoutCartBreakdown, ReservationTourBreakdown} from '../components'
import {
  activityInformationForm,
  cartBreakdownPoints,
  checkoutBase,
  checkoutBookingInfo,
  checkoutMain,
  checkoutRewards,
  confirmationBookingInfo,
  earnBar,
  featureItem,
  featureLabel,
  featuresContainer,
  paymentForm,
  paymentToggle,
  travelerInformation
} from '../styles'
import {ReactComponent as BagCoin} from '../img/Coin.svg'
import {ReactComponent as Calendar} from '../img/Calendar.svg'
import {ReactComponent as Car} from '../img/Car.svg'
import {ReactComponent as Checkbox} from '../img/Checkbox.svg'
import {ReactComponent as Clock} from '../img/Clock.svg'
import {Container} from '@iseatz/framework'
import {ReactComponent as Earn} from '../img/EarnCongrats.svg'
import Edit from '@material-ui/icons/Edit'
import {ReactComponent as Group} from '../img/Group.svg'
import {ReactComponent as Logo} from '../img/WR_Logo.svg'
import PaymentFormDisclamer from '../layout/disclaimer/payment-form-disclamer'
import React from 'react'
import {ResizedClearIcon} from '../img/clearResize'
import SizeAwareActivitiesTraveler from '../layout/svp/size-aware-traveler'
import {ReactComponent as Tickets} from '../img/Tickets.svg'
import TravelerInformationWrapper from '../layout/traveler-information/container'
import UserTierBar from '../layout/tier-bar'
import {cancellationModal} from '../styles/component-styles'
import dictionary from '../dictionary/en'
import {getKey} from '../libs/dictionary'
import {priceWithLocale} from '../utils'

Container.bind('lang:ams.activities.checkout.label', 'Checkout')

// Checkout Rewards Section
Container.bind('props:ams.activities.checkoutmain.rewards', {
  description: 'Already a Wyndham Rewards member? Enter your member number below. (Optional)',
  TitleContainerProps: {
    container: true,
    justify: 'space-between',
    alignItems: 'center'
  },
  linkText: getKey(dictionary, 'sections.activity.components.activitiesSearchHeroHome.linkCaption')
})

Container.bind('styles:ams.activities.rewardsSection.container', checkoutRewards.container)
Container.bind('props:ams.activities.rewardsSection.container', {
  direction: 'column'
})
Container.bind('props:ams.activities.rewardsSection.signUp', {
  inline: true
})
Container.bind('props:ams.activities.rewardsSection.input', {
  variant: 'outlined'
})
Container.bind('styles:ams.activities.rewardsSection.description', checkoutRewards.description)
Container.bind('component:ams.activities.rewardsSection.icon', Logo)
Container.bind('props:ams.activities.rewardsSection.icon', checkoutRewards.icon)
Container.bind('styles:ams.activities.rewardsSection.signUpContainer', checkoutRewards.signUpContainer)
Container.bind('styles:ams.activities.rewardsSection.signUp', checkoutRewards.signUp)
Container.bind('styles:ams.activities.rewardsSection.iconContainer', checkoutRewards.iconContainer)
Container.bind('styles:ams.activities.rewardsSection.link', checkoutRewards.link)
Container.bind('props:ams.activities.rewardsSection.link', {
  to: config.rewardsSignUp,
  LinkProps: {
    underline: 'none',
    target: '_blank',
    rel: 'noopener noreferrer'
  }
})

Container.bind('styles:ams.activities.checkout.points.container', checkoutRewards.pointsContainer)
Container.bind('styles:ams.activities.checkout.points.amount', checkoutRewards.amount)
Container.bind('styles:ams.activities.checkout.points.suffix', checkoutRewards.points)
Container.bind('styles:ams.activities.checkout.points.label', checkoutRewards.points)

// Tour Features
Container.bind('styles:ams.activities.tourFeatures', featuresContainer)
Container.bind('component:ams.activities.tourFeatures.durationIcon', Clock)
Container.bind('styles:ams.activities.tourFeatures.durationItemContainer', featureItem)
Container.bind('props:ams.activities.tourFeatures.durationLabel', {variant: 'subtitle1'})
Container.bind('styles:ams.activities.tourFeatures.durationLabel', featureLabel)
Container.bind('component:ams.activities.tourFeatures.eTicketIcon', Tickets)
Container.bind('styles:ams.activities.tourFeatures.eTicketItemContainer', featureItem)
Container.bind('props:ams.activities.tourFeatures.eTicketLabel', {variant: 'subtitle1'})
Container.bind('styles:ams.activities.tourFeatures.eTicketLabel', featureLabel)
Container.bind('component:ams.activities.tourFeatures.pickupIcon', Car)
Container.bind('styles:ams.activities.tourFeatures.pickupItemContainer', featureItem)
Container.bind('props:ams.activities.tourFeatures.pickupLabel', {variant: 'subtitle1'})
Container.bind('styles:ams.activities.tourFeatures.pickupLabel', featureLabel)

Container.bind('styles:ams.activities.checkout.layout', checkoutBase.pageContainer)

Container.bind('styles:ams.activities.checkoutmain.container', checkoutMain.mainContainer)
Container.bind('styles:ams.activities.checkoutmain.loader', checkoutMain.loader)

Container.bind('props:ams.activities.checkout.main', {
  defaultValidationError: getKey(dictionary, 'sections.activity.validationsMessages.error')
})
Container.bind('styles:ams.activities.checkout.error.container', checkoutMain.errorContainer)
Container.bind('styles:ams.activities.checkout.error.icon', checkoutMain.errorIcon)
Container.bind('styles:ams.activities.checkout.error.text', checkoutMain.errorText)
Container.bind('props:ams.activities.checkout.error.text', {
  variant: 'body1'
})
Container.bind('styles:ams.activities.checkoutmain.submit.button', checkoutMain.submitButton)

// Activity Information
Container.bind('styles:ams.activities.activityFields.title', activityInformationForm.label)
Container.bind('styles:ams.activities.activityFields.grid', activityInformationForm.formComponentContainer)
Container.bind('props:ams.activities.activityFields.grid', {
  elevation: 0
})
Container.bind('props:ams.activities.activityFields.input', {
  variant: 'outlined'
})
Container.bind('styles:ams.activities.activityFields.inputContainer', activityInformationForm.inputContainer)
Container.bind('props:ams.activities.activityFields.inputContainer', {
  xs: 12
})
Container.bind('styles:ams.activities.activityFields.requiredLabel.label', activityInformationForm.requiredLabel)

// Payment Form
Container.bind('styles:ams.activities.checkoutmain.paymentForm.container', paymentForm.formContainer)
Container.bind('props:ams.activities.checkoutmain.paymentForm.form', {
  BaseProps: {
    elevation: 0
  },
  InputsContainersProps: {
    elevation: 0,
    spacing: 24
  }
})
Container.bind('styles:ams.activities.paymentForm.title', paymentForm.label)
Container.bind('styles:ams.activities.paymentForm.container', paymentForm.formComponentContainer)
Container.bind('styles:ams.activities.paymentForm.card.label', paymentForm.creditCardLabel)
Container.bind('styles:ams.activities.paymentForm.requiredLabel.label', paymentForm.requiredLabel)
Container.bind('props:ams.activities.paymentForm.dropdown', {
  SelectProps: {
    MenuProps: {
      disablePortal: true,
      MenuListProps: {
        style: paymentForm.dropdownComponent.menuRoot
      }
    },
    style: paymentForm.dropdownComponent.selectComponent
  },
  variant: 'outlined'
})
Container.bind('styles:ams.activities.paymentForm.dropdown', paymentForm.dropdownComponent.dropdown)
Container.bind('styles:ams.activities.paymentForm.singleMultiField.select', paymentForm.selectItemComponent)
Container.bind('styles:ams.activities.paymentForm.expiration', paymentForm.expirationLabel)

Container.bind('styles:ams.activities.disclaimer.disclaimerContainer', paymentForm.disclaimerComponent)
Container.bind('styles:ams.activities.disclaimer.formLabel', paymentForm.disclaimerLabel)

Container.bind('component:ams.activities.disclaimer.disclaimer', PaymentFormDisclamer)

Container.bind('props:ams.activities.checkoutmain.disclaimer', {
  DisclaimerControlProps: {
    icon: <Checkbox/>,
    color: 'primary'
  }
})
// Billing information
Container.bind('styles:ams.activities.paymentForm.checkoutBillingInfo.title.label', paymentForm.creditCardLabel)
Container.bind('styles:ams.activities.paymentForm.checkoutBillingInfo.container',
  paymentForm.billingInformationComponentContainer)
Container.bind('styles:ams.activities.paymentForm.checkoutBillingInfo.dropdown', paymentForm.dropdownComponent.dropdown)
Container.bind('props:ams.activities.paymentForm.checkoutBillingInfo.dropdown', {
  SelectProps: {
    MenuProps: {
      disablePortal: true,
      MenuListProps: {
        style: paymentForm.dropdownComponent.menuRoot
      }
    },
    style: paymentForm.dropdownComponent.selectComponent
  },
  variant: 'outlined'
})

Container.bind('props:ams.activities.checkoutBillingInfo.form', {
  InputsContainersProps: {
    elevation: 0,
    spacing: 24
  }
})

// Activities Travelers
Container.bind('styles:ams.activities.traveler.base', travelerInformation.formContainer)
Container.bind('component:ams.activities.traveler.traveler', SizeAwareActivitiesTraveler)
Container.bind('props:ams.activities.traveler.traveler', {
  BaseProps: {
    spacing: 24
  }
})
Container.bind('styles:ams.activities.traveler.requiredLabel.label', travelerInformation.requiredLabel)
Container.bind('props:ams.activities.traveler.input', {
  variant: 'outlined'
})
Container.bind('styles:ams.activities.traveler.input', travelerInformation.inputComponent)
Container.bind('styles:ams.activities.traveler.dropdown', paymentForm.dropdownComponent.dropdown)
Container.bind('props:ams.activities.traveler.dropdown', {
  SelectProps: {
    MenuProps: {
      disablePortal: true,
      MenuListProps: {
        style: paymentForm.dropdownComponent.menuRoot
      }
    },
    style: paymentForm.dropdownComponent.selectComponent
  },
  variant: 'outlined'
})

Container.bind('component:ams.activities.checkoutmain.travelerInfo.container', TravelerInformationWrapper)
Container.bind('styles:ams.activities.checkoutmain.travelerInfo.container', travelerInformation.mainTitle)
Container.bind('props:ams.activities.checkoutmain.travelerInfo.container', {
  title: 'Traveler Information'
})

Container.bind('styles:ams.activities.traveler.primary', travelerInformation.radioButton)
Container.bind('styles:ams.activities.traveler.primaryContainer', travelerInformation.primaryContainer)
Container.bind('styles:ams.activities.traveler.label', travelerInformation.labelTitle)

// Checkout Product Breakdown
Container.bind('styles:ams.activities.checkout.productBreakdown.container',
  checkoutBookingInfo.productBreakdown.container)
Container.bind('styles:ams.activities.checkout.productBreakdown.title', confirmationBookingInfo.productBreakdown.title)
Container.bind('styles:ams.activities.checkout.productBreakdown.bodyContainer',
  checkoutBookingInfo.productBreakdown.list)
Container.bind('styles:ams.activities.checkout.productBreakdown.listItem',
  checkoutBookingInfo.productBreakdown.listItem)
Container.bind('styles:ams.activities.checkout.productBreakdown.link', confirmationBookingInfo.productBreakdown.link)

// Checkout Price Breakdown
Container.bind('component:ams.activities.checkout.priceBreakdown.breakdown', ReservationTourBreakdown)
Container.bind('props:ams.activities.checkout.priceBreakdown.breakdown', {
  title: 'Total Booking Cost',
  expansionLabel: 'Traveler(s)',
  ListProps: {md: 12}
})
Container.bind('props:ams.activities.checkout.cartBreakdown.priceBreakdown', {
  title: 'Price Details',
  taxes: 'No additional taxes and fees.'
})
Container.bind('props:ams.activities.checkout.priceBreakdown.taxesContainer', {
  justify: 'flex-end'
})
Container.bind('styles:ams.activities.checkout.priceBreakdown.container',
  checkoutBookingInfo.priceBreakdown.container)
Container.bind('props:ams.activities.checkout.priceBreakdown.container', {
  direction: 'column'
})
Container.bind('styles:ams.activities.checkout.priceBreakdown.header',
  confirmationBookingInfo.priceBreakdown.header)
Container.bind('styles:ams.activities.checkout.priceBreakdown.taxes',
  confirmationBookingInfo.priceBreakdown.taxes)

Container.bind('styles:ams.activities.checkout.cartBreakdown.footerText',
  confirmationBookingInfo.footer.text)
Container.bind('styles:ams.activities.checkout.cartBreakdown.footerContainer', confirmationBookingInfo.footer.container)
Container.bind('props:ams.activities.checkout.cartBreakdown.footerContainer', {
  alignContent: 'stretch',
  justify: 'space-between',
  elevation: 0
})

Container.bind('styles:ams.activities.checkout.cartBreakdown.container', confirmationBookingInfo.container)
Container.bind('styles:ams.activities.checkout.cartBreakdown.header', confirmationBookingInfo.header)
Container.bind('styles:ams.activities.checkout.cartBreakdown.footerText',
  confirmationBookingInfo.footer.text)
Container.bind('styles:ams.activities.checkout.cartBreakdown.footerContainer',
  confirmationBookingInfo.footer.container)
Container.bind('styles:ams.activities.checkout.priceBreakdown.container',
  confirmationBookingInfo.priceBreakdown.container)
Container.bind('styles:ams.activities.checkout.priceBreakdown.header',
  confirmationBookingInfo.priceBreakdown.header)
Container.bind('styles:ams.activities.checkout.priceBreakdown.taxes',
  confirmationBookingInfo.priceBreakdown.taxes)

Container.bind('component:ams.activities.checkout.productBreakdown.calendarIcon', Calendar)
Container.bind('component:ams.activities.checkout.productBreakdown.clockIcon', Clock)
Container.bind('component:ams.activities.checkout.productBreakdown.groupIcon', Group)

Container.bind('styles:ams.activities.checkout.productBreakdown.time',
  confirmationBookingInfo.productBreakdown.description)
Container.bind('styles:ams.activities.checkout.productBreakdown.date',
  confirmationBookingInfo.productBreakdown.description)
Container.bind('styles:ams.activities.checkout.productBreakdown.traveler',
  confirmationBookingInfo.productBreakdown.description)

Container.bind('props:ams.activities.checkout.cartBreakdown.product', {
  travelersLabel: 'Traveler(s)'
})

Container.bind('component:ams.activities.checkout.productHeader.icon',
  Edit)
Container.bind('styles:ams.activities.checkout.productHeader.icon',
  checkoutBookingInfo.productHead.icon)
Container.bind('styles:ams.activities.checkout.productHeader.container',
  checkoutBookingInfo.productHead.containerCheckout)
Container.bind('styles:ams.activities.checkout.productHeader.leftTitle',
  checkoutBookingInfo.productHead.leftTitleCheckout)
Container.bind('styles:ams.activities.checkout.productHeader.rightContainer',
  checkoutBookingInfo.productHead.rightContainer)

Container.bind('props:ams.activities.checkout.cartBreakdown.header', {
  leftTitle: 'My Activity Summary'
})

Container.bind('component:ams.activities.checkout.right.cartBreakdown', CheckoutCartBreakdown)
Container.bind('props:ams.activities.checkout.right.cartBreakdown', {
  footerText: 'Your total today will be paid with'
})

Container.bind('props:ams.activities.checkout.right.earn', {
  supplier: 'Wyndham'
})

Container.bind('props:ams.activities.checkout.cartBreakdown.footerPrice', {
  showCurrency: true,
  ContainerProps: {
    justify: 'flex-start',
    alignItems: 'baseline'
  },
  priceConverter: priceWithLocale
})

Container.bind('props:ams.activities.checkout.layout', {
  ContentProps: {
    xs: 12,
    md: 8,
    lg: 7
  },
  RightSidebarProps: {
    xs: 12,
    md: 4,
    lg: 5
  }
})

Container.bind('component:ams.activities.checkout.cartBreakdown.paymentIcon', BagCoin)
Container.bind('styles:ams.activities.checkout.cartBreakdown.paymentIcon', cartBreakdownPoints.icon)
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPoints.container', cartBreakdownPoints.container)
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPoints.amount', cartBreakdownPoints.amount)
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPoints.suffix', cartBreakdownPoints.points)

Container.bind('props:ams.activities.reservation.cartBreakdownFooterPoints.container', {
  alignItems: 'baseline'
})

Container.bind('styles:ams.activities.reservation.error.container', checkoutMain.errorContainer)
Container.bind('styles:ams.activities.reservation.error.icon', checkoutMain.errorIcon)
Container.bind('styles:ams.activities.reservation.error.text', checkoutMain.errorText)
Container.bind('props:ams.activities.reservation.error.text', {
  variant: 'body1'
})

Container.bind('styles:ams.activities.checkout.right.sidebar', checkoutBookingInfo.sidebar)
Container.bind('styles:ams.activities.checkout.right.container', checkoutBookingInfo.sidebarContainer)

Container.bind('styles:ams.activities.checkout.productBreakdown.traveler',
  confirmationBookingInfo.productBreakdown.iconItem)
Container.bind('styles:ams.activities.checkout.productBreakdown.time',
  confirmationBookingInfo.productBreakdown.iconItem)
Container.bind('styles:ams.activities.checkout.productBreakdown.date',
  confirmationBookingInfo.productBreakdown.iconItem)

// Payment toggle
Container.bind('component:ams.activities.checkoutmain.paymentToggle', ActivitiesPaymentToggle)
Container.bind('props:ams.activities.checkoutmain.paymentToggle', {
  CardRadioProps: {
    label: 'A Credit or Debit Card'
  },
  PointsRadioProps: {
    label: 'Wyndham Rewards Points'
  },
  RadioGroupProps: {
    row: true
  },
  message: 'We\'re sorry, you don\'t have enough Wyndham Rewards points to pay with points.'
})

Container.bind('styles:ams.activities.paymentToggle.container', paymentToggle.container)
Container.bind('styles:ams.activities.paymentToggle.messageContainer', paymentToggle.messageContainer)
Container.bind('styles:ams.activities.paymentToggle.title', paymentToggle.title)
Container.bind('styles:ams.activities.paymentToggle.message', paymentToggle.description)
Container.bind('styles:ams.activities.paymentToggle.control', paymentToggle.radio)
Container.bind('styles:ams.activities.paymentToggle.divider', paymentToggle.divider)

// Checkout Earn
Container.bind('styles:ams.activities.checkout.earn.container', earnBar.container)
Container.bind('styles:ams.activities.checkout.earn.text', earnBar.text)
Container.bind('component:ams.activities.checkout.earn.icon', Earn)
Container.bind('styles:ams.activities.checkout.earn.iconContainer', earnBar.iconContainer)
Container.bind('styles:ams.activities.checkout.earn.loyaltyLine', earnBar.loyaltyLine)
Container.bind('component:ams.activities.checkout.earn.tierBar', UserTierBar)

// Cancellation Modal
Container.bind('styles:ams.activities.checkout.termsModal.dialog', cancellationModal.dialog)
Container.bind('styles:ams.activities.checkout.termsModal.title', cancellationModal.dialogTitle)
Container.bind('styles:ams.activities.checkout.termsModal.subtitle',
  cancellationModal.dialogTermsSubtitle
)
Container.bind('component:ams.activities.checkout.termsModal.clearIcon', ResizedClearIcon)
Container.bind('styles:ams.activities.checkout.termsModal.headerContainer',
  cancellationModal.headerContainer)
Container.bind('styles:ams.activities.reservation.requiredLabel.label', cancellationModal.requiredLabel)
