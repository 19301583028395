import React, {PureComponent} from 'react'
import Divider from '@material-ui/core/Divider'
import PropTypes from 'prop-types'
import {footerDividerStyles} from '../../styles'
import {withStyles} from '@material-ui/core/styles'

class FooterDividerBase extends PureComponent {
  render () {
    const {classes} = this.props
    return (
      <Divider className={classes.divider}/>
    )
  }
}

FooterDividerBase.propTypes = {
  classes: PropTypes.shape({
    divider: PropTypes.string
  })
}

export default withStyles(footerDividerStyles)(FooterDividerBase)
