import {CoreBanner, CoreLink} from '@iseatz/ams-core'
import React, {Fragment, PureComponent} from 'react'
import {
  foodDeliveryEndBanner,
  foodDeliveryMidBanner,
  foodDeliveryStartBanner
} from '../styles/component-styles/food-delivery-banner'
import PropTypes from 'prop-types'
import {Typography} from '@material-ui/core'
import dictionary from '../dictionary/en'
import {foodDeliveryUrl} from '../config'
import {getKey} from '../libs/dictionary'
import {withStyles} from '@material-ui/core/styles'

const deliveryBannerDescription = (
  <Fragment>
    Earn 250 Wyndham Rewards points for every eligible order you place on DoorDash in the U.S.
    Plus, new DoorDash customers will earn 2,000 bonus points after placing their first eligible
    order.<sup>1</sup>
  </Fragment>)

const NotificationBanner = withStyles(foodDeliveryStartBanner)(({classes}) => (
  <CoreBanner
    title={getKey(dictionary, 'sections.activity.components.foodDeliveryBanner.notificationBanner.title').toUpperCase()}
    className={classes.container}
    TitleProps={{
      className: classes.title,
      gutterBottom: true
    }}
    DescriptionProps={{
      className: classes.description
    }}
    ContentProps={{
      className: classes.contentContainer
    }}
  />))

const DeliveryBanner = withStyles(foodDeliveryMidBanner)(({classes}) =>
  (<CoreBanner
    title={getKey(dictionary, 'sections.activity.components.foodDeliveryBanner.earnBanner.title')}
    description={deliveryBannerDescription}
    className={classes.container}
    TitleProps={{
      className: classes.title,
      gutterBottom: true
    }}
    DescriptionProps={{
      className: classes.description
    }}
    ContentProps={{
      className: classes.contentContainer
    }}
  />))

const DeliveryLink = ({classes}) => (
  <Fragment>
    <Typography className={classes.description}>
      {getKey(dictionary, 'sections.activity.components.foodDeliveryBanner.feeBanner.description')}
      <CoreLink to={foodDeliveryUrl.link} className={classes.link} target="_blank" rel="noopener noreferrer">
        {foodDeliveryUrl.text}
      </CoreLink>
      {'.'}<sup>2</sup>
    </Typography>
  </Fragment>
)
DeliveryLink.propTypes = {
  classes: PropTypes.shape({
    description: PropTypes.string,
    link: PropTypes.string
  })
}
const FeeBanner = withStyles(foodDeliveryEndBanner)(({classes}) =>
  (<CoreBanner
    title={getKey(dictionary, 'sections.activity.components.foodDeliveryBanner.feeBanner.title')}
    description={<DeliveryLink classes={classes} />}
    className={classes.container}
    TitleProps={{
      className: classes.title,
      gutterBottom: true
    }}
    DescriptionProps={{
      className: classes.description
    }}
    ContentProps={{
      className: classes.contentContainer
    }}
  />))

class FoodDeliveryBanner extends PureComponent {
  render () {
    return (
      <Fragment>
        <NotificationBanner />
        <DeliveryBanner />
        <FeeBanner />
      </Fragment>
    )
  }
}

export default FoodDeliveryBanner
