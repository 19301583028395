import {lvpMargins, mediumPadding, mvpColumns} from '../spacing'

export const disclaimerStyles = ({breakpoints}) => ({
  root: {
    maxWidth: '100%',
    marginTop: mediumPadding,
    [breakpoints.down('lg')]: {
      marginRight: mvpColumns,
      marginLeft: mvpColumns
    },
    [breakpoints.down('sm')]: {
      marginTop: lvpMargins
    }
  }
})
