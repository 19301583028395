
const getEnvVar = (key, defaultValue = null) => {
  let value = defaultValue

  if (typeof process.env[key] !== 'undefined') {
    value = process.env[key]
  }

  return value
}

export const environment = getEnvVar('NODE_ENV', 'development')

export const api = {
  baseUrl: getEnvVar('ISEATZ_API_BASEURL', 'https://wyndham-api-qa.iseatz.com/')
}

export const izRewardUrl = getEnvVar('ISEATZ_REWARDS_BASEURL', 'https://rqa.wyndhamhotels.com/wyndham-rewards')

export const foodDeliveryJoinToday = getEnvVar('ISEATZ_FOOD_DELIVERY_JOIN_NOW_BASEURL',
  'https://www.wyndhamhotels.com/wyndham-rewards/join?CID=NA:WR:20190502:ISEATZ:JOIN:WR:FOODDELIVERY-HP:NA:EN-US')

export const enableFoodDelivery = getEnvVar('ISEATZ_ENABLE_FOOD_DELIVERY', false) === 'true'

export const siteName = 'go for it'

export const imageAlt = 'Wyndham Rewards'

export const rewardsMemberIdMatcher = /^[0-9]{9}[a-z]{1}$/gi

export const validateMemberNumber = rewardsNum => [rewardsMemberIdMatcher.test(rewardsNum)]

// Loyalty Program
export const loyaltyProgramCode = 'Wyndham'
export const loyaltyProgramType = 'HOTEL'

// URLs
export const rewardsUrl = `${izRewardUrl}`
export const rewardsSignUp = `${izRewardUrl}/join?CID=NA:WR:20190403:ISEATZ:JOINWRLINK:WR:NA:NA:EN-US`
export const signInUrl = () => `${api.baseUrl}/sign_in`
export const foodDeliveryUrl = {
  link: 'https://doordash.com/Wyndham',
  text: 'doordash.com/Wyndham'
}
export const rewardsHomeInfo = {
  link: 'https://www.wyndhamhotels.com/wyndham-rewards?CID=NA:WR:20190403:ISEATZ:WRHOMELINK:WR:NA:NA:EN-US', // eslint-disable-line
  name: 'Wyndham Rewards Home'
}
export const rewardsLearnMoreUrl = (
  'https://www.wyndhamhotels.com/wyndham-rewards/how-it-works?CID=NA:WR:20190502:ISEATZ:HIW:WR:FOODDELIVERY-HP:NA:EN-US'
)
// SVP
export const enableSvp = getEnvVar('ISEATZ_ENABLE_SVP', false) === 'true'
export const dateFormat = 'YYYY-MM-DD'
