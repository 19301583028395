import {CoreGridList, CoreLink} from '@iseatz/ams-core'
import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Reward from './reward'
import Typography from '@material-ui/core/Typography'
import {compose} from 'redux'
import {rewardContainer as styles} from '../../styles/component-styles/rewards'
import {withStyles} from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

export class RewardContainer extends PureComponent {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      rewards: PropTypes.arrayOf(PropTypes.object).isRequired,
      learnMoreTo: PropTypes.string.isRequired,
      mainTitle: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      learnMoreCaption: PropTypes.string.isRequired,
      width: PropTypes.string
    }
    render () {
      const {classes, rewards, learnMoreTo, mainTitle, subTitle, learnMoreCaption, width} = this.props
      const columnNums = (width === 'xs' || width === 'sm') ? 1 : rewards.length
      return (
        <Grid container className={classes.root} direction="column">
          <Typography className={classes.mainTitle}>{mainTitle}</Typography>
          <Typography className={classes.subTitle}>{subTitle}
            <CoreLink
              to={learnMoreTo}
              LinkProps={{target: '_blank', className: classes.link, rel: 'noopener noreferrer'}}>
              {learnMoreCaption}
            </CoreLink>
          </Typography>
          <Grid item>
            <CoreGridList
              CardComponent={Reward}
              loading={false}
              items={rewards}
              cellHeight="auto"
              cols={columnNums}
            />
          </Grid>
        </Grid>
      )
    }
}

const enhance = compose(withWidth(), withStyles(styles))

export default enhance(RewardContainer)
