import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

export default class Container extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    title: PropTypes.string
  }
  render () {
    const {children, classes, title} = this.props
    return (
      <Grid container direction="column" className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        <Grid className={classes.travelersContainer}>
          {children}
        </Grid>
      </Grid>
    )
  }
}
