import * as config from '../../config'
import React, {PureComponent} from 'react'
import BrandingImageComponent from './logo-wrapper'
import BrandingTitleComponent from './title'
import {CoreBranding} from '@iseatz/ams-core'
import PropTypes from 'prop-types'
import {brandingStyles} from '../../styles'
import logo from '../../img/logo.png'
import {resolveHomeURL} from './util'
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'

const StyledBranding = withStyles(brandingStyles)(CoreBranding)

class WyndhamBranding extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      linkStyling: PropTypes.string,
      linkTitle: PropTypes.string
    }),
    location: PropTypes.shape({})
  }

  render () {
    const {location} = this.props
    return (
      <StyledBranding
        ImageProps={{
          src: logo,
          alt: config.imageAlt
        }}
        ImageComponent={BrandingImageComponent}
        text={(<BrandingTitleComponent goForItLink={resolveHomeURL(location)}/>)}
      />
    )
  }
}

export default withRouter(WyndhamBranding)
