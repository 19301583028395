import {mediumTextLetterSpacing} from '../font'

export const navDropdownSignInStyles = ({spacing}) => ({
  root: {
    cursor: 'pointer',
    padding: `${spacing.unit * 1.5}px 0`,
    '&:hover': {
      textDecoration: 'none'
    }
  }
})

export const navDropdownFooterStyles = {
  container: {
    marginTop: 28,
    letterSpacing: 0.86
  }
}

export const navDropdownStyles = ({palette, spacing, typography}) => ({
  navLink: {
    borderTop: `1px solid ${palette.secondary.light}`,
    fontWeight: `${typography.subtitle2.fontWeight}`,
    display: 'block',
    fontSize: typography.h6.fontSize,
    margin: [[0, 8]],
    padding: `${spacing.unit * 2}px 0`,
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: palette.primary.main,
    fontFamily: typography.fontFamily,
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  navLinkSelected: {
    color: palette.primary.dark
  },
  item: {
    paddingTop: 8
  },
  userInfo: {
    padding: [[8, 8, 0, 8]]
  },
  heroRewardText: {
    fontSize: typography.caption.fontSize,
    letterSpacing: mediumTextLetterSpacing
  }
})
