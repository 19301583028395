import {bodyTextSize, buttonTextSize, globalFont} from '../../font'
import {bodyGrey} from '../../colors'
import {buttons} from '../../base-styles/buttons'
import {labels} from '../../base-styles/labels'
import {minimalPadding} from '../../spacing'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const collapseButton = {
  root: {
    ...buttons.buttonNoHover.root,
    borderRadius: 0,
    marginTop: pxToRem(50),
    padding: 0,
    justifyContent: 'flex-start',
    width: pxToRem(82),
    [svpTheme.breakpoints.down('sm')]: {
      marginTop: pxToRem(25)
    }
  }
}

export const collapseText = {
  root: {
    color: bodyGrey,
    fontFamily: globalFont,
    fontSize: buttonTextSize,
    lineHeight: 1.375,
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: pxToRem(bodyTextSize)
    }
  }
}

export const mask = {
  root: {
    ...labels.link,
    fontSize: buttonTextSize,
    paddingRight: minimalPadding,
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: pxToRem(bodyTextSize)
    }
  }
}
