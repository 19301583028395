import {
  lightPadding,
  lvpPadding,
  minimalPadding,
  mvpPadding,
  noPadding,
  svpPadding
} from '../../spacing'
import {backgroundGrey} from '../../colors'
import {bodyTextSize} from '../../font'
import {labels} from '../../base-styles/labels'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

const lvpOffsetPadding = 10
const mvpOffsetPadding = 5

export const tourBreakdown = {
  root: {
    padding: [[0, 0, 0, svpPadding]],
    [svpTheme.breakpoints.down('sm')]: {
      padding: [[mvpPadding, 0, 0, 0]]
    }
  }
}

export const tourBreakdownExpansion = {
  root: {
    backgroundColor: backgroundGrey,
    [svpTheme.breakpoints.down('sm')]: {
      marginLeft: 15
    }
  }
}

export const tourBreakdownExpansionLabel = {
  root: labels.link
}

export const expansionSummary = {
  root: {
    [svpTheme.breakpoints.down('sm')]: {
      minHeight: 0
    }
  }
}

export const tourBreakdownTitle = {
  root: {
    [svpTheme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14)
    }
  }
}

export const ticketListItem = {
  container: {
    '& section': {
      display: 'flex'
    },
    padding: [[0, 0, mvpPadding, 0]],
    [svpTheme.breakpoints.down('sm')]: {
      padding: [[pxToRem(3), noPadding, noPadding, noPadding]]
    }
  }
}

export const ticketListLabel = {
  root: labels.dividerLabel
}

export const dividerContainer = {
  item: {
    [svpTheme.breakpoints.down('sm')]: {
      marginTop: pxToRem(lightPadding)
    }
  }
}

export const rewardsLabel = {
  root: labels.logo
}

export const ticketList = {
  root: {
    padding: 0,
    width: '100%'
  }
}

export const listPrice = {
  amount: {
    root: {
      fontSize: bodyTextSize
    }
  },
  cents: {
    root: {
      fontSize: bodyTextSize,
      paddingRight: minimalPadding
    }
  },
  suffix: {
    root: {
      fontSize: bodyTextSize,
      paddingLeft: pxToRem(5)
    }
  }
}

export const breakdownPrice = {
  cents: {
    root: {
      ...labels.price,
      paddingRight: 2,
      paddingTop: 2
    }
  },
  prefix: {
    root: {
      ...labels.price,
      paddingTop: minimalPadding
    }
  }
}

export const breakdownPoints = {
  container: {
    [svpTheme.breakpoints.between('md', 'lg')]: {
      marginBottom: pxToRem(svpPadding - mvpOffsetPadding)
    },
    [svpTheme.breakpoints.up('lg')]: {
      marginBottom: pxToRem(lvpPadding - lvpOffsetPadding)
    },
    [svpTheme.breakpoints.down('sm')]: {
      marginTop: svpPadding
    }
  }
}

export const labelContainer = {
  item: {
    [svpTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  }
}
