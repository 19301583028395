import React, {PureComponent} from 'react'
import Card from '../destination-card'
import {CoreGridList} from '@iseatz/ams-core'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {destinationsStyles as styles} from '../../styles/component-styles/featured-destinations'
import {withStyles} from '@material-ui/core/styles'

class Destinations extends PureComponent {
    static propTypes = {
      destinationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
      classes: PropTypes.object
    }
    render () {
      const {destinationItems, classes} = this.props
      return (
        <Grid container className={classes.root} alignContent="flex-start">
          <CoreGridList
            CardComponent={Card}
            loading={false}
            items={destinationItems}
            cols={4}
            spacing={0}
            className={classes.gridList}
            cellHeight={'auto'}
          />
        </Grid>
      )
    }
}

export default withStyles(styles)(Destinations)
