/* eslint-disable max-lines */
import {backgroundGrey, linkBlue, titaniumMember, white, wyzardSuitBlue} from '../colors'
import {
  bodyTextSize,
  bold,
  buttonTextSize,
  headerNavFontSize,
  logoTextSize,
  offerTextSize,
  smallTitleTextSize,
  subheaderTextSize
} from '../font'
import {
  lvpColumns,
  lvpMargins,
  minimalPadding,
  mvpColumns,
  mvpGutters,
  mvpMargins,
  noPadding,
  svpMargins} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography
const bookingBorderPadding = [[mvpMargins, lvpMargins]]

export const confirmationBookingInfo = {
  container: {
    container: {
      backgroundColor: backgroundGrey
    }
  },
  productHead: {
    container: {
      container: {
        padding: bookingBorderPadding,
        [svpTheme.breakpoints.down('sm')]: {
          padding: [[svpMargins, lvpColumns]]
        }
      }
    },
    leftTitle: {
      root: {
        color: wyzardSuitBlue,
        fontSize: headerNavFontSize,
        textTransform: 'uppercase',
        fontWeight: bold,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: smallTitleTextSize
        }
      }
    },
    leftInfo: {
      root: {
        fontSize: headerNavFontSize,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: smallTitleTextSize
        }
      }
    },
    rightTitle: {
      root: {
        fontSize: headerNavFontSize,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: smallTitleTextSize
        }
      }
    },
    rightInfoText: {
      root: {
        fontSize: headerNavFontSize,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: smallTitleTextSize
        }
      }
    }
  },
  productBreakdown: {
    container: {
      container: {
        padding: bookingBorderPadding,
        [svpTheme.breakpoints.down('sm')]: {
          padding: [[svpMargins, lvpColumns]]
        }
      }
    },
    title: {
      root: {
        fontSize: headerNavFontSize,
        color: titaniumMember,
        fontWeight: bold,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: smallTitleTextSize
        }
      }
    },
    titleContainer: {
      container: {
        paddingBottom: 10
      }
    },
    listItems: {
      container: {
        paddingBottom: 8
      }
    },
    iconItem: {
      root: {
        paddingLeft: mvpGutters,
        color: titaniumMember,
        [svpTheme.breakpoints.down('sm')]: {
          paddingLeft: 8,
          fontSize: smallTitleTextSize
        }
      }
    },
    link: {
      root: {
        color: linkBlue,
        fontSize: offerTextSize,
        padding: noPadding,
        textTransform: 'capitalize',
        textDecoration: 'underline',
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: bodyTextSize
        }
      }
    }
  },
  priceBreakdown: {
    container: {
      container: {
        padding: bookingBorderPadding,
        [svpTheme.breakpoints.down('sm')]: {
          padding: [[svpMargins, lvpColumns]]
        }
      }
    },
    header: {
      root: {
        fontSize: headerNavFontSize,
        color: wyzardSuitBlue,
        fontWeight: bold,
        textTransform: 'uppercase',
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: smallTitleTextSize
        }
      }
    },
    taxes: {
      root: {
        fontSize: offerTextSize,
        color: titaniumMember,
        fontWeight: bold,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: bodyTextSize
        }
      }
    }
  },
  footer: {
    container: {
      container: {
        padding: bookingBorderPadding,
        backgroundColor: wyzardSuitBlue,
        flexGrow: 1,
        [svpTheme.breakpoints.down('sm')]: {
          padding: [[svpMargins, lvpColumns]]
        }
      }
    },
    text: {
      root: {
        color: white,
        fontSize: headerNavFontSize,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: buttonTextSize
        }
      }
    },
    priceAmount: {
      root: {
        color: white,
        fontSize: subheaderTextSize,
        fontWeight: bold,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: buttonTextSize
        }
      }
    },
    prefix: {
      root: {
        color: white,
        fontSize: logoTextSize,
        alignSelf: 'flex-start',
        fontWeight: bold
      }
    },
    suffix: {
      root: {
        color: white,
        fontWeight: bold,
        paddingLeft: pxToRem(5)
      }
    },
    cents: {
      root: {
        color: white,
        fontSize: subheaderTextSize,
        fontWeight: bold,
        paddingRight: minimalPadding,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: buttonTextSize
        }
      }
    }
  },
  tourBreakdownList: {
    price: {
      root: {
        fontSize: offerTextSize,
        color: titaniumMember
      }
    },
    cents: {
      root: {
        fontSize: offerTextSize,
        color: titaniumMember,
        paddingRight: 4
      }
    },
    suffix: {
      root: {
        fontSize: offerTextSize,
        color: titaniumMember,
        paddingLeft: pxToRem(5)
      }
    }
  },
  tourBreakdown: {
    title: {
      root: {
        paddingTop: 14,
        fontSize: offerTextSize,
        fontWeight: bold,
        color: titaniumMember,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: buttonTextSize
        }

      }
    },
    priceAmount: {
      root: {
        paddingTop: 14,
        fontSize: offerTextSize,
        fontWeight: bold,
        color: titaniumMember,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: buttonTextSize
        }
      }
    },
    cents: {
      root: {
        paddingTop: 14,
        fontSize: offerTextSize,
        fontWeight: bold,
        color: titaniumMember,
        paddingRight: 4,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: buttonTextSize
        }
      }
    },
    expansion: {
      root: {
        backgroundColor: backgroundGrey,
        paddingBottom: svpMargins
      }
    },
    expansionLabel: {
      root: {
        color: linkBlue
      }
    },
    expansionDetails: {
      root: {
        padding: noPadding
      }
    },
    list: {
      container: {
        padding: noPadding
      }
    },
    listProps: {
      style: {
        width: '100%'
      }
    },
    listItem: {
      container: {
        paddingBottom: mvpColumns
      }
    },
    expandIcon: {
      root: {
        padding: noPadding
      }
    },
    price: {
      root: {
        padding: noPadding,
        cssFloat: 'right'
      }
    },
    suffix: {
      root: {
        paddingTop: pxToRem(14),
        paddingLeft: pxToRem(5),
        fontSize: offerTextSize,
        fontWeight: bold,
        color: titaniumMember
      }
    }
  }
}
