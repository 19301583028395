import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import WyndhamLogo from '../../img/wyndham_footer_logo.svg'
import {footerImageStyles as styles} from '../../styles'
import {withStyles} from '@material-ui/core/styles'

class FooterImage extends PureComponent {
  render () {
    const {classes} = this.props
    return (
      <Grid container direction="column" alignItems="center">
        <img src={WyndhamLogo} alt="logo" className={classes.root}/>
      </Grid>
    )
  }
}

FooterImage.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string
  })
}
export default withStyles(styles)(FooterImage)
