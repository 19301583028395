import {lvpPageGutters, mvpSvpPageGutters} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const brandingStyles = ({palette: {primary}, typography}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
    [svpTheme.breakpoints.down('sm')]: {
      flex: 'none'
    }
  },
  text: {
    color: primary.main,
    flex: '1 1 auto',
    fontSize: typography.fontSize,
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    [svpTheme.breakpoints.down('sm')]: {
      marginLeft: 8
    }
  },
  image: {
    height: '4rem',
    margin: [[0, pxToRem(8), 0, lvpPageGutters]],
    [svpTheme.breakpoints.only('md')]: {
      margin: [[0, pxToRem(8), 0, mvpSvpPageGutters]]
    },
    [svpTheme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  linkStyling: {
    '&:hover': {textDecoration: 'none'}
  }
})

export const linkTitle = {
  root: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {textDecoration: 'none'}
  },
  super: {
    fontStyle: 'normal'
  }
}
