import * as config from '../config'
import {
  badge,
  badgeContainer,
  banner,
  breadcrumbs,
  interstitialProps,
  loadingProps
} from '../styles'
import {Container} from '@iseatz/framework'
import {payWithPointsConverter} from '../utils'

// Config
Container.bind('config:global.environment', config.environment)
Container.bind('config:api', {
  baseURL: config.api.baseUrl,
  withCredentials: true
})
Container.bind('config:validate.ams.membership', config.validateMemberNumber)
Container.bind('config:ams.profile.rewards.code', config.loyaltyProgramCode)
Container.bind('config:ams.profile.rewards.type', config.loyaltyProgramType)
Container.bind('config:ams.activities.search.pageMap', {
  xs: 15,
  sm: 15,
  md: 12,
  lg: 15,
  xl: 15
})
Container.bind('config:ams.activities.points.converter', payWithPointsConverter)

// Lang
Container.bind('lang:ams.activities.label', 'Tours & Activities Home')

// Breadcrumbs
Container.bind('styles:ams.activities.global.breadcrumbs.separator', breadcrumbs.separator)
Container.bind('props:ams.activities.global.breadcrumbs.separator', {
  separator: '|'
})
Container.bind('props:ams.activities.global.breadcrumbs.bar', {disableGutters: true})
Container.bind('styles:ams.activities.global.breadcrumbs.bar', breadcrumbs.bar)
Container.bind('styles:ams.activities.global.breadcrumbs.item', breadcrumbs.item)
Container.bind('styles:ams.activities.global.breadcrumbs.root.item', breadcrumbs.breadcrumbsRootItem)

// Banner
Container.bind('props:ams.activities.banner.button', {
  fullWidth: true
})
Container.bind('styles:ams.activities.banner.button', banner.button)
Container.bind('styles:ams.activities.banner.title', banner.title)
Container.bind('styles:ams.activities.banner.description', banner.description)

// Interstitial
Container.bind('props:ams.activities.loading.loader', loadingProps)
Container.bind('props:ams.activities.loading.loaderContainer', interstitialProps)
Container.bind('styles:ams.activities.loading.loaderContainer', {
  container: {
    marginTop: 90
  }
})

// Hero Badge
Container.bind('styles:ams.activities.badge', badgeContainer)
Container.bind('styles:ams.activities.badge.text', {
  root: badge
})
