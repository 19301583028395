import {buttons, labels} from '../base-styles/index'
import {minimalMargin} from '../spacing'
import {smallTextLetterSpacing} from '../font'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography
const border = `solid ${pxToRem(1)} ${svpTheme.palette.secondary.light}`

export const searchModal = {
  action: {
    margin: 0
  },
  buttonContainer: {
    borderTop: border
  },

  closeIcon: {
    ...buttons.close
  },

  dialog: {
    zIndex: 1000001
  },

  header: {
    ...labels.modalHeader
  },

  popover: {
    marginLeft: '1rem',
    marginTop: '9rem',
    width: '100%'
  },

  searchbar: {
    height: '90%',
    margin: minimalMargin,
    width: '100%'
  },

  title: {
    ...labels.leadingLabel,
    paddingLeft: pxToRem(12),
    letterSpacing: smallTextLetterSpacing
  }
}
