import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {componentType} from '@iseatz/framework'
import {benefit as styles} from '../../styles/component-styles/benefit-section'
import {withStyles} from '@material-ui/core/styles'

export class Benefit extends PureComponent {
    static propTypes = {
      icon: componentType,
      label: PropTypes.string,
      description: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
      classes: PropTypes.object.isRequired
    }
    render () {
      const {icon: Icon, label, description, classes} = this.props
      const typographyDescription = description.map(lineColumn => (
        <Typography key={lineColumn} className={classes.description}>{lineColumn}</Typography>))

      return (
        <Grid container direction="column" justify="center" alignItems="center">
          <Icon/>
          <Typography className={classes.label} variant="h5">{label}</Typography>
          <Hidden smDown>
            {typographyDescription}
          </Hidden>
        </Grid>
      )
    }
}

export default withStyles(styles)(Benefit)
