import {buttons, labels} from '../base-styles/index'
import {lvpColumns, svpPadding} from '../spacing'
import {ruleGrey} from '../colors'
import {smallTextLetterSpacing} from '../font'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography
const border = `solid ${pxToRem(1)} ${svpTheme.palette.secondary.light}`
const offset = 3

export const dateModal = {
  action: {
    margin: 0
  },
  buttonContainer: {
    borderTop: border
  },
  closeIcon: {
    ...buttons.close
  },
  dateContainer: {
    paddingBottom: pxToRem(325),
    marginRight: lvpColumns,
    width: '100%'
  },
  dialog: {
    zIndex: 1000001
  },
  header: {
    ...labels.modalHeader
  },
  locationButton: {
    ...labels.link,
    textTransform: 'capitalize'
  },
  locationContainer: {
    borderTop: `${pxToRem(1)} solid ${ruleGrey}`,
    maxHeight: pxToRem(70),
    paddingLeft: svpPadding,
    width: '100%',
    marginTop: 11
  },
  locationTitle: {
    ...labels.formBlockLabel
  },
  title: {
    ...labels.leadingLabel,
    letterSpacing: smallTextLetterSpacing,
    paddingLeft: svpPadding + offset
  }
}
