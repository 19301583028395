import {bodyGrey, gold, legalGrey, linkBlue, titaniumMember} from '../colors'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography
const bar = {
  height: '100%'
}

export const tierBar = {
  separatorBlue: {
    ...bar,
    backgroundColor: linkBlue
  },
  separatorGold: {
    ...bar,
    backgroundColor: gold
  },
  separatorPlatinum: {
    ...bar,
    backgroundColor: bodyGrey
  },
  separatorDiamond: {
    ...bar,
    backgroundColor: legalGrey
  },
  separatorTitanium: {
    ...bar,
    backgroundColor: titaniumMember
  },
  guest: {
    ...bar,
    backgroundColor: linkBlue
  },
  tierContainer: {
    height: pxToRem(2)
  }
}
