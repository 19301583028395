
import React, {PureComponent} from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {rewardCard as styles} from '../../styles/component-styles/rewards'
import {withStyles} from '@material-ui/core/styles'

export class Reward extends PureComponent {
    static propTypes = {
      item: PropTypes.object.isRequired,
      classes: PropTypes.object.isRequired
    }
    render () {
      const {item: {title, descriptionSection, subscript}, classes} = this.props
      return (
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>{title}
              <sup className={classes.titleSubScript}>{subscript}</sup>
            </Typography>
            {descriptionSection}
          </CardContent>
        </Card>
      )
    }
}

export default withStyles(styles)(Reward)
