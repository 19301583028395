// Columns
export const lvpColumns = 12
export const mvpColumns = 8
export const svpColumns = 4

// Gutters
export const lvpGutters = 32
export const mvpGutters = 24
export const svpGutters = 16
export const lvpPageGutters = 46
export const mvpSvpPageGutters = 9

// Margins
export const lvpMargins = 32
export const mvpMargins = 24
export const svpMargins = 16
export const smallMargin = 10
export const minimalMargin = 2

// Padding
export const lvpPadding = 32
export const mvpPadding = 24
export const svpPadding = 16
export const mediumPadding = 64
export const heavyPadding = 100
export const lightPadding = 20
export const minimalPadding = 6
export const noPadding = 0
export const buttonPadding = 14
export const superScriptPadding = 10
export const fullWidth = '100%'
