import React, {Fragment, PureComponent} from 'react'
import FooterDivider from './footer-divider'
import FooterImage from './footer-image'
import FooterLinks from './footer-links'
import FooterText from './footer-text'
import PropTypes from 'prop-types'
import {Route} from 'react-router-dom'
import {TopScrollRoute} from '@iseatz/ams-core'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {resolve} from '@iseatz/framework'
import {withRouter} from 'react-router'

const defaultFooter = (<Fragment>
  <FooterLinks
    termsAndConditionsLink="https://www.viator.com/support/termsAndConditions"
    privacyPolicyLink="https://www.viator.com/support/privacypolicy"
  />
  <FooterImage/>
  <FooterDivider/>
  <FooterText
    text={getKey(dictionary, 'sections.activity.footer.footerText')}
  />
</Fragment>)

export class WyndhamGlobalFooter extends PureComponent {
  render () {
    return (
      <Fragment>
        <FooterDivider/>
        <Route
          path={resolve('route:path.activities')}
          component={() => defaultFooter}
        />
        <Route
          path="/"
          exact
          component={() => defaultFooter }
        />
        <TopScrollRoute
          path={resolve('route:path.foodDelivery')}
          component={() => (<Fragment>
            <FooterLinks
              termsAndConditionsLink="https://www.doordash.com/terms/"
              privacyPolicyLink="https://www.doordash.com/privacy/"
            />
            <FooterImage/>
            <FooterDivider/>
            <FooterText
              text={getKey(dictionary, 'sections.foodDelivery.footer.footerText')}
            />
          </Fragment>)}
        />
      </Fragment>
    )
  }
}

WyndhamGlobalFooter.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string
  })
}

export default withRouter(WyndhamGlobalFooter)
