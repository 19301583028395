import {ActivitiesHeaderContainer, withLocation} from '@iseatz/ams-activities'
import {Dialog, TopScrollRoute} from '@iseatz/ams-core'
import React, {PureComponent} from 'react'
import {Route, matchPath, withRouter} from 'react-router-dom'
import {
  headerStyling,
  navMenuBodyStyles,
  navMenuIconStyles
} from '../../styles'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import {Helmet} from 'react-helmet'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ImmutablePropTypes from 'react-immutable-proptypes'
import MenuIcon from '@material-ui/icons/Menu'
import PropTypes from 'prop-types'
import {ResizedClearIcon} from '../../img/clearResize'
import SearchFoodDeliveryHero from '../food-delivery-search-hero/home-hero'
import SearchHero from '../activities-search-hero/hero'
import SearchHeroHome from '../activities-search-hero/home-hero'
import {UserInformation} from '../user-information'
import UserTierBar from '../tier-bar'
import WyndhamBranding from '../branding'
import WyndhamNav from '../nav-main'
import {compose} from 'redux'
import {connect} from 'react-redux'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {isSearch} from './util'
import {resolve} from '@iseatz/framework'
import {selectors} from '@iseatz/ams-profile'
import {withStyles} from '@material-ui/core/styles'

// eslint-disable-next-line
const ActivitiesHeaderCore = ({classes: {appBarContainer, ...restClasses}, ...restProps}) => (<div className={appBarContainer}>
  <AppBar {
  ...{
    ...restProps,
    classes: {
      ...restClasses
    }
  }} />
</div>)

const StyledMenuIcon = withStyles(navMenuIconStyles)(MenuIcon)
const StyledBody = withStyles(navMenuBodyStyles)(Grid)

// Explicitly pass the location like prop, so the component re-renders in a location change
const RouterWyndhamNav = withRouter(WyndhamNav)

const breadcrumbsProps = {
  ContainerComponentProps: {
    item: true,
    lg: 8,
    xs: 6
  }
}

const getTitle = location => {
  let title = getKey(dictionary, 'sections.activity.pageTitle.default')
  if (matchPath(location.pathname, {path: resolve('route:path.activities')})) {
    title = getKey(dictionary, 'sections.activity.pageTitle.activitiesTitle')
  }
  if (matchPath(location.pathname, {path: resolve('route:path.foodDelivery')})) {
    title = getKey(dictionary, 'sections.activity.pageTitle.foodDeliveryTitle')
  }
  return title
}

class WyndhamHeaderBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    user: ImmutablePropTypes.contains({}),
    location: PropTypes.shape({})
  }

  render () {
    const {location} = this.props
    const {
      classes,
      user,
      isLoggedIn
    } = this.props

    const style = isSearch(location) ? {zIndex: 1000001} : {}

    let userInformation = null
    if (isLoggedIn) {
      userInformation = <UserInformation {...user.toJS()}
        containerProps={{justify: 'flex-end'}}
        pointsLabel={getKey(dictionary, 'sections.activity.components.userInformation.pts')}/>
    }

    return (
      <ActivitiesHeaderContainer
        AppBarComponent={ActivitiesHeaderCore}
        AppBarProps={{
          classes: {
            appBarContainer: classes.appBarContainer,
            root: classes.header
          },
          color: 'inherit',
          component: 'div',
          elevation: 0
        }}
        BrandingComponent={WyndhamBranding}
        ContainerComponent="header"
        NavComponent={RouterWyndhamNav}
        NavProps={{
          MenuComponent: Dialog,
          MenuProps: {
            TitleComponent: WyndhamBranding,
            TitleProps: {inline: true},
            CloseButtonComponent: IconButton,
            CloseIconComponent: ResizedClearIcon,
            BodyComponent: StyledBody,
            BodyProps: {container: true},
            style,
            title: 'span'
          },
          TogglerIconComponent: StyledMenuIcon
        }}
        BreadcrumbsProps={{
          ...breadcrumbsProps
        }}
        container={true}
        justify={'space-between'}
        ToolbarProps={{disableGutters: true}}
      >
        {/* TODO: for the moment this seems to be the best place to put this title logic, this should be rewiewed
        when more pages be implemented */}
        <Helmet>
          <title>{getTitle(location)}</title>
        </Helmet>
        <Hidden smDown>
          {userInformation}
        </Hidden>
        <UserTierBar/>
        <Grid xs={12} item>
          <TopScrollRoute
            path={resolve('route:path.foodDelivery')}
            exact
            render={() => <SearchFoodDeliveryHero isLoggedIn={isLoggedIn} user={user.toJS()}/>}
          />
          <Route
            path={resolve('route:path.activities.search')}
            component={SearchHero}
          />
          <Route
            path={resolve('route:path.activities')}
            exact
            component={SearchHeroHome}
          />
        </Grid>
      </ActivitiesHeaderContainer>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  user: selectors.getUser(state)
})

const enhance = compose(
  withLocation,
  withRouter,
  connect(mapStateToProps),
  withStyles(headerStyling)
)

export const WyndhamHeader = enhance(WyndhamHeaderBase)
