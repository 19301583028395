import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {selectors} from '@iseatz/ams-profile'
import {tierBar as styles} from '../styles/component-styles/tier-bar'
import {withStyles} from '@material-ui/core/styles'

class TierBar extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      disclamerText: PropTypes.string,
      guest: PropTypes.string,
      tierContainer: PropTypes.string
    }),
    user: PropTypes.shape({
      toJS: PropTypes.func
    }),
    isLoggedIn: PropTypes.bool
  }

  static defaultProps = {
    isLoggedIn: false
  }

  render () {
    const {classes, isLoggedIn, user} = this.props
    const userJs = user.toJS()
    const bar = (isLoggedIn)
      ? <Grid item xs={12} className={classes[`separator${userJs.tierlevel}`]}/>
      : <Grid item xs={12} className={classes.guest}/>

    return (
      <Grid item xs={12} className={classes.tierContainer}>
        {bar}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  user: selectors.getUser(state)
})

const UserTierBar = connect(mapStateToProps)(TierBar)

export default withStyles(styles)(UserTierBar)
