import {
  backgroundGrey,
  white,
  wyzardSuitBlue} from '../../colors'
import {bold, formBlockLabel} from '../../font'
import {svpGutters} from '../../spacing'
import {
  svpTheme
} from '../../theme'

export const participantsRange = {
  container: {
    backgroundColor: backgroundGrey,
    borderBottom: `2px solid ${white}`,
    margin: 0,
    width: '100%',
    [svpTheme.breakpoints.down('md')]: {
      padding: 0
    },
    [svpTheme.breakpoints.up('lg')]: {
      padding: [[0, svpGutters]]
    }
  },
  label: {
    display: 'flex',
    fontSize: 10,
    letterSpacing: 0.5
  },
  max: {
    color: wyzardSuitBlue,
    fontSize: formBlockLabel,
    fontWeight: bold
  },
  min: {
    color: wyzardSuitBlue,
    fontSize: formBlockLabel,
    fontWeight: bold
  },
  rangeContainer: {
    margin: 0,
    padding: '12px 0 !important',
    letterSpacing: 5
  },
  separator: {
    color: wyzardSuitBlue,
    fontSize: formBlockLabel,
    fontWeight: bold
  }
}
