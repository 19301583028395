import {
  buttons,
  labels
} from '../../base-styles'
import {
  mvpGutters,
  smallMargin,
  svpGutters
} from '../../spacing'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const checkoutMain = {
  errorContainer: {
    container: {
      ...labels.errorMessage,
      ...labels.errorContainer
    }
  },
  errorIcon: {
    root: {
      marginRight: svpGutters,
      marginLeft: mvpGutters,
      [svpTheme.breakpoints.only('md')]: {
        marginLeft: 0
      },
      [svpTheme.breakpoints.down('sm')]: {
        marginLeft: smallMargin
      }
    }
  },
  errorText: {
    root: {
      ...labels.errorText
    }
  },
  mainContainer: {
    container: {
      [svpTheme.breakpoints.down('sm')]: {
        marginBottom: svpGutters * 3,
        paddingLeft: pxToRem(8),
        paddingRight: pxToRem(8)
      },
      [svpTheme.breakpoints.up('md')]: {
        marginBottom: mvpGutters * 3
      }
    }
  },
  submitButton: {
    root: {
      ...buttons.actionButton,
      ...buttons.actionHover
    }
  },
  loader: {
    root: {
      position: 'absolute'
    }
  }
}
