import {
  lvpGutters,
  mvpGutters,
  svpGutters
} from '../spacing'
import {
  svpTheme
} from '../theme'

const offsetGridSpacing = 10

export const layout = {
  responsiveSideGutters: {
    [svpTheme.breakpoints.down('sm')]: {
      margin: [[0, svpGutters]]
    },
    [svpTheme.breakpoints.between('md', 'lg')]: {
      margin: [[0, mvpGutters]]
    },
    [svpTheme.breakpoints.up('lg')]: {
      margin: [[0, lvpGutters]]
    }
  },
  responsiveGutters: {
    [svpTheme.breakpoints.down('md')]: {
      padding: mvpGutters - offsetGridSpacing
    },
    [svpTheme.breakpoints.down('sm')]: {
      padding: 0
    },
    [svpTheme.breakpoints.up('lg')]: {
      padding: lvpGutters - offsetGridSpacing
    }
  }
}
