import {noPadding, svpPadding} from '../../spacing'
import Bookmark from '../../../img/bookmark.svg'
import {svpTheme} from '../../theme'
import {white} from '../../colors'

export const productImage = {
  imageContainer: {
    container: {
      paddingBottom: '.75rem'
    }
  },
  image: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '11.5rem',
    [svpTheme.breakpoints.up('md')]: {
      height: '27rem'
    }
  },
  bookmark: {
    root: {
      backgroundColor: 'transparent',
      backgroundImage: `url(${Bookmark})`,
      backgroundPosition: 'center',
      boxShadow: '0 0 0 0',
      height: 113,
      margin: [[-9, noPadding, noPadding, 22]],
      width: 96
    }
  },
  bookmarkText: {
    root: {
      fontSize: 15,
      color: white,
      letterSpacing: 0.55,
      padding: [[24, noPadding, noPadding, '20%']],
      textTransform: 'uppercase'
    }
  },
  container: {
    item: {
      [svpTheme.breakpoints.down('sm')]: {
        padding: [[noPadding, svpPadding]]
      }
    }
  }
}
