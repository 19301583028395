import {
  backgroundGrey,
  white,
  wyzardSuitBlue
} from '../../colors'
import {
  bodyTextSize,
  bold,
  buttonTextSize,
  regular
} from '../../font'
import {
  lvpPadding,
  minimalPadding,
  mvpColumns,
  mvpPadding,
  noPadding,
  svpPadding
} from '../../spacing'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const highlights = {
  contactStyles: {
    item: {
      backgroundColor: wyzardSuitBlue,
      marginTop: mvpPadding,
      maxHeight: 135
    }
  },
  contactText: {
    root: {
      color: white,
      fontSize: buttonTextSize,
      padding: [[minimalPadding, lvpPadding, lvpPadding, lvpPadding]]
    }
  },
  container: {
    style: {
      backgroundColor: backgroundGrey
    }
  },
  descriptionContainer: {
    item: {
      [svpTheme.breakpoints.down('sm')]: {
        padding: mvpColumns
      }
    }
  },
  header: {
    item: {
      backgroundColor: backgroundGrey,
      paddingLeft: svpPadding,
      paddingRight: svpPadding,
      paddingTop: svpPadding,
      verticalAlign: 'middle',
      display: 'inline'
    }
  },
  highlightsTitle: {
    inline: {
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: pxToRem(20),
        fontWeight: regular
      }
    }
  },
  icon: {
    root: {
      fontSize: bodyTextSize,
      paddingTop: minimalPadding
    }
  },
  itemText: {
    root: {
      padding: [[noPadding, minimalPadding]]
    },
    primary: {
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: pxToRem(bodyTextSize)
      }
    }
  },
  outerContainer: {
    container: {
      position: 'relative',
      [svpTheme.breakpoints.between('md', 'lg')]: {
        paddingTop: svpPadding
      },
      [svpTheme.breakpoints.up('lg')]: {
        marginTop: 55
      },
      [svpTheme.breakpoints.down('sm')]: {
        padding: 0
      }
    }
  },
  titleSpace: {
    root: {
      width: '100%',
      justifyContent: 'left',
      paddingLeft: '0.75rem',
      backgroundColor: backgroundGrey
    },
    expanded: {
      justifyContent: 'left',
      padding: [['0.25rem', 0, '2.5rem', '0.35rem']]
    },
    content: {
      alignItems: 'center'
    },
    expandIcon: {
      paddingBottom: '0.5rem'
    }
  },
  detailsSpace: {
    root: {
      backgroundColor: backgroundGrey
    }
  }
}
highlights.contactTitle = {
  root: {
    ...highlights.contactText.root,
    fontWeight: bold,
    paddingBottom: noPadding,
    paddingTop: mvpPadding
  }
}
