import React, {Fragment, PureComponent} from 'react'
import {rewardDescription, rewardDivider} from '../../styles'
import BenefitContainer from '../../layout/benefits-section/container'
import {ReactComponent as Call} from '../../img/Call.svg'
import {CoreLink} from '@iseatz/ams-core'
import {CrossSell} from '../../layout/cross-sell/cross-sell'
import {ReactComponent as Destination} from '../../img/Destination.svg'
import Disclaimer from '../../layout/disclaimer/disclaimer-container'
import Divider from '@material-ui/core/Divider'
import {ReactComponent as Earn} from '../../img/Earn.svg'
import FeaturedDestinationsContainer from '../../layout/featured-destinations/container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import {ReactComponent as Redeem} from '../../img/Redeem.svg'
import RewardContainer from '../../layout/rewards/container'
import {ReactComponent as Search} from '../../img/Search.svg'
import TopPicksContainer from '../../layout/top-picks/container'
import Typography from '@material-ui/core/Typography'
import {connect} from 'react-redux'
import {search} from '@iseatz/ams-activities'
import {whenFoodDeliveryEnabled} from '../../utils'
import {withStyles} from '@material-ui/core'

const {
  actions
} = search

const possibilities = [
  {
    icon: Earn,
    label: 'EARN POINTS',
    description: [
      'Get 10 points per dollar when you book everything',
      <span key="benefit2">from food tours to outdoor excursions.<sup>1</sup></span>]
  },
  {
    icon: Redeem,
    label: 'REDEEM POINTS',
    description: ['Use your points to plan exciting activities and book', 'unique experiences.']
  },
  {
    icon: Search,
    label: 'DISCOVER THINGS TO DO',
    description: ['Find 300,000+ exciting activities, tours, and', 'experiences around the world.']
  },
  {
    icon: Call,
    label: 'MEMBER SERVICES',
    description: [
      'Have questions or issues? Call 866-996-7937 to',
      'speak to our Wyndham Rewards customer support team.']
  }
]

const destinationItems = [
  {
    title: 'New York, NY',
    latitude: 40.7128,
    longitude: -74.0060,
    id: 'NY'
  },
  {
    title: 'London, UK',
    latitude: 51.5074,
    longitude: -0.1278,
    id: 'UK'
  },
  {
    title: 'Charleston, SC',
    latitude: 32.7765,
    longitude: -79.9311,
    id: 'SC'
  },
  {
    title: 'Orlando, FL',
    latitude: 28.538336,
    longitude: -81.379234,
    id: 'FL'
  }
]

// Removed temporarily under CO WHG-621
const descriptionText = [`As a Wyndham Rewards Member, you'll earn 250 points for every order you place. `
+ `Plus, new DoorDash customers will earn 2,000 bonus points after placing their first order.`
]
const foodCrossSell = whenFoodDeliveryEnabled(<Grid container direction="column" alignItems="center">
  <CrossSell
    topTitle="NOW AVAILABLE"
    mainSubTitle="DOORDASH"
    descriptionTitle="Order In, Earn Points"
    descriptionText={descriptionText}
    buttonCaption="GET STARTED"
  />
</Grid>
)
const StyledTypography = withStyles(rewardDescription)(Typography)
const StyledDivider = withStyles(rewardDivider)(Divider)

const rewards = [
  {
    id: 1,
    title: 'go free',
    subscript: 'SM',
    descriptionSection: (
      <Fragment>
        <StyledTypography>
          Book free nights at thousands of Wyndham Rewards Hotels <strong>starting at just 7,500 points.</strong>
          <sup>2</sup>
        </StyledTypography>
        <Hidden mdUp>
          <StyledDivider />
        </Hidden>
      </Fragment>
    )
  },
  {
    id: 2,
    title: 'go fast',
    subscript: 'SM',
    descriptionSection: (
      <Fragment>
        <StyledTypography>
          Get a discounted night <strong>starting at just 1,500 points.</strong><sup>2</sup>
        </StyledTypography>
        <Hidden mdUp>
          <StyledDivider />
        </Hidden>
      </Fragment>
    )
  },
  {
    id: 3,
    title: 'go get \'em',
    subscript: 'SM',
    descriptionSection: (
      <Typography>
        For every qualified stay, earn
        <strong> 10 points per dollar or 1,000 points</strong>—whichever is more.<sup>3</sup>
      </Typography>
    )
  }
]

const rightSummerPicks = [
  {
    title: 'Sip Local Wines',
    subTitle: 'Napa, CA',
    latitude: 38.297539,
    longitude: -122.286865,
    id: 'CA',
    category: 'ZAC6',
    itemProps: {
      cols: 2
    }
  },
  {
    title: 'Discover the Windy City',
    subTitle: 'Chicago, IL',
    latitude: 41.878113,
    longitude: -87.629799,
    id: 'IL',
    category: 'ZAC12'
  },
  {
    title: 'Explore the City by Water',
    subTitle: 'Clearwater, FL',
    latitude: 27.965853,
    longitude: -82.800102,
    id: 'FL',
    category: 'ZAC3'
  }
]

const leftSummerPick = {
  title: 'Catch Live Entertainment',
  subTitle: 'Las Vegas, NV',
  latitude: 36.169941,
  longitude: -115.139832,
  id: 'NV',
  category: 'ZAC11'
}

class WyndhamActivitiesHomePageBase extends PureComponent {
  static propTypes = {
    resetSearch: PropTypes.func
  }
  static defaultProps = {
    resetSearch: () => null
  }

  componentDidMount () {
    this.props.resetSearch(true)
  }

  render () {
    return (
      <Grid container direction="column">
        <BenefitContainer possibilities={possibilities} />
        <FeaturedDestinationsContainer
          destinationItems={destinationItems}
          title="Featured Destinations"
          MainIconComponent={Destination}
        />
        <TopPicksContainer
          title="Discover Our Top Picks"
          leftSummerPick={leftSummerPick}
          rightSummerPicks={rightSummerPicks}
        />
        {foodCrossSell}
        <Grid container direction="column" alignItems="center">
          <RewardContainer
            learnMoreTo="https://www.wyndhamhotels.com/wyndham-rewards/how-it-works?CID=NA:WR:20190403:ISEATZ:HIW:WR:TOURSACT-HP:NA:EN-US" // eslint-disable-line
            mainTitle="Wyndham Rewards"
            subTitle="Enjoy exclusive member perks and earn points toward
            free nights."
            learnMoreCaption="Learn More"
            rewards={rewards}
          />
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Disclaimer>
            <Typography variant="caption" paragraph gutterBottom>
              <sup>1</sup>
              Points are earned at a rate of 10 points per dollar when paying solely with credit or debit card.
              If you book an activity using Wyndham Rewards points, you will not earn points on the transaction.
            </Typography>
            <Typography variant="caption" paragraph gutterBottom>
              <sup>2</sup>
              Go Free awards at participating hotels require redemption of between 7,500
              and 30,000 points; at participating condos, condo-style suites, and homes,
              redemption of between 15,000 and 30,000 points per bedroom is required. At
              hotels, award will apply to standard bedroom. Go Fast awards at participating
              hotels require redemption of between 1,500 and 6,000 points, plus a cash payment;
              at participating condos, condo-style suites, and homes, redemption of between
              3,000 and 6,000 points per bedroom, plus a cash payment, are required. All
              awards subject to availability and member must have enough points for all
              bedrooms in desired accommodation to book award nights. <strong>Per night resort fee
              payable for all nights at Caesars Entertainment properties, including award
              nights. Points cannot be used to pay resort fees.</strong> Cancellation policies vary
              by property. Blackout dates/rates, minimum length of stay requirements, and other
              restrictions apply. Most properties: applies to standard room rate only (go free:
              including taxes, go fast: excluding taxes). Not all Wyndham Rewards properties
              participate in go fast nights. Amount of cash required will vary by property.
              Other advance fees typically payable at homes and condo-style resorts for go fast nights.
            </Typography>
            <Typography variant="caption" paragraph gutterBottom>
              <sup>3</sup>
              At most properties where points can be earned, they are earned for dollars spent on the room rate only.
              Qualified Stays can be booked through the call centers or web sites of Wyndham Hotels & Resorts, directly
              with the properties or through the Wyndham Rewards app and are subject to certain restrictions, including,
              without limitation, that such stays cannot be booked via any third party website. See &nbsp;
              <CoreLink
                LinkProps={{target: '_blank'}}
                to="https://www.wyndhamrewards.com/terms">
                www.WyndhamRewards.com/terms
              </CoreLink>
              &nbsp;for more information on Qualified Stays.
            </Typography>
          </Disclaimer>
        </Grid>
      </Grid>
    )
  }
}

const mapDispatchToProps = {
  resetSearch: actions.resetSearch
}

const WyndhamActivitiesHomePage = connect(null, mapDispatchToProps)(WyndhamActivitiesHomePageBase)

export {WyndhamActivitiesHomePageBase, WyndhamActivitiesHomePage}
