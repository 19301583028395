import React, {PureComponent} from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import {doordashDelivery as altButton} from '../../styles/component-styles/doordash-delivery'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {button as styles} from '../../styles/component-styles/order-now-button'
import {withStyles} from '@material-ui/core/styles'

class OrderNowButton extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string
    }),
    submit: PropTypes.bool,
    username: PropTypes.string,
    isLoggedIn: PropTypes.bool
  }
  static defaultProps = {
    submit: false
  }

  render () {
    const {isLoggedIn} = this.props
    const notLoggedInCaption = getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.button')
    const loggedInCaption = getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.altButton')
    const buttonCaption = isLoggedIn ? loggedInCaption : notLoggedInCaption
    const {classes, submit} = this.props
    let maybeSubmitProps = {}
    if (submit) {
      maybeSubmitProps = {type: 'submit'}
    }
    return (
      <Button className={classes.root} fullWidth {...maybeSubmitProps}>
        {buttonCaption}
      </Button>)
  }
}

const OrderNowAlt = props => {
  const altButtonCaption = getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.altButton')
  const {classes} = props
  const href = 'https://www.doordash.com/?utm_source=wyndham'
  OrderNowAlt.propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      orderNowButton: PropTypes.string
    })
  }
  return (
    <Button
      color="primary"
      size="large"
      variant="outlined"
      className={classes.orderNowButton}
      href={href}
      fullWidth
    >
      {altButtonCaption}
    </Button>
  )
}

const OrderNowButtonStyled = withStyles(styles)(OrderNowButton)
const OrderNowAltStyled = withStyles(altButton)(OrderNowAlt)

export {OrderNowButtonStyled as OrderNowButton, OrderNowAltStyled as OrderNowAlt}
