import {
  bodyGrey,
  ruleGrey,
  titaniumMember,
  white,
  wyzardSuitBlue
} from '../colors'
import {
  bodyLineHeight,
  bodyTextSize,
  bold,
  highlightsTextSize,
  medium,
  regular,
  smallTextLetterSpacing
} from '../font'
import {buttons, labels} from '../base-styles'
import {
  fullWidth,
  lightPadding,
  lvpGutters,
  minimalPadding,
  mvpGutters,
  noPadding,
  smallMargin,
  svpColumns,
  svpGutters
} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const cancellationModal = {
  dropdown: {
    root: {
      width: '90%',
      margin: [[mvpGutters, lvpGutters, lvpGutters, lvpGutters]],
      [svpTheme.breakpoints.down('sm')]: {
        width: 'auto',
        marginTop: svpGutters,
        fontWeight: bold,
        color: titaniumMember
      }
    }
  },
  headerContainer: {
    container: {
      borderBottom: `1px solid ${ruleGrey}`,
      [svpTheme.breakpoints.down('sm')]: {
        padding: [svpGutters]
      }
    }
  },
  dialog: {
    root: {
      width: fullWidth
    },
    paper: {
      width: fullWidth,
      padding: lightPadding,
      [svpTheme.breakpoints.down('sm')]: {
        padding: svpColumns,
        margin: 0,
        height: '100%',
        maxHeight: '100%'
      }
    }
  },
  dialogTitle: {
    root: {
      fontWeight: medium,
      fontSize: highlightsTextSize,
      color: wyzardSuitBlue,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: highlightsTextSize,
        fontWeight: regular,
        textTransform: 'uppercase'
      }
    }
  },
  dialogSubtitle: {
    root: {
      margin: [[noPadding, lvpGutters, minimalPadding, lvpGutters]],
      fontWeight: medium,
      fontSize: bodyLineHeight,
      color: bodyGrey,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: bodyTextSize,
        fontWeight: regular,
        letterSpacing: smallTextLetterSpacing
      }
    }
  },
  list: {
    root: {
      margin: [[noPadding, lvpGutters, noPadding, lvpGutters]],
      [svpTheme.breakpoints.down('sm')]: {
        margin: [[noPadding, svpGutters, noPadding, svpGutters]]
      }
    }
  },
  listItem: {
    primary: {
      fontWeight: medium,
      fontSize: bodyLineHeight,
      color: bodyGrey,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: bodyTextSize,
        fontWeight: regular,
        letterSpacing: smallTextLetterSpacing
      }
    }
  },
  dialogTermsSubtitle: {
    root: {
      fontSize: bodyLineHeight,
      color: bodyGrey,
      padding: [[svpGutters, 0]],
      [svpTheme.breakpoints.down('sm')]: {
        padding: [svpGutters]
      }
    }
  },
  commentInput: {
    root: {
      margin: [[noPadding, lvpGutters]],
      height: 100,
      width: '90%',
      [svpTheme.breakpoints.down('sm')]: {
        width: 'auto',
        marginBottom: pxToRem(70)
      }
    }
  },
  selectContainer: {
    container: {
      width: '90%',
      margin: [[noPadding, lvpGutters, lvpGutters, lvpGutters]]
    }
  },
  modalButton: {
    root: {
      ...buttons.actionButton,
      ...buttons.actionHover,
      marginTop: lvpGutters,
      [svpTheme.breakpoints.down('sm')]: {
        ...buttons.buttonNoHover,
        margin: [[smallMargin, noPadding]]
      }
    }
  },
  buttonContainer: {
    container: {
      width: '90%',
      margin: [[noPadding, lvpGutters]],
      [svpTheme.breakpoints.down('sm')]: {
        width: 'auto',
        position: 'fixed',
        bottom: noPadding,
        left: noPadding,
        right: noPadding,
        backgroundColor: white,
        zIndex: 1,
        margin: noPadding,
        padding: [[noPadding, pxToRem(32)]]
      }
    }
  },
  inputLabel: {
    root: {
      paddingLeft: 50,
      paddingTop: 50
    }
  },
  clearContainer: {
    container: {
      [svpTheme.breakpoints.down('sm')]: {
        position: 'absolute',
        right: pxToRem(32),
        top: pxToRem(18),
        width: pxToRem(24)
      }
    }
  },
  icon: {
    root: {
      fontSize: bodyTextSize,
      paddingTop: minimalPadding
    }
  },
  requiredLabel: {
    root: {
      ...labels.formRequiredLabel,
      margin: [[noPadding, lvpGutters]],
      width: '90%',
      [svpTheme.breakpoints.down('sm')]: {
        width: 'auto',
        marginTop: svpGutters
      }
    }
  }
}
