import PropTypes from 'prop-types'
import React from 'react'
import {SearchBar} from '@iseatz/ams-activities'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'

// eslint-disable-next-line
export const SearchBarBase = ({autocompleteProps, classes, disabled = false, ...rest}) => (<SearchBar
  label={getKey(dictionary, 'sections.activity.components.searchbar.label')}
  direction="column"
  justify="space-between"
  alignItems="stretch"
  selectDates={false}
  spacing={16}
  buttonLabel=
    {getKey(dictionary,
      'sections.activity.components.activitiesSearchHeroHome.searchbar.buttonLabel')}
  AutocompleteProps={{
    ...autocompleteProps,
    InputProps: {
      disabled
    }
  }}
  elevation={0}
  ButtonProps={{
    className: classes.homeSearchButton
  }}
  {...rest}
/>)

SearchBarBase.propTypes = {
  classes: PropTypes.shape({
    homeSearchButton: PropTypes.string
  })
}
