import {ruleGrey, wyzardSuitBlue} from '../colors'
import {svpTheme} from '../theme'

const headerStyling = () => ({
  appBarContainer: {
    borderBottom: 'solid',
    borderBottomColor: ruleGrey,
    borderBottomWidth: 1,
    width: '100%',
    // NOTE: Required height value for svp fixed element to take space. Matches height of client logo.
    [svpTheme.breakpoints.down('sm')]: {
      height: 65
    }
  },
  selectedButton: {
    display: 'inline-block',
    position: 'relative',
    color: wyzardSuitBlue,
    width: 'auto',
    '&::after': {
      content: '""',
      height: '4px',
      backgroundColor: wyzardSuitBlue,
      position: 'absolute',
      bottom: '-4px',
      left: 0,
      right: 0
    }
  },
  header: {
    [svpTheme.breakpoints.down('sm')]: {
      position: 'fixed'
    }
  }
})

export {headerStyling}
