import {
  bodyGrey,
  ruleGrey
} from '../../colors'
import {
  forms,
  inputs,
  labels,
  layout
} from '../../base-styles'
import {
  bold
} from '../../font'
import {
  lvpGutters
} from '../../spacing'
import {
  svpTheme
} from '../../theme'

const {pxToRem} = svpTheme.typography

export const paymentForm = {
  creditCardLabel: {
    root: {
      ...labels.formBlockLabel,
      lineHeight: 1,
      paddingBottom: 0
    }
  },
  disclaimerComponent: {
    container: {
      marginTop: lvpGutters
    }
  },
  disclaimerLabel: {
    root: {
      color: bodyGrey,
      fontSize: pxToRem(14)
    }
  },
  dropdownComponent: {
    dropdown: {
      root: {
        ...inputs.formButton
      }
    },
    menuRoot: {
      padding: 0
    },
    selectComponent: {
      fontWeight: bold
    }
  },
  expirationLabel: {
    root: {
      fontSize: '1rem'
    }
  },
  formContainer: {
    item: {
      ...forms.groupContainerForm
    }
  },
  formComponentContainer: {
    container: {
      margin: 0,
      ...forms.cardForm,
      ...layout.responsiveGutters
    }
  },
  billingInformationComponentContainer: {
    container: {
      margin: 0,
      ...forms.cardForm,
      ...layout.responsiveGutters,
      marginTop: 0,
      borderTop: `${pxToRem(1)} solid ${ruleGrey}`
    }
  },
  label: {
    root: {
      ...labels.leadingLabel
    }
  },
  selectItemComponent: {
    root: {
      ...svpTheme.typography.button,
      ...inputs.buttonPadding,
      border: `${pxToRem(1)} solid ${ruleGrey}`,
      textTransform: 'capitalize'
    }
  },
  requiredLabel: {
    root: {
      ...labels.formRequiredLabel
    }
  }
}
