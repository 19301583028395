import {mediumPadding} from '../spacing'

export const loadingProps = {
  color: 'secondary',
  size: '98px',
  thickness: 2
}
export const interstitialProps = {
  justify: 'center'
}
export const noResultsStyles = ({palette: {primary}}) => ({
  header: {
    color: primary.dark,
    paddingTop: mediumPadding
  }
})
