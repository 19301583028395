import {
  bannerDescriptionLetterSpacing,
  bannerTitleLetterSpacing,
  bodyTextSize,
  bold,
  headerNavFontSize,
  regular
} from '../font'
import {heavyPadding, lightPadding, minimalPadding, mvpPadding, noPadding, svpPadding} from '../spacing'
import {white, wyzardSuitBlue} from '../colors'
import {
  buttons
} from '../base-styles'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

const root = {
  color: white,
  backgroundColor: wyzardSuitBlue
}

export const banner = {
  button: {
    root: {
      textTransform: 'capitalize',
      ...buttons.bannerButton
    }
  },
  title: {
    root: {
      ...root,
      fontWeight: bold,
      fontSize: headerNavFontSize,
      letterSpacing: bannerTitleLetterSpacing,
      padding: [[mvpPadding, heavyPadding, minimalPadding, lightPadding]],
      [svpTheme.breakpoints.down('sm')]: {
        padding: [[lightPadding, svpPadding, minimalPadding, svpPadding]],
        fontSize: bodyTextSize,
        fontWeight: regular,
        letterSpacing: bannerDescriptionLetterSpacing,
        lineHeight: pxToRem(15)
      }
    }
  },
  description: {
    root: {
      ...root,
      letterSpacing: bannerDescriptionLetterSpacing,
      padding: [[noPadding, heavyPadding, mvpPadding, lightPadding]],
      [svpTheme.breakpoints.down('sm')]: {
        padding: [[noPadding, svpPadding, lightPadding, svpPadding]],
        fontSize: bodyTextSize,
        lineHeight: pxToRem(15)
      }
    }
  }
}
