import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {brandingStyles} from '../../styles/component-styles/branding'
import {compose} from 'redux'
import {resolveHomeURL} from './util'
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'

class LogoWrapper extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string
    }),
    src: PropTypes.string,
    alt: PropTypes.string,
    location: PropTypes.shape({})
  }
  render () {
    const {classes, location, ...imgProps} = this.props
    return (
      <a href={resolveHomeURL(location)} className={classes.root}>
        <img {...imgProps}/>
      </a>
    )
  }
}

export default compose(withRouter, withStyles(brandingStyles))(LogoWrapper)
