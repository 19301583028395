/* eslint-disable max-lines */
import {
  bodyTextSize,
  bold,
  heroSmallTextSize,
  highlightsTextSize,
  medium,
  subheaderTextSize
} from '../font'
import {
  errorRed,
  linkBlue,
  linkLightBlue,
  ruleGrey,
  titaniumMember,
  wyzardSuitBlue
} from '../colors'
import {
  fullWidth,
  heavyPadding,
  lightPadding,
  lvpColumns,
  lvpGutters,
  lvpMargins,
  lvpPadding,
  minimalPadding,
  mvpColumns,
  mvpGutters,
  mvpMargins,
  noPadding,
  smallMargin,
  svpGutters
} from '../spacing'
import {inputs, labels} from '../base-styles'
import {cancellationModal} from './cancellation-modal'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const pendingReservationFooter = {
  footer: {
    marginTop: pxToRem(lvpMargins),
    [svpTheme.breakpoints.down('sm')]: {
      marginTop: pxToRem(mvpMargins)
    }
  }
}

export const reservationPage = {
  cancelInfo: {
    errorContainer: {
      container: {
        margin: [[40, 20]],
        [svpTheme.breakpoints.down('sm')]: {
          margin: [[lvpGutters * 3, 0]]
        }
      }
    },
    container: {
      container: {
        marginTop: 30,
        backgroundColor: '#FCE5E5',
        padding: lightPadding
      }
    },
    title: {
      root: {
        color: errorRed,
        margin: [[noPadding, 20, 10, 20]]
      }
    },
    content: {
      root: {
        color: errorRed,
        margin: [[noPadding, 20, 10, 20]]
      }
    }
  },
  layout: {
    container: {
      padding: [[lvpPadding, 56, heavyPadding, 56]],
      [svpTheme.breakpoints.down('md')]: {
        margin: 0,
        padding: mvpGutters
      },
      [svpTheme.breakpoints.down('sm')]: {
        margin: 0,
        padding: '1rem'
      }
    }
  },
  rightContainer: {
    root: {
      paddingLeft: lightPadding * 2,
      [svpTheme.breakpoints.down('sm')]: {
        padding: [[lightPadding * 2, 0]]
      }
    }
  },
  cancellationModal: {
    ...cancellationModal
  },
  cancellationHeader: {
    container: {
      container: {
        padding: [[lvpPadding, 56, noPadding, 56]],
        [svpTheme.breakpoints.down('md')]: {
          margin: 0,
          padding: mvpGutters
        },
        [svpTheme.breakpoints.down('sm')]: {
          margin: 0,
          padding: '1rem'
        }
      }
    },
    title: {
      root: {
        color: errorRed,
        fontSize: highlightsTextSize,
        fontWeight: bold,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: subheaderTextSize,
          paddingTop: lvpPadding
        }
      }
    },
    subtitle: {
      root: {
        color: titaniumMember,
        fontSize: svpGutters,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: bodyTextSize
        }
      }
    },
    buttonText: {
      root: {
        color: linkBlue,
        padding: [[noPadding, mvpGutters, noPadding, 10]]
      }
    }
  },
  terms: {
    panel: {
      root: {
        width: fullWidth
      }
    },
    header: {
      root: {
        fontSize: heroSmallTextSize,
        color: linkBlue
      }
    },
    container: {
      container: {
        marginTop: mvpGutters,
        padding: [[minimalPadding, 10]],
        border: `1px solid ${ruleGrey}`
      }
    },
    details: {
      root: {
        margin: [[mvpGutters, noPadding]]
      }
    },
    summary: {
      root: {
        width: fullWidth,
        minHeight: pxToRem(48),
        '&$expanded': {
          minHeight: pxToRem(48)
        }
      },
      expanded: {}
    }
  },
  earn: {
    container: {
      container: {
        paddingBottom: lvpColumns,
        border: `1px solid ${ruleGrey}`,
        borderTop: 'none',
        marginTop: lvpGutters
      }
    },
    text: {
      root: {
        fontWeight: medium,
        fontSize: heroSmallTextSize,
        color: wyzardSuitBlue,
        margin: [[svpGutters, mvpGutters * 2, noPadding, lvpColumns]],
        [svpTheme.breakpoints.down('sm')]: {
          margin: [[svpGutters, 0, noPadding, mvpGutters]]
        }
      }
    },
    iconContainer: {
      container: {
        marginTop: mvpGutters,
        [svpTheme.breakpoints.down('sm')]: {
          margin: [[mvpGutters * 2, svpGutters]]
        }
      }
    },
    loyaltyLine: {
      container: {
        height: pxToRem(7)
      }
    }
  },
  errorContainer: {
    container: {
      ...labels.errorMessage,
      ...labels.errorContainer
    }
  },
  errorIcon: {
    root: {
      marginRight: svpGutters,
      marginLeft: mvpGutters,
      [svpTheme.breakpoints.only('md')]: {
        marginLeft: 0
      },
      [svpTheme.breakpoints.down('sm')]: {
        marginLeft: smallMargin
      }
    }
  },
  errorText: {
    root: {
      ...labels.errorText
    }
  },
  emailModal: {
    closeButton: {
      root: {
        padding: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        '&:hover': {
          backgroundColor: 'inherit'
        }
      }
    },
    dialog: {
      paper: {
        width: fullWidth,
        [svpTheme.breakpoints.up('md')]: {
          padding: lightPadding
        }
      }
    },
    dialogTitle: {
      root: {
        margin: 0,
        fontWeight: medium,
        fontSize: highlightsTextSize,
        color: wyzardSuitBlue,
        [svpTheme.breakpoints.down('sm')]: {
          fontSize: 18,
          fontWeight: 'normal'
        }
      }
    },
    emailInput: {
      root: {
        margin: [[lvpMargins, 0]],
        [svpTheme.breakpoints.down('sm')]: {
          margin: [[svpGutters, mvpColumns]]
        }
      }
    },
    header: {
      container: {
        padding: [[svpGutters, mvpColumns]],
        [svpTheme.breakpoints.down('sm')]: {
          ...inputs.svpInput
        }
      }
    },
    modalButton: {
      root: {
        color: linkBlue,
        backgroundColor: linkLightBlue
      }
    },
    buttonContainer: {
      container: {
        width: 'auto',
        margin: [[0, 0, mvpColumns, 0]]
      }
    }
  }
}
