import {inputs, labels} from '../base-styles/index'

export const summary = {
  card: inputs.svpInput,
  icon: inputs.editIcon,
  subtitle: labels.svpSearch.subtitle,
  title: labels.svpSearch.title,
  summary: {
    position: 'sticky',
    top: 64, // 1px less than the height of fixed header above
    zIndex: 1000000 // designed to keep above infinite scroll results
  }
}
