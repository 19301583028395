import React, {Fragment} from 'react'
import Hidden from '@material-ui/core/Hidden'
import {NavDropdown} from './nav-dropdown'
import UserTierBar from './tier-bar'
import WyndhamNavMain from './nav'

const WyndhamNav = props => (
  <Fragment>
    <Hidden smDown>
      <WyndhamNavMain {...props} />
    </Hidden>
    <Hidden mdUp>
      <UserTierBar/>
      <NavDropdown {...props} />
    </Hidden>
  </Fragment>
)

export default WyndhamNav
