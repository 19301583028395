import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import {ReactComponent as LogoSvg} from '../../img/door-dash-hero-logo.svg'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {logoWrapper} from '../../styles/component-styles/logo-wrapper'
import {withStyles} from '@material-ui/core/styles'

class LogoWrapper extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      image: PropTypes.string,
      root: PropTypes.string,
      poweredBy: PropTypes.string
    })
  }
  render () {
    const {classes} = this.props
    return (
      <Grid container direction="column" alignItems="flex-end" className={classes.root}>
        <Grid item >
          <Typography className={classes.poweredBy}>
            Powered By
          </Typography>
        </Grid>
        <Grid item>
          <LogoSvg className={classes.image}/>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(logoWrapper)(LogoWrapper)
