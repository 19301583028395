
import React, {PureComponent} from 'react'
import Destinations from './destinations'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Title from './title'
import {componentType} from '@iseatz/framework'

export default class Container extends PureComponent {
    static propTypes = {
      destinationItems: PropTypes.arrayOf(PropTypes.object),
      MainIconComponent: componentType,
      title: PropTypes.string.isRequired
    }
    render () {
      const {destinationItems, MainIconComponent, title} = this.props
      return (
        <Grid container justify="center" alignContent="center">
          <Title MainIconComponent={MainIconComponent} title={title}/>
          <Destinations destinationItems={destinationItems} />
        </Grid>)
    }
}
