import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {heroStyling} from '../../styles/component-styles'
import {withStyles} from '@material-ui/core/styles'

export class SearchSubTitle extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      homeSubtitle: PropTypes.string,
      homeSecondSubtitle: PropTypes.string,
      homeTitle: PropTypes.string,
      homeTitleContainer: PropTypes.string,
      title: PropTypes.string
    }),
    lineOne: PropTypes.string,
    lineTwo: PropTypes.string,
    lineThree: PropTypes.string
  }
  static defaultProps = {
    lineThree: null
  }

  render () {
    const {classes, lineOne, lineTwo, lineThree} = this.props
    let LineThreeComponent
    if (lineThree) {
      LineThreeComponent
      = <Grid item xs={12}>
          <Typography className={classes.homeSubtitle}>{lineThree}</Typography>
        </Grid>
    }

    return (
      <Grid container direction="column" className={classes.homeTitleContainer}>
        <Grid item xs={12}>
          <Typography className={classes.homeTitle}>{lineOne}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.homeSubtitle}>{lineTwo}</Typography>
        </Grid>
        {LineThreeComponent}
      </Grid>
    )
  }
}

export default withStyles(heroStyling)(SearchSubTitle)
