import {linkBlue, wyzardSuitBlue} from '../colors'
import {mediumTextLetterSpacing, searchSortLetterSpacing, sortActivitiesTextSize} from '../font'
import {minimalPadding, noPadding} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const searchSort = {
  sortContainer: {
    container: {
      margin: [['.75rem', noPadding, '1rem', noPadding]]
    }
  },
  sortLabel: {
    root: {
      paddingRight: '1rem',
      fontSize: sortActivitiesTextSize,
      letterSpacing: searchSortLetterSpacing,
      color: wyzardSuitBlue
    }
  },
  sortSelect: {
    root: {
      [svpTheme.breakpoints.up('md')]: {
        fontSize: sortActivitiesTextSize,
        color: linkBlue,
        letterSpacing: searchSortLetterSpacing
      },
      [svpTheme.breakpoints.down('sm')]: {
        border: `1px solid ${svpTheme.palette.secondary.light}`,
        padding: '.5rem 0 .25rem .75rem',
        fontFamily: svpTheme.typography.button.fontFamily
      }
    },
    icon: {
      [svpTheme.breakpoints.up('md')]: {
        color: linkBlue
      }
    },
    select: {
      width: '100%'
    }
  },
  searchLabel: {
    root: {
      display: 'inline-block',
      color: wyzardSuitBlue,
      [svpTheme.breakpoints.up('sm')]: {
        fontSize: pxToRem(21)
      },
      padding: [[noPadding, noPadding, noPadding, noPadding]],
      letterSpacing: mediumTextLetterSpacing
    }
  },
  searchCountLabel: {
    root: {
      display: 'inline-block',
      color: wyzardSuitBlue,
      [svpTheme.breakpoints.up('sm')]: {
        fontSize: pxToRem(26)
      },
      padding: [[pxToRem(5), minimalPadding, noPadding, pxToRem(25)]],
      letterSpacing: mediumTextLetterSpacing,
      [svpTheme.breakpoints.down('md')]: {
        paddingLeft: 12
      },
      [svpTheme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    }
  }
}
