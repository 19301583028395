import {
  ActivitiesHero,
  SearchBar,
  withLocation
} from '@iseatz/ams-activities'
import React, {Fragment, PureComponent} from 'react'
import {AutocompleteBaseProps} from './autocomplete-base'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import SubtitleComponent from './subtitle'
import TitleComponent from './title'
import activitiesHeroImage from '../../img/activities_hero.png'
import activitiesHeroImageSVP from '../../img/activities_heroSVP.jpg'
import {compose} from 'redux'
import {dateIsAfterYearFromNow} from '../../utils'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {heroStyling} from '../../styles'
import {withStyles} from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

class SearchHero extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    city: PropTypes.string,
    classes: PropTypes.shape({
      button: PropTypes.string,
      searchbar: PropTypes.string
    }),
    width: PropTypes.string
  }

  render () {
    const {
      classes,
      children,
      city,
      width
    } = this.props

    const height = (width !== 'xs' && width !== 'sm') ? '20rem' : '12rem'
    const heroImage = (width !== 'xs' && width !== 'sm') ? activitiesHeroImage : activitiesHeroImageSVP

    return (
      <Fragment>
        <ActivitiesHero
          height={height}
          image={heroImage}
          style={{overflow: 'visible'}}
          SubtitleComponent={SubtitleComponent}
          subtitle={city}
          title={getKey(dictionary, 'sections.activity.components.hero.title')}
          TitleComponent={TitleComponent}
        >
          <Grid container justify="center">
            <Hidden smDown>
              <SearchBar
                label={getKey(dictionary, 'sections.activity.components.searchbar.label')}
                alignItems="center"
                justify="space-around"
                selectDates={true}
                spacing={8}
                buttonLabel={getKey(dictionary, 'sections.activity.components.searchbar.button')}
                ButtonProps={{
                  className: classes.button
                }}
                className={classes.searchbar}
                AutocompleteProps={{...AutocompleteBaseProps}}
                DateProps={{
                  additionalOutsideRange: [dateIsAfterYearFromNow]
                }}
              />
            </Hidden>
          </Grid>
          {children}
        </ActivitiesHero>
      </Fragment>
    )
  }
}
const enhance = compose(withWidth(), withStyles(heroStyling), withLocation)

export default enhance(SearchHero)
