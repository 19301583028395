import {forms, labels} from '../base-styles'
import {
  lightPadding,
  lvpGutters,
  lvpPadding,
  mvpPadding,
  svpPadding
} from '../spacing'

export const activityInformationForm = {
  formComponentContainer: {
    container: {
      ...forms.cardForm,
      padding: [[svpPadding, mvpPadding, lvpPadding, mvpPadding]]
    }
  },
  inputContainer: {
    item: {
      marginTop: lightPadding
    }
  },
  label: {
    root: {
      ...labels.leadingLabel,
      marginTop: lvpGutters
    }
  },
  requiredLabel: {
    root: {
      ...labels.formRequiredLabel
    }
  }
}
