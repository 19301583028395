import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {heroStyling} from '../../styles'
import {withStyles} from '@material-ui/core/styles'

class Subtitle extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      subtitle: PropTypes.string
    }),
    children: PropTypes.string
  }
  render () {
    const {classes, children} = this.props
    return (
      <Grid container justify="center">
        <Typography className={classes.subtitle}>{children}</Typography>
      </Grid>
    )
  }
}

export default withStyles(heroStyling)(Subtitle)
