
import {
  fullWidth,
  lvpPadding,
  lvpPageGutters,
  mvpSvpPageGutters,
  noPadding
} from '../../spacing'
import {svpTheme} from '../../theme'

export const checkoutBase = {
  pageContainer: {
    container: {
      margin: 0,
      [svpTheme.breakpoints.up('lg')]: {
        padding: [[noPadding, lvpPageGutters, lvpPadding, lvpPageGutters]]
      },
      [svpTheme.breakpoints.only('md')]: {
        padding: [[noPadding, mvpSvpPageGutters]]
      },
      [svpTheme.breakpoints.down('sm')]: {
        alignItems: 'center',
        flexDirection: 'column-reverse'
      },
      width: fullWidth
    }
  }
}
