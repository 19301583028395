import React, {PureComponent} from 'react'
import {lvpPageGutters, mvpSvpPageGutters, svpTheme} from '../../styles'
import {CoreHero} from '@iseatz/ams-core'
import DoordashDelivery from '../food-delivery-order-now-container/food-delivery-order-now-container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import LoggedMainContent from './logged-main-content'
import LogoWraper from './logo-wrapper'
import PropTypes from 'prop-types'
import SearchSubTitle from './food-search-subtitle'
import {compose} from 'redux'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import homeHeroImage from '../../img/burgers-hero.png'
import {withBreadcrumbs} from '../header/withBreadcrumbs'
import {withLocation} from '@iseatz/ams-activities'
import {withStyles} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'

const guestContent = (
  <Grid container justify="flex-start" alignItems="center">
    <Grid item md={5} lg={6}>
      <Hidden smDown>
        <DoordashDelivery />
      </Hidden>
    </Grid>
    <Grid item xs={12} md={6}>
      <SearchSubTitle
        lineOne={getKey(dictionary,
          'sections.activity.components.foodDeliverySearchHeroHome.titleSubTitle.lineOne'
        )}
        lineTwo={getKey(dictionary,
          'sections.activity.components.foodDeliverySearchHeroHome.titleSubTitle.lineTwo'
        )}
        lineThree={getKey(dictionary,
          'sections.activity.components.foodDeliverySearchHeroHome.titleSubTitle.lineThree'
        )}
      />
    </Grid>
  </Grid>
)

const GridBlock = withStyles({
  root: {
    margin: [[0, lvpPageGutters]],
    [svpTheme.breakpoints.only('md')]: {
      margin: [[0, mvpSvpPageGutters]]
    },
    [svpTheme.breakpoints.down('sm')]: {
      margin: [[0, 16]]
    }
  }
})(({classes, children}) => (<div className={classes.root}>{children}</div>))

class SearchHeroHome extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    updateBreadcrumbs: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    width: PropTypes.string
  }

  componentDidMount () {
    this.props.updateBreadcrumbs()
  }

  render () {
    const {
      children,
      width,
      isLoggedIn,
      user
    } = this.props
    let height = isLoggedIn ? '28rem' : 'auto'
    let ContainerComponent = GridBlock
    if (width === 'xs' || width === 'sm') {
      height = '12rem'
      ContainerComponent = Grid
    }

    const loggedInContent = (
      <LoggedMainContent user={user} />
    )
    const mainContent = isLoggedIn ? loggedInContent : <ContainerComponent >{guestContent}</ContainerComponent>

    return (
      <CoreHero
        height={height}
        image={homeHeroImage}
        showLogo LogoComponent={LogoWraper}
      >
        {mainContent}
        {children}
      </CoreHero>
    )
  }
}
const enhance = compose(withWidth(), withLocation, withBreadcrumbs)

export default enhance(SearchHeroHome)
