import React, {PureComponent} from 'react'
import {getDropdownButtonStyles, linkBlue} from '../styles'
import PropTypes from 'prop-types'

class DropdownMinus extends PureComponent {
  static propTypes = {
    background: PropTypes.string,
    border: PropTypes.string,
    inner: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    background: 'none',
    border: linkBlue,
    inner: linkBlue,
    size: 24
  }

  constructor (props) {
    super(props)
    this.state = {
      active: false
    }
    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
  }

  handleMouseDown () {
    this.setState({
      active: true
    })
  }

  handleMouseUp () {
    this.setState({
      active: false
    })
  }

  render () {
    const {size} = this.props
    const styles = getDropdownButtonStyles(this.state)

    return (
      <svg
        version="1.1"
        id="Minus_-_Active"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        height={size}
        width={size}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseUp}>
        <g id="Background_1_">
          <g>
            <path fill={styles.background} d="M1,23h22V1H1V23z"/>
          </g>
        </g>
        <polygon id="Border" fill={styles.inner} points="8,11 8,12 16,12 16,11 "/>
        <g id="Icon">
          <g id="Page-1">
            <g>
              <path fill={styles.border} d="M24,24H0V0h24V24z M1,23h22V1H1V23z"/>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export {DropdownMinus}
