import React, {PureComponent} from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {Dialog} from '@iseatz/ams-core'
import Grid from '@material-ui/core/Grid'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {SvpSearchBarBase} from './svp-searchbar'
import UpdateButton from './update-button'
import {compose} from 'redux'
import {connect} from 'react-redux'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {isNil} from '@iseatz/framework'
import {search} from '@iseatz/ams-activities'
import {searchModal} from '../../styles'
import {withStyles} from '@material-ui/core/styles'
const {selectors, utils} = search

export class SvpSearchDialogBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    newUrl: PropTypes.string,
    onClose: PropTypes.func,
    locationCriteria: ImmutablePropTypes.mapContains({}),
    currentSearch: ImmutablePropTypes.mapContains({})
  }

  static defaultProps = {
    newUrl: ''
  }

  render () {
    const {
      classes,
      currentSearch,
      newUrl,
      onClose,
      locationCriteria,
      ...otherProps
    } = this.props
    const footer = () =>
      <Grid container justify="flex-end" className={classes.buttonContainer}>
        <UpdateButton
          disabled={isNil(locationCriteria)}
          component={Link}
          to={utils.createSearchUrl(currentSearch,
            {...(locationCriteria && {
              locationId: locationCriteria.get('value'),
              locationXname: locationCriteria.get('name'),
              locationType: locationCriteria.get('locationType')})})}
          onClick={onClose}>
          {getKey(
            dictionary, 'sections.activity.components.activitiesSearchHeroHome.dateModal.button'
          )}
        </UpdateButton>
      </Grid>

    return (
      <Dialog
        {...otherProps}
        className={classes.dialog}
        ActionProps={{className: classes.action}}
        BodyComponent={() => <SvpSearchBarBase
          classes={classes}
          onAdditionalAction={onClose}
        />}
        CloseIconComponent={CloseIcon}
        CloseIconProps={{className: classes.closeIcon}}
        FooterContent={footer}
        HeaderProps={{
          alignItems: 'center',
          className: classes.header,
          container: true
        }}
        onClose={onClose}
        title={getKey(
          dictionary, 'sections.activity.components.activitiesSearchHeroHome.searchModal.title'
        )}
        TitleProps={{className: classes.title, inline: true}}
      />)
  }
}
const mapDispatchToProps = () => ({})
const mapStateToProps = state => ({
  locationCriteria: selectors.getCriteriaLocation(state),
  currentSearch: selectors.getSearchQuery(state)
})

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(searchModal)
)

export const SvpSearchDialog = enhance(SvpSearchDialogBase)
