export const heroTitle = ({
  typography, breakpoints
}) => ({
  homeTitleContainer: {
    [breakpoints.down('md')]: {
      marginLeft: 24
    },
    [breakpoints.down('sm')]: {
      margin: [['1.5rem', 0, 0, 0]],
      display: 'flex',
      alignItems: 'flex-end'
    }
  },

  homeTitle: {
    [breakpoints.down('md')]: {
      fontSize: 40
    },
    [breakpoints.down('sm')]: {
      fontSize: 30,
      paddingRight: '0.3rem'
    }
  }
})
