import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {breadcrumbs} from '@iseatz/ams-activities'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {rewardsHomeInfo} from '../../config'
import {withModule} from './withModule'
import {withRouter} from 'react-router-dom'

const {
  actions
} = breadcrumbs

const disableInPathname = '/session-token'
const noop = () => null

export const withBreadcrumbs = WrappedComponent => {
  class WithBreadcrumbs extends PureComponent {
    static propTypes = {
      history: PropTypes.shape({}),
      location: PropTypes.shape({
        pathname: PropTypes.string
      }).isRequired,
      module: PropTypes.shape({
        link: PropTypes.string,
        name: PropTypes.string,
        pathIndex: PropTypes.number
      }).isRequired,
      update: PropTypes.func.isRequired
    }

    render () {
      const {
        location,
        update
      } = this.props

      if (location.pathname === disableInPathname) {
        return (<WrappedComponent
          updateBreadcrumbs={noop}
          {...this.props} />)
      }

      const {
        module: {
          pathIndex,
          ...moduleRest
        }
      } = this.props

      const updateBreadcrumbs = () => update({
        index: pathIndex,
        root: {
          ...rewardsHomeInfo
        },
        route: {
          ...moduleRest
        },
        pathname: location.pathname
      })

      return (
        <WrappedComponent
          updateBreadcrumbs={updateBreadcrumbs}
          {...this.props} />
      )
    }
  }
  const mapDispatchToProps = {
    update: actions.updateBreadcrumbs
  }
  return compose(
    connect(null, mapDispatchToProps),
    withModule,
    withRouter
  )(WithBreadcrumbs)
}
