import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {Typography} from '@material-ui/core'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {participantsRange} from '../styles/component-styles/details/index'
import {selectors} from '@iseatz/ams-activities'
import {withStyles} from '@material-ui/core/styles'

class ParticipantsRangeBase extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      label: PropTypes.string,
      max: PropTypes.string,
      min: PropTypes.string,
      rangeContainer: PropTypes.string,
      separator: PropTypes.string
    }),
    labelText: PropTypes.string,
    maxParticipants: PropTypes.number,
    minParticipants: PropTypes.number
  }
  static defaultProps = {
    labelText: 'NUMBER OF TRAVELERS (Min-Max)'
  }
  render () {
    const {
      classes,
      labelText,
      maxParticipants,
      minParticipants,
      ...rest} = this.props
    return (
      <Grid
        container
        classes={{
          container: classes.container
        }}
        spacing={24}
        {...rest}
      >
        <Grid
          item
          component={Typography}
          className={classes.label}
          alignItems="center"
        >
          {labelText}
        </Grid>
        <Grid
          item
          container
          classes={{
            container: classes.rangeContainer
          }}
          xs={2}>
          <Typography className={classes.min}>{minParticipants}</Typography>
          <Typography className={classes.separator}>-</Typography>
          <Typography className={classes.max}>{maxParticipants}</Typography>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  maxParticipants: selectors.getActivityMaxParticipants(state),
  minParticipants: selectors.getActivityMinParticipants(state)
})

export const ParticipantsRange = compose(
  connect(mapStateToProps),
  withStyles(participantsRange)
)(ParticipantsRangeBase)
