// Wyndham Color Palette
export const backgroundBlue = '#e4f7ff'
export const linkLightBlue = '#93e1ff'
export const linkBlue = '#005ead'
export const wyzardSuitBlue = '#202c5d'
export const backgroundGrey = '#f4f4f4'
export const backgroundLightGrey = '#f8f8f8'
export const borderGrey = '#e2e2e2'
export const ruleGrey = '#d9d9d9'
export const bodyGrey = '#555555'
export const legalGrey = '#666666'
export const titaniumMember = '#333333'
export const headerInfoColor = '#4A4A4A'
export const gold = '#a06519'
export const errorRed = '#e00000'
export const transparentErrorRed = 'rgba(224,0,0,0.1)'
export const successGreen = '#178217'

export const white = '#ffffff'
export const black = '#000000'
