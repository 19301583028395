import {
  lvpPadding,
  mvpPadding,
  mvpSvpPageGutters,
  noPadding
} from '../spacing'
import {white, wyzardSuitBlue} from '../colors'
import charleston from '../../img/charleston.jpg'
import {globalFont} from '../font'
import london from '../../img/london.jpg'
import newYork from '../../img/new_york.jpg'
import orlando from '../../img/orlando.jpg'
import {svpTheme} from '../theme'

export const destinationsStyles = ({breakpoints}) => ({
  root: {
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
      justifyContent: 'flex-start',
      padding: [[0, mvpSvpPageGutters]]
    }
  },
  gridList: {
    flexWrap: 'nowrap',
    overflowY: 'unset'
  }
})

export const destinationsTitle = ({breakpoints, typography}) => ({
  container: {
    padding: [['3rem', noPadding, mvpSvpPageGutters, noPadding]]
  },
  title: {
    fontFamily: globalFont,
    fontSize: typography.title.fontSize,
    lineHeight: 2,
    textAlign: 'center',
    color: wyzardSuitBlue,
    [breakpoints.down('sm')]: {
      fontSize: typography.subtitle2.fontSize
    }
  }
})

const destinationCardTitleFont = {
  fontSize: 20,
  color: white,
  fontFamily: globalFont
}

export const destinationCard = {
  titleContainer: {
    background: `linear-gradient(0deg, rgba(0,0,0,0.65) 30%, rgba(0,0,0,0) 100%)`
  },
  titleBar: {
    background: 'none',
    lineHeight: 3,
    margin: '0 15px 0 15px',
    ...destinationCardTitleFont
  },
  cardContent: {
    listStyle: 'none',
    height: 189,
    width: 267
  },
  rootContent: {
    padding: 0,
    paddingRight: lvpPadding,
    [svpTheme.breakpoints.down('md')]: {
      paddingRight: mvpPadding
    },
    [svpTheme.breakpoints.down('sm')]: {
      paddingRight: mvpSvpPageGutters
    }
  },
  link: {
    textDecoration: 'none'
  },
  NY: {
    background: `url(${newYork})`
  },
  SC: {
    background: `url(${charleston})`
  },
  FL: {
    background: `url(${orlando})`
  },
  UK: {
    background: `url(${london})`
  }
}
