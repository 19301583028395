import {globalFont, logoTextSize} from '../font'
import {lvpPageGutters, mvpSvpPageGutters} from '../spacing'
import {svpTheme} from '../theme'
import {white} from '../colors'

export const badge = {
  color: white,
  fontFamily: globalFont,
  fontSize: logoTextSize,
  letterSpacing: 0.48
}

export const badgeContainer = {
  container: {
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingTop: '0.5rem',
    paddingRight: '0.5rem',
    [svpTheme.breakpoints.down('md')]: {
      paddingRight: mvpSvpPageGutters
    },
    [svpTheme.breakpoints.up('lg')]: {
      paddingRight: lvpPageGutters
    }
  }
}
