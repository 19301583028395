// Font Sizes
export const logoTextSize = 10
export const TinyTextLetterSpacing = 0.57
export const smallTextLetterSpacing = 0.71
export const mediumTextLetterSpacing = 0.86
export const largeTextLetterSpacing = 1.47
export const navButtonsLetterSpacing = 1.07
export const bannerTitleLetterSpacing = 1.6
export const bannerDescriptionLetterSpacing = 1.14
export const searchSortLetterSpacing = 1
export const bodyLineHeight = 14
export const bodyTextSize = 12
export const buttonTextSize = 15
export const formBlockLabel = 15
export const offerTextSize = 14
export const headerNavFontSize = 16
export const headerNavLineHeight = 20
export const headerTextSize = 36
export const headerLineHeight = 41
export const heroLargeTextSize = 40
export const heroSmallTextSize = 20
export const highlightsTextSize = 24
export const priceSymbolSize = 10
export const smallTitleTextSize = 13
export const subheaderTextSize = 18
export const subheaderLineHeight = 21
export const svgIconLargeSize = 34
export const smallFontSize = 8
export const signButtonTextSize = 14
export const sortActivitiesTextSize = 14
export const svpInputText = 16

// Fonts
export const regular = 400
export const medium = 500
export const bold = 700
export const extraBold = 900
export const globalFont = 'Fira Sans'
