import {Link, matchPath} from 'react-router-dom'
import React, {Fragment, PureComponent} from 'react'
import {createRoute, resolve} from '@iseatz/framework'
import {
  globalFont,
  headerStyling,
  linkBlue,
  regular,
  signButtonTextSize,
  smallTextLetterSpacing,
  svpTheme
} from '../styles'
import Button from '@material-ui/core/Button'
import {Profile} from './session/profile'
import PropTypes from 'prop-types'
import {buttons} from '../styles/base-styles'
import {compose} from 'redux'
import {whenFoodDeliveryEnabled} from '../utils'
import {withBreadcrumbs} from './header/withBreadcrumbs'
import {withLocation} from '@iseatz/ams-activities'
import {withStyles} from '@material-ui/core/styles'

const tourRootPath = 'route:path.activities'
const foodDeliveryRootPath = 'route:path.foodDelivery'

const NavButton = withStyles({
  root: {...buttons.navButton
  },
  label: {
    ...svpTheme.typography.button,
    color: linkBlue,
    textTransform: 'uppercase'
  }
})(Button)

const AccountButton = withStyles({
  root: buttons.accountButton,
  label: {
    ...svpTheme.typography.button,
    color: linkBlue,
    fontSize: signButtonTextSize,
    fontWeight: regular,
    letterSpacing: smallTextLetterSpacing,
    fontFamily: globalFont
  }
})(Button)

class WyndhamNav extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    location: PropTypes.object,
    module: PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
      pathIndex: PropTypes.number
    }),
    updateBreadcrumbs: PropTypes.func.isRequired
  }

  componentDidMount () {
    const {
      updateBreadcrumbs
    } = this.props
    updateBreadcrumbs()
  }

  setActiveStyles (currentRoute) {
    const {
      classes,
      location
    } = this.props

    return matchPath(location.pathname, {path: resolve(currentRoute)})
      ? {label: classes.selectedButton}
      : {}
  }

  render () {
    const maybeFoodDeliveryButton = whenFoodDeliveryEnabled(<NavButton
      component={Link}
      to={createRoute(foodDeliveryRootPath)}
      classes={this.setActiveStyles(foodDeliveryRootPath)}
    >Food Delivery</NavButton>)

    return (
      <Fragment>
        <NavButton component={Link}
          to={createRoute(tourRootPath)}
          classes={this.setActiveStyles(tourRootPath)}
        >
         Tours & Activities
        </NavButton>
        {maybeFoodDeliveryButton}
        <Profile
          onClose={() => null}
          ButtonComponent={AccountButton}
          signInOutContainerProps={{
            alignItems: 'center'
          }}/>
      </Fragment>
    )
  }
}

const enhance = compose(
  withStyles(headerStyling),
  withLocation,
  withBreadcrumbs)
export default enhance(WyndhamNav)
