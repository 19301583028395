import {
  TinyTextLetterSpacing,
  globalFont,
  headerNavLineHeight,
  navButtonsLetterSpacing,
  sortActivitiesTextSize,
  svgIconLargeSize
} from '../font'
import {
  legalGrey,
  linkBlue,
  linkLightBlue,
  ruleGrey,
  white,
  wyzardSuitBlue
} from '../colors'
import {
  lightPadding,
  lvpGutters,
  lvpPageGutters,
  minimalPadding,
  mvpPadding,
  mvpSvpPageGutters,
  noPadding
} from '../spacing'
import {inputs} from './inputs'
import {svpTheme} from '../theme'

const searchButtonHeight = 50

const navBarButtonHeight = 86

const {pxToRem} = svpTheme.typography

export const buttons = {
  actionButton: {
    ...svpTheme.typography.button,
    ...inputs.buttonPadding,
    backgroundColor: linkLightBlue,
    color: linkBlue
  },
  actionHover: {
    '&:hover': {
      backgroundColor: linkBlue,
      color: white
    }
  },
  invertedButton: {
    ...svpTheme.typography.button,
    ...inputs.buttonPadding,
    backgroundColor: white,
    color: linkBlue,
    marginTop: lvpGutters,
    textAlign: 'center',
    border: `2px solid ${linkLightBlue}`
  },
  invertedHover: {
    '&:hover': {
      backgroundColor: linkLightBlue,
      color: linkBlue
    }
  },
  accountButton: {
    borderLeft: 'solid',
    borderRight: 'solid',
    borderColor: ruleGrey,
    borderWidth: 1.4,
    height: navBarButtonHeight,
    marginRight: lvpPageGutters,
    padding: [[noPadding, minimalPadding]],
    width: 100,
    [svpTheme.breakpoints.only('md')]: {
      marginRight: mvpSvpPageGutters,
      padding: mvpPadding
    }
  },
  baseButton: {},
  buttonNoHover: {
    root: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  },
  bannerButton: {
    color: '#A4D9FC', // this color isn't a Wyndham color, but is on invision
    backgroundColor: wyzardSuitBlue,
    fontFamily: globalFont,
    height: '100%'
  },
  clear: {
    color: linkBlue,
    fontFamily: globalFont,
    fontSize: sortActivitiesTextSize,
    fontWeight: 'normal',
    letterSpacing: TinyTextLetterSpacing,
    textTransform: 'capitalize',
    width: '100%'
  },
  close: {
    color: legalGrey,
    fontSize: svgIconLargeSize,
    paddingRight: pxToRem(7),
    paddingTop: pxToRem(5)
  },
  navButton: {
    height: navBarButtonHeight,
    padding: [[noPadding, lightPadding]],
    lineHeight: headerNavLineHeight,
    letterSpacing: navButtonsLetterSpacing
  },
  searchbar: {
    height: searchButtonHeight,
    margin: -27,
    width: 140
  }
}

buttons.modalButton = {
  ...buttons.actionButton,
  ...buttons.buttonNoHover.root,
  height: pxToRem(38),
  margin: [[pxToRem(12), pxToRem(10), pxToRem(12), 0]],
  padding: 0,
  width: pxToRem(89)
}

buttons.disabledModalButton = {
  ...buttons.modalButton,
  color: ruleGrey,
  border: `1px solid ${ruleGrey}`,
  backgroundColor: white
}

export const getDropdownButtonStyles = internalState => {
  let styles = {
    background: 'none',
    border: linkBlue,
    inner: linkBlue
  }
  if (internalState.active === true) {
    styles = {
      background: linkBlue,
      border: linkBlue,
      inner: white
    }
  }
  return styles
}
