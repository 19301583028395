import React, {PureComponent} from 'react'
import {CoreLink} from '@iseatz/ams-core'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {footerLinkStyles} from '../../styles'
import {withStyles} from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

class FooterLinks extends PureComponent {
  render () {
    const {
      width,
      classes,
      termsAndConditionsLink,
      privacyPolicyLink
    } = this.props

    const direction = (width === 'xs' || width === 'sm') ? 'column' : 'row'

    return (
      <div className={classes.wrapper}>
        <Grid container className={classes.root} direction={direction} justify="center" alignItems="center">
          <Grid item xs={12} md={3} lg={2} className={classes.termsAndConditionsContainer}>
            <CoreLink
              LinkProps={{target: '_blank', className: classes.link}}
              to={termsAndConditionsLink}>
            Terms &amp; Conditions
            </CoreLink>
          </Grid>
          <Grid item xs={12} md={3} lg={2} className={classes.termsOfUseContainer}>
            <CoreLink
              LinkProps={{target: '_blank', className: classes.link}}
              to={privacyPolicyLink}>
            Privacy Policy
            </CoreLink>
          </Grid>
        </Grid>
      </div>
    )
  }
}

FooterLinks.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    termsAndConditionsContainer: PropTypes.string,
    termsOfUseContainer: PropTypes.string,
    link: PropTypes.string,
    wrapper: PropTypes.string
  }),
  termsAndConditionsLink: PropTypes.string,
  privacyPolicyLink: PropTypes.string,
  width: PropTypes.string
}
const enhance = compose(withWidth(), withStyles(footerLinkStyles))

export default enhance(FooterLinks)
