import {linkBlue} from '../colors'

export const button = ({palette, typography, breakpoints}) => ({
  root: {
    height: typography.pxToRem(50),
    fontFamily: typography.button,
    color: linkBlue,
    backgroundColor: palette.primary.light,
    '&:hover': {
      backgroundColor: palette.primary.main,
      color: palette.common.white
    },
    [breakpoints.down('md')]: {
      fontSize: typography.body.fontSize
    },
    [breakpoints.down('sm')]: {
      fontSize: typography.button.fontSize
    }
  }
})
export const buttonHomeContainer = () => ({
  item: {
    width: '100%',
    marginTop: '1rem'
  }
})
