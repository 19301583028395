import React, {PureComponent} from 'react'
import {destinationCard, container as styles} from '../../styles/component-styles/top-pics'
import {CoreGridList} from '@iseatz/ams-core'
import {DestinationCard} from '../destination-card'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {WhenWidthProps} from '../when-width-props/when-width-props'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
const SingleDestinationCard = withStyles(destinationCard)(DestinationCard)
const RightHandSideCards = withStyles(destinationCard)(DestinationCard)
const svpItemProps = { // GridListTile will add styles, we need to remove them
  style: {
    width: 190,
    height: 195,
    boxSizing: 'content-box'
  }
}
const svpProps = ({rightSummerPicks, ...originalProps}) => ({
  ...originalProps,
  coreProps: {
    cellHeight: 195,
    cols: 4,
    spacing: 12
  },
  cardSpacing: 0,
  rightSummerPicks: rightSummerPicks.map(pick => ({...pick, itemProps: svpItemProps}))
})

const mvpProps = originalProps => ({
  ...originalProps,
  coreProps: {
    cellHeight: 180,
    cols: 2,
    spacing: 8
  },
  cardSpacing: 8
})

class Container extends PureComponent {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      leftSummerPick: PropTypes.object.isRequired,
      rightSummerPicks: PropTypes.arrayOf(PropTypes.object).isRequired,
      title: PropTypes.string.isRequired,
      coreProps: PropTypes.shape({}),
      cardSpacing: PropTypes.number
    }

    static defaultProps = {
      coreProps: {
        cellHeight: 267,
        cols: 2,
        spacing: 32
      },
      cardSpacing: 32
    }

    render () {
      const {classes, title, leftSummerPick, rightSummerPicks, coreProps, cardSpacing} = this.props
      return (
        <Grid container direction="column" className={classes.root}>
          <Grid item>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          <Grid item className={classes.cardsContainer}>
            <Grid container direction="row" spacing={cardSpacing} className={classes.horizontalContainer}>
              <Grid item xs={3} md={6}>
                <SingleDestinationCard
                  item={leftSummerPick}
                />
              </Grid>
              <Grid item xs={9} md={6}>
                <CoreGridList
                  CardComponent={RightHandSideCards}
                  loading={false}
                  items={rightSummerPicks}
                  {...coreProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
}
const enhance = compose(
  WhenWidthProps({xs: svpProps, sm: svpProps, md: mvpProps}),
  withStyles(styles)
)

export default enhance(Container)
