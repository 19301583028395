import {
  bodyGrey,
  legalGrey,
  linkBlue,
  ruleGrey,
  white
} from '../colors'
import {
  bold,
  headerNavFontSize
} from '../font'
import {
  buttonPadding
} from '../spacing'

export const inputs = {
  buttonPadding: {
    padding: buttonPadding
  },
  editIcon: {
    fontSize: headerNavFontSize,
    color: linkBlue
  },
  formPlaceholder: {
    color: legalGrey
  },
  formButton: {
    background: white,
    '& > label': {
      color: legalGrey
    },
    '& input': {
      fontWeight: bold
    }
  },
  fadedIcon: {
    color: bodyGrey
  },
  relativeSizePopover: {
    boxShadow: 'none',
    display: 'table',
    left: '0 !important',
    maxWidth: '100%',
    position: 'absolute',
    top: '0 !important',
    width: '100%'
  },
  svpInput: {
    borderBottom: `1px solid ${ruleGrey}`,
    width: '100%'
  }
}
