import {SearchDateModal, SvpSearchDialog} from '../layout/svp'
import {
  addDateParamsIfNotPresent,
  roundedPriceWithLocale,
  searchContainsMandatoryParams
} from '../utils'
import {
  buttonContainer,
  card,
  dialogHeaderStyles,
  dialogTitleStyles,
  filterButtonStyles,
  filterLabel,
  iconStyles,
  interstitialProps,
  listItemStyles,
  noPadding,
  searchButton,
  searchFilter,
  searchFilterContainer,
  searchFilterLabel,
  searchFilterLayout,
  searchSort,
  subheaderStyles,
  summary,
  svpClearButton,
  svpTheme,
  svpUpdateButton,
  totalCountLabel
} from '../styles'
import Button from '@material-ui/core/Button'
import {CalendarWrapper} from '../layout/calendar-wrapper'
import Card from '@material-ui/core/Card'
import {ReactComponent as Checkbox} from '../img/Checkbox.svg'
import CloseIcon from '@material-ui/icons/Close'
import {Container} from '@iseatz/framework'
import Edit from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import NoResultsComponent from '../layout/no-results'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import {SearchCard} from '../components'
import SearchField from '../layout/search-field/search-field'
import Typography from '@material-ui/core/Typography'
import {ruleGrey} from '../styles/colors'
import {withStyles} from '@material-ui/core/styles'

// Config
Container.bind('config:ams.activities.searchbar.autocomplete.limit', 6)
Container.bind('config:ams.activities.sort.default', 'TOP_SELLERS')
Container.bind('config:ams.activities.sort.options', [
  {label: 'Top Sellers', value: 'TOP_SELLERS'},
  {label: 'Price: High to Low', value: 'PRICE_DESC'},
  {label: 'Price: Low to High', value: 'PRICE_ASC'}
])
Container.bind('config:ams.activities.searchFacets.priceOptions', [
  {label: '$25 or less', value: '1', min: 0, max: 25},
  {label: '$25 - $50', value: '2', min: 25, max: 50},
  {label: '$50 - $100', value: '3', min: 50, max: 100},
  {label: 'Over $100', value: '4', min: 100, max: null}
])

// Language
Container.bind('lang:ams.activities.search.label', 'Search Results')

// Page
Container.bind('props:ams.activities.searchPage', {
  applyDefaultParams: addDateParamsIfNotPresent,
  hasMandatorySearchParameters: searchContainsMandatoryParams
})

Container.bind('styles:ams.activities.search.layout', searchFilterLayout)
Container.bind('styles:ams.activities.search.filter', searchFilter)
Container.bind('styles:ams.activities.search.filterButton', {
  root: filterButtonStyles
})
Container.bind('props:ams.activities.search.filterDialog', {
  fullScreen: true,
  HeaderComponent: withStyles({
    container: dialogHeaderStyles
  })(Grid),
  CloseButtonComponent: IconButton,
  style: {zIndex: 1000001},
  title: 'Filter',
  TitleComponent: withStyles({
    root: dialogTitleStyles
  })(Typography),
  CloseIconComponent: CloseIcon
})
Container.bind('styles:ams.activities.search.label', {
  root: totalCountLabel
})
Container.bind('props:ams.activities.search.countContainer', {
  alignItems: 'baseline'
})

Container.bind('styles:ams.activities.search.countLabel', searchSort.searchCountLabel)

// Search Bar
Container.bind('component:ams.activities.searchbar', Paper)
Container.bind('component:ams.activities.searchbar.button', Button)
Container.bind('props:ams.activities.searchbar.autocomplete', {
  TextProps: {
    fullWidth: true
  },
  TextComponent: SearchField,
  SearchIconProps: {
    nativeColor: ruleGrey
  },
  minCharacters: 3
})
Container.bind('props:ams.activities.legacyDatepicker.datePicker', {
  WrapperComponent: CalendarWrapper,
  readOnly: true,
  minimumNights: 0
})
// SVP Loader
Container.bind('props:ams.activities.searchSummary.loader.progress', {
  color: 'secondary'
})
Container.bind('props:ams.activities.loading.loader.progressContainer', interstitialProps)
Container.bind('styles:ams.activities.loading.loader.progressContainer', {
  container: {
    marginTop: 10
  }
})
Container.bind('styles:ams.activities.searchSummary.loader.container', {
  container: summary.summary
})

// SVP Search Summary
Container.bind('props:ams.activities.search.searchSummary', {
  ActionComponent: withStyles({root: summary.icon})(Edit),
  CardComponent: withStyles({root: summary.card})(Card),
  TitleComponent: withStyles({root: summary.title})(Typography)
})

Container.bind('component:ams.activities.search.searchSummary.dateModal', SearchDateModal)
Container.bind('component:ams.activities.search.searchSummary.searchModal', SvpSearchDialog)

// Core -> Search Facets Component
Container.bind('props:ams.activities.searchFacets', {
  clearLabel: 'Clear',
  FacetProps: {
    CheckboxProps: {
      color: 'primary'
    }
  }
})

Container.bind('styles:ams.activities.searchFacets.buttonsContainer', {
  container: buttonContainer
})
Container.bind('props:ams.activities.searchFacets.container', {
  elevation: 0
})
Container.bind('styles:ams.activities.searchFacets.container', {
  root: searchFilterContainer
})
Container.bind('styles:ams.activities.searchFacets.title', {
  root: searchFilterLabel
})
Container.bind('styles:ams.activities.searchFacets.clearButton', {
  root: {
    ...searchButton,
    [svpTheme.breakpoints.down('sm')]: {
      ...svpClearButton
    }
  }
})
Container.bind('styles:ams.activities.searchFacets.updateButton', {
  root: svpUpdateButton
})

// Sort
Container.bind('styles:ams.activities.sort.select', searchSort.sortSelect)
Container.bind('styles:ams.activities.search.label', searchSort.searchLabel)
Container.bind('props:ams.activities.search.label', {
  align: 'center'
})
Container.bind('styles:ams.activities.sort.label', searchSort.sortLabel)
Container.bind('styles:ams.activities.sort.container', searchSort.sortContainer)

Container.bind('props:ams.activities.search.sort', {
  disableUnderline: true,
  ContainerProps: {
    justify: 'flex-end'
  }
})

// Core -> Facet Options Component
Container.bind('styles:ams.activities.facet.checkbox', {
  root: iconStyles
})
Container.bind('props:ams.activities.facet.checkbox', {
  icon: <Checkbox />
})

Container.bind('styles:ams.activities.facet.listItem', {
  root: listItemStyles
})
Container.bind('props:ams.activities.facet.title', {
  disableSticky: true
})
Container.bind('styles:ams.activities.facet.title', {
  root: subheaderStyles
})
Container.bind('styles:ams.activities.facet.label', {
  primary: filterLabel
})

// Activities Grid
Container.bind('component:ams.activities.grid.noResults', NoResultsComponent)
Container.bind('props:ams.activities.grid.list', {
  cellHeight: 'auto'
})
Container.bind('component:ams.activities.grid.card', SearchCard)
Container.bind('props:ams.activities.grid.card', {
  elevation: 0,
  square: true
})

// Activities Card
Container.bind('props:ams.activities.activityCard.container', {
  elevation: 0
})
Container.bind('styles:ams.activities.activityCard.content', {
  root: {
    padding: noPadding
  }
})
Container.bind('styles:ams.activities.activityCard.name', {
  root: card.name
})
Container.bind('styles:ams.activities.activityCard.actions', {
  root: card.actions
})
Container.bind('props:ams.activities.activityCard.divider', {
  separatorStyles: {
    minHeight: 12
  },
  isVertical: true,
  direction: 'column'
})
Container.bind('props:ams.activities.activityCard.divider.container', {
  container: false,
  item: true,
  md: 6
})
Container.bind('styles:ams.activities.activityCard.divider.labelComponent', {
  root: card.dividerLabel
})
Container.bind('props:ams.activities.activityCard.points', {
  label: 'STARTING AT',
  suffix: 'PTS',
  ContainerProps: {
    alignItems: 'baseline',
    xs: '8'
  }
})
Container.bind('styles:ams.activities.activityCard.points.suffix', {
  root: card.suffix
})
Container.bind('styles:ams.activities.activityCard.points.label', {
  root: card.label
})
Container.bind('styles:ams.activities.activityCard.points.amount', {
  root: card.pointsAmount
})
Container.bind('props:ams.activities.activityCard.price', {
  priceConverter: roundedPriceWithLocale,
  showCurrency: true,
  label: 'STARTING AT',
  ContainerProps: {
    alignItems: 'baseline',
    xs: '8'
  }
})
Container.bind('styles:ams.activities.activityCard.button', {
  root: card.cardButton
})

// Price
Container.bind('styles:ams.activities.price.amount', {
  root: card.amount
})
Container.bind('styles:ams.activities.price.prefix', {
  root: card.prefix
})

Container.bind('styles:ams.activities.price.suffix', {
  root: card.suffix
})

Container.bind('styles:ams.activities.price.label', {
  root: card.label
})
