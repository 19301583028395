import {CartBreakdownBase} from './cart-breakdown'
import {withDependencies} from '@iseatz/framework'

const dependencies = {
  ContainerComponent: 'node:ams.activities.checkout.cartBreakdown.container',
  DividerComponent: 'node:ams.activities.checkout.cartBreakdown.divider',
  DividerContainerComponent: 'node:ams.activities.checkout.cartBreakdown.dividerContainer',
  HeaderComponent: 'node:ams.activities.checkout.cartBreakdown.header',
  FooterTextComponent: 'node:ams.activities.checkout.cartBreakdown.footerText',
  FooterContainerComponent: 'node:ams.activities.checkout.cartBreakdown.footerContainer',
  PriceComponent: 'node:ams.activities.checkout.cartBreakdown.footerPrice',
  ProductComponent: 'node:ams.activities.checkout.cartBreakdown.product',
  PriceBreakdownComponent: 'node:ams.activities.checkout.cartBreakdown.priceBreakdown',
  PaymentIconComponent: 'node:ams.activities.checkout.cartBreakdown.paymentIcon',
  PointsComponent: 'node:ams.activities.checkout.cartBreakdown.points'
}

export const CheckoutCartBreakdown = withDependencies(dependencies)(CartBreakdownBase)
