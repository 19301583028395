import {
  backgroundGrey,
  wyzardSuitBlue} from '../colors'
import {bold, offerTextSize} from '../font'
import {
  lightPadding,
  lvpColumns,
  svpMargins} from '../spacing'
import {svpTheme} from '../theme'

export const reservationBillingInfo = {
  container: {
    backgroundColor: backgroundGrey,
    overflow: 'hidden'
  },
  title: {
    root: {
      margin: [[svpMargins, lvpColumns]],
      color: wyzardSuitBlue,
      fontSize: 20,
      [svpTheme.breakpoints.up('lg')]: {
        margin: [[18, lightPadding, 14, lightPadding]]
      }
    }
  },
  creditCardWrapper: {
    item: {
      [svpTheme.breakpoints.up('lg')]: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)'
      }
    }
  },
  subsection: {
    container: {
      container: {
        padding: [[svpMargins, lvpColumns]],
        [svpTheme.breakpoints.up('lg')]: {
          padding: [[34, 20]]
        }
      }
    },
    listContainer: {
      container: {
        marginBottom: 10
      }
    },
    label: {
      root: {
        fontWeight: bold,
        marginRight: 6,
        fontSize: offerTextSize
      }
    },
    text: {
      root: {
        fontSize: offerTextSize,
        width: 'auto',
        wordBreak: 'normal',
        wordWrap: 'normal',
        [svpTheme.breakpoints.up('lg')]: {
          width: '100%',
          wordBreak: 'break-word',
          wordWrap: 'break-word'
        }
      }
    }
  }
}
