import {
  buttons,
  forms,
  inputs
} from '../../base-styles'
import {
  globalFont,
  svgIconLargeSize
} from '../../font'
import {
  linkLightBlue,
  ruleGrey,
  white,
  wyzardSuitBlue
} from '../../colors'
import {
  minimalPadding,
  mvpGutters,
  svpGutters
} from '../../spacing'
import {
  svpTheme
} from '../../theme'

export const productFilter = {
  container: {
    container: {
      [svpTheme.breakpoints.down('md')]: {
        padding: 0
      },
      [svpTheme.breakpoints.up('lg')]: {
        padding: svpGutters
      }
    }
  },
  incrementButtonComponent: {
    ...buttons.buttonNoHover
  },
  dropdownButtonComponent: {
    button: {
      root: {
        ...svpTheme.overrides.MuiFormControl.root,
        ...inputs.buttonPadding,
        textAlign: 'left',
        '& > span': {
          justifyContent: 'space-between'
        },
        '&[aria-owns="dropdown-popover"]': {
          backgroundColor: wyzardSuitBlue
        },
        '&[aria-owns="dropdown-popover"] > span > p': {
          color: white
        },
        '&[aria-owns="dropdown-popover"] > span > svg': {
          color: white
        }
      }
    },
    content: {
      root: {
        ...svpTheme.typography.button,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap'
      }
    },
    icon: {
      root: {
        ...inputs.fadedIcon
      }
    }
  },
  filterContainer: {
    item: {
      [svpTheme.breakpoints.down('sm')]: {
        marginTop: '1.25rem'
      },
      [svpTheme.breakpoints.up('md')]: {
        display: 'flex',
        marginLeft: mvpGutters
      }
    }
  },
  popoverButton: {
    root: {
      '& > span': {
        justifyContent: 'center'
      }
    }
  },
  popoverComponent: {
    styles: {
      position: 'relative'
    },

    /*
      Popover is programmatically setting the element position directly,
      with no override options whereas we're trying to let the containers determine its width
      based on its sibling, the dropdown button.
      Hence !important is being used to squash the automatically computed values for top and left.
    */
    css: {
      paper: {
        ...inputs.relativeSizePopover
      }
    }
  },
  popoverContainer: {
    item: {
      position: 'relative'
    }
  },
  popoverMenuList: {
    root: {
      padding: 0
    }
  },
  popoverMenuItem: {
    root: {
      ...svpTheme.typography.button,
      ...inputs.buttonPadding,
      textTransform: 'capitalize',
      border: `1px solid ${ruleGrey}`,
      cursor: 'auto',
      '&:hover': {
        backgroundColor: white
      },
      [svpTheme.breakpoints.down('sm')]: {
        padding: minimalPadding,
        '& > button': {
          minWidth: 0
        }
      }
    }
  },
  popoverMenuText: {
    root: {
      '& > span': {
        ...svpTheme.typography.button,
        textTransform: 'capitalize'
      }
    }
  },
  popoverIcon: {
    root: {
      color: linkLightBlue,
      fontSize: svgIconLargeSize,
      fontFamily: globalFont
    }
  },
  searchForm: {
    container: {
      ...forms.cardForm,
      margin: 0,
      marginTop: 0,
      display: 'block',
      [svpTheme.breakpoints.down('sm')]: {
        display: 'flex'
      }
    }
  },
  updateComponent: {
    root: {
      ...buttons.actionButton,
      ...buttons.actionHover
    }
  }
}
