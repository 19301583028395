import React from 'react'
import {has} from '@iseatz/framework'
import withWidth from '@material-ui/core/withWidth'

const applyIfWidthIsPresent = (width, widthConditions, props) => {
  if (has(width, widthConditions)) {
    return widthConditions[width](props)
  }
  return props
}

/**
 * Takes an Object where the key of the object is a width metric for example sm
 * xs etc, and the value is a function which takes current props and returns new props
 * if width is specified in the object it will run the function returning the resulting props
 *
 * @param {Object} widthCondition object where keys are `xs,sm,lg,etc` and values are functions
 * @returns {Component} a wrapped component
 */
export const WhenWidthProps = widthCondition => Component =>
  withWidth()(({width, ...originalProps}) =>
    <Component {...applyIfWidthIsPresent(width, widthCondition, originalProps)} />
  )
