import {OrderNowAlt, OrderNowButton} from '../food-delivery-order-now-button/index'
import React, {PureComponent} from 'react'
import {foodDeliveryJoinToday, rewardsMemberIdMatcher} from '../../config'
import Divider from '@material-ui/core/Divider'
import {ReservationErrorMessage as ErrorContainer} from '@iseatz/ams-activities'
import FoodDeliveryOrderNowForm from '../food-delivery-order-now-form/food-delivery-order-now-form'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import {compose} from 'redux'
import dictionary from '../../dictionary/en'
import {doordashDelivery} from '../../styles/component-styles/doordash-delivery'
import {getKey} from '../../libs/dictionary'
import {withStyles} from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

class DoordashDelivery extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {referenceId: '', formInvalid: false}
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  static propTypes = {
    classes: PropTypes.shape({
      bottomText: PropTypes.string,
      button: PropTypes.string,
      container: PropTypes.string,
      divider: PropTypes.string,
      errorMessageContainer: PropTypes.string,
      foodDeliveryLink: PropTypes.string,
      joinTodayContainer: PropTypes.string,
      orderNowButton: PropTypes.string,
      orderNow: PropTypes.string,
      orderAndEarn: PropTypes.string,
      pointsMessage: PropTypes.string,
      spacedContainer: PropTypes.string,
      subtitle: PropTypes.string,
      textField: PropTypes.string,
      title: PropTypes.string
    }),
    width: PropTypes.string
  }

  handleChange (event) {
    this.setState({referenceId: event.target.value})
  }

  handleSubmit (event) {
    if (this.state.referenceId === '' || rewardsMemberIdMatcher.test(this.state.referenceId)) {
      return
    }
    this.setState({formInvalid: true})
    event.preventDefault()
  }

  render () {
    const {classes, width} = this.props
    const lvp = (width === 'lg' || width === 'xl')
    let itemAlignment
    if (lvp) {
      itemAlignment = 'center'
    } else {
      itemAlignment = 'left'
    }
    let maybeError = null
    if (this.state.formInvalid) {
      maybeError = (
        <Grid container alignContent="center" justify="center" className={classes.errorMessageContainer}>
          <ErrorContainer
            text="Please review your information and update the fields above."
          />
        </Grid>
      )
    }
    return (
      <FoodDeliveryOrderNowForm onSubmit={this.handleSubmit}>
        <Grid
          container
          className={classes.container}
          direction="column"
          alignItems={itemAlignment}
        >
          <Typography className={classes.title}>
            {getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.title')}
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.orderAndEarn}>
              <Typography className={classes.subtitle}>
                {getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.subtitle')}
              </Typography>
              <TextField
                error={this.state.formInvalid}
                variant="outlined"
                fullWidth
                label="Wyndham Rewards Member #"
                name="reference_id"
                onChange={this.handleChange}
                value={this.state.referenceId}
                className={classes.textField}
              />
              {maybeError}
              <OrderNowButton submit />
            </Grid>
            <Hidden lgUp>
              <Grid item xs={12}>
                <Divider className={classes.divider}/>
              </Grid>
            </Hidden>
            <Grid item xs={12} lg={6} className={classes.orderNow}>
              <Typography className={classes.subtitle}>
                {getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.altButtonSubtitle')}
              </Typography>
              <OrderNowAlt />
            </Grid>
          </Grid>
          <Grid container alignContent="center" justify="center" className={classes.joinTodayContainer}>
            <Typography className={classes.bottomText}>
              {getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.bottomText')}
            </Typography>
            <a
              href={foodDeliveryJoinToday}
              className={classes.foodDeliveryLink}>
              {getKey(dictionary, 'sections.activity.components.foodDeliverySearchHeroHome.linkCaption')}
            </a>
          </Grid>
        </Grid>
      </FoodDeliveryOrderNowForm>
    )
  }
}

const enhance = compose(withWidth(), withStyles(doordashDelivery))
export default enhance(DoordashDelivery)
