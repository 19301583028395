import {ActivitiesHero, withLocation} from '@iseatz/ams-activities'
import React, {Fragment, PureComponent} from 'react'
import {AutocompleteBaseProps} from './autocomplete-base'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import PropTypes from 'prop-types'
import {SearchBarBase} from './home-searchbar'
import SearchSubTitle from '../home-subtitle/home-search-subtitle'
import {SvpSearchDialog} from '../svp'
import Typography from '@material-ui/core/Typography'
import {compose} from 'redux'
import {connect} from 'react-redux'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {heroStyling} from '../../styles'
import homeHeroImage from '../../img/home-page-hero.png'
import {selectors} from '@iseatz/ams-profile'
import {withBreadcrumbs} from '../header/withBreadcrumbs'
import {withStyles} from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

const WyndhamRewardsJoinUrl = (
  'https://www.wyndhamhotels.com/wyndham-rewards/join?CID=NA:WR:20190403:ISEATZ:JOINWRLINK:WR:NA:NA:EN-US'
)
export class SearchHeroHome extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    updateBreadcrumbs: PropTypes.func.isRequired,
    width: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      showSearch: false
    }
    this.toggleSearch = this.toggleSearch.bind(this)
  }

  componentDidMount () {
    this.props.updateBreadcrumbs()
  }

  toggleSearch () {
    this.setState({
      showSearch: !this.state.showSearch
    })
  }

  render () {
    const {
      classes,
      children,
      width,
      isLoggedIn
    } = this.props

    const {
      showSearch
    } = this.state

    const height = (width === 'xs' || width === 'sm') ? '12rem' : '28rem'

    const JoinNowWidget = isLoggedIn
      ? null
      : <Fragment>
        <Grid>
          <Divider className={classes.divider}/>
        </Grid>
        <Grid container justify="center">
          <Typography className={classes.heroRewardText}>
            {getKey(dictionary, 'sections.activity.components.activitiesSearchHeroHome.bottomText')}
            <a href={WyndhamRewardsJoinUrl} className={classes.link}>
              {getKey(dictionary, 'sections.activity.components.activitiesSearchHeroHome.linkCaption')}
            </a>
          </Typography>
        </Grid>
      </Fragment>

    return (
      <Fragment>
        <ActivitiesHero
          height={height}
          image={homeHeroImage}
          className={classes.activitiesHero}>
          <Grid container justify="flex-start" alignItems="center" className={classes.heroSearchContainer}>
            <Hidden smDown>
              <Grid item md={6} lg={4} className={classes.heroSearchItem}>
                <Grid
                  container
                  className={classes.searchContainer}
                  direction="column"
                  justify="center">
                  <Grid item>
                    <Typography className={classes.homeHeroSearchTitle}>
                      {getKey(dictionary, 'sections.activity.components.activitiesSearchHeroHome.title')}
                    </Typography>
                  </Grid>
                  <Grid>
                    <SearchBarBase classes={classes}
                      autocompleteProps={{
                        ...AutocompleteBaseProps
                      }} />
                    {JoinNowWidget}
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
              <SearchSubTitle
                lineOne={getKey(dictionary,
                  'sections.activity.components.activitiesSearchHeroHome.titleSubTitle.title'
                )}
                lineTwo={getKey(dictionary,
                  'sections.activity.components.activitiesSearchHeroHome.titleSubTitle.subtitle'
                )}
              />
            </Grid>
          </Grid>
          {children}
        </ActivitiesHero>
        <Hidden mdUp>
          <Typography className={classes.homeHeroSearchTitle}>
            {getKey(dictionary, 'sections.activity.components.activitiesSearchHeroHome.title')}
          </Typography>
          <SearchBarBase
            autocompleteProps={{
              classes: {
                container: classes.searchBarBase
              }
            }}
            showButton={false}
            onClick={this.toggleSearch}
            classes={classes}
            disabled />
        </Hidden>
        <SvpSearchDialog
          onClose={this.toggleSearch}
          open={showSearch}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state)
})

const enhance = compose(
  withWidth(),
  withStyles(heroStyling),
  withLocation,
  connect(mapStateToProps),
  withBreadcrumbs
)

export default enhance(SearchHeroHome)
