import {
  linkBlue,
  wyzardSuitBlue
} from '../../colors'
import {
  lvpMargins,
  mvpMargins,
  noPadding,
  svpPadding
} from '../../spacing'
import {headerNavLineHeight} from '../../font'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const checkoutBookingInfo = {
  sidebar: {
    container: {
      position: 'relative'
    }
  },
  sidebarContainer: {
    container: {
      paddingLeft: pxToRem(40),
      position: 'sticky',
      top: 0,
      marginTop: 62,
      display: 'block',
      [svpTheme.breakpoints.only('md')]: {
        paddingLeft: pxToRem(svpPadding)
      },
      [svpTheme.breakpoints.down('sm')]: {
        marginTop: pxToRem(36),
        paddingLeft: pxToRem(8),
        paddingRight: pxToRem(8)
      }
    }
  },
  productHead: {
    containerCheckout: {
      container: {
        padding: [[15, 24]]
      }
    },
    icon: {
      root: {
        color: wyzardSuitBlue,
        fontSize: headerNavLineHeight
      }
    },
    leftTitleCheckout: {
      root: {
        color: wyzardSuitBlue,
        fontSize: headerNavLineHeight
      }
    },
    rightContainer: {
      item: {
        marginLeft: 'auto',
        [svpTheme.breakpoints.up('md')]: {
          display: 'none'
        }
      }
    }
  },
  productBreakdown: {
    container: {
      container: {
        padding: [[mvpMargins, lvpMargins, 12, lvpMargins]]
      }
    },
    list: {
      container: {
        padding: [[10, 0]]
      }
    },
    listItem: {
      container: {
        padding: [[5, 0]]
      }
    }
  },
  priceBreakdown: {
    container: {
      container: {
        padding: [[mvpMargins, lvpMargins, 18, lvpMargins]]
      }
    }
  },
  tourBreakdown: {
    expansionLabel: {
      root: {
        color: linkBlue,
        marginRight: 15
      }
    },
    expansionSummary: {
      root: {
        padding: noPadding,
        color: linkBlue
      }
    }
  }
}
