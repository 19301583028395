import {
  lightPadding,
  lvpMargins,
  minimalPadding,
  mvpMargins,
  mvpPadding,
  svpPadding
} from '../../spacing'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const featuresContainer = {
  container: {
    borderTop: `solid ${pxToRem(1)} ${svpTheme.palette.secondary.light}`,
    flexDirection: 'column',
    marginBottom: mvpMargins,
    marginTop: mvpMargins,
    [svpTheme.breakpoints.up('md')]: {
      borderBottom: `solid ${pxToRem(1)} ${svpTheme.palette.secondary.light}`,
      flexDirection: 'row'
    },
    [svpTheme.breakpoints.up('lg')]: {
      marginBottom: lvpMargins,
      marginTop: lvpMargins
    }
  }
}
export const featureItem = {
  item: {
    margin: `${pxToRem(lightPadding)} ${pxToRem(lightPadding)} 0`,
    [svpTheme.breakpoints.up('md')]: {
      margin: `${pxToRem(lightPadding)} 0`
    }
  }
}
export const featureLabel = {
  root: {
    padding: [[0, svpPadding, 0, minimalPadding]],
    fontSize: svpTheme.typography.fontSize,
    [svpTheme.breakpoints.up('md')]: {
      fontSize: svpTheme.typography.body1.fontSize,
      padding: [[0, svpPadding, 0, minimalPadding]]
    },
    [svpTheme.breakpoints.up('lg')]: {
      fontSize: svpTheme.typography.h6.fontSize,
      padding: [[0, mvpPadding, 0, minimalPadding]]
    }
  }

}
