import {bodyTextSize, medium, subheaderTextSize} from '../../font'
import {
  lvpColumns,
  lvpGutters,
  minimalPadding,
  mvpGutters,
  noPadding,
  svpGutters
} from '../../spacing'
import {ruleGrey, wyzardSuitBlue} from '../../colors'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography

export const earnBar = {
  container: {
    container: {
      paddingBottom: lvpColumns,
      border: `1px solid ${ruleGrey}`,
      borderTop: 'none',
      marginTop: lvpGutters
    }
  },
  text: {
    root: {
      fontWeight: medium,
      fontSize: subheaderTextSize,
      color: wyzardSuitBlue,
      margin: [[svpGutters, mvpGutters * 2, noPadding, lvpColumns]],
      [svpTheme.breakpoints.down('md')]: {
        fontSize: bodyTextSize,
        margin: [[svpGutters, mvpGutters, noPadding, lvpColumns]]
      }
    }
  },
  iconContainer: {
    container: {
      marginTop: mvpGutters,
      [svpTheme.breakpoints.down('md')]: {
        marginTop: pxToRem(18),
        marginLeft: minimalPadding
      }
    }
  },
  loyaltyLine: {
    container: {
      height: pxToRem(6),
      [svpTheme.breakpoints.down('md')]: {
        height: pxToRem(4)
      }
    }
  }
}
