import {
  lvpColumns,
  lvpPageGutters,
  mvpSvpPageGutters,
  svpColumns
} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const logoWrapper = ({typography, palette, breakpoints}) => ({
  root: {
    padding: [[lvpColumns, lvpPageGutters, 0]],
    [breakpoints.only('md')]: {
      padding: [[lvpColumns, mvpSvpPageGutters, 0]]
    },
    [breakpoints.down('sm')]: {
      padding: [[svpColumns, mvpSvpPageGutters, 0]]
    }
  },
  image: {
    [breakpoints.down('sm')]: {
      width: 115,
      height: 13
    }
  },
  poweredBy: {
    ...typography.subtitle1,
    color: 'white',
    [breakpoints.down('sm')]: {
      fontSize: pxToRem(7)
    }
  }
})
