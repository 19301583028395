import {heavyPadding, lvpMargins, mediumPadding, mvpPadding} from '../spacing'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const rewardsContainerFoodDelivery = () => ({
  root: {
    marginTop: mvpPadding
  }
})

const rewardContainer = ({typography, palette, breakpoints}) => ({
  root: {
    backgroundColor: palette.background.grey,
    padding: [['2.5rem', mediumPadding, 0, '6rem']],
    marginTop: heavyPadding,
    [breakpoints.down('sm')]: {
      textAlign: 'center',
      padding: '2.5rem .5rem'
    }
  },
  mainTitle: {
    color: palette.primary.dark,
    fontSize: typography.title.fontSize,
    whiteSpace: 'nowrap'
  },
  subTitle: {
    color: palette.secondary.dark,
    marginBottom: '1.75rem'
  },
  link: {
    marginLeft: '.5rem'
  }
})
const rewardDescription = {
  root: {
    paddingBottom: '2.5rem'
  }
}
const rewardDivider = {
  root: {
    width: '4.5rem',
    margin: 'auto'
  }
}
const rewardCard = ({typography, palette, breakpoints}) => ({
  root: {
    backgroundColor: palette.background.grey
  },
  cardContent: {
    padding: 0,
    [breakpoints.up('md')]: {
      marginRight: lvpMargins
    }
  },
  title: {
    color: palette.primary.dark,
    fontFamily: typography.rewardTitle.fontFamily,
    fontSize: typography.rewardTitle.fontSize,
    fontStyle: 'italic',
    margin: '.50rem 0'
  },
  titleSubScript: {
    color: palette.primary.darkrdSuitBlue,
    fontFamily: typography.rewardSubscript.fontFamily,
    fontSize: typography.rewardSubscript.fontSize,
    fontStyle: 'normal',
    padding: pxToRem(3)
  }
})

export {rewardContainer, rewardCard, rewardDescription, rewardDivider}
