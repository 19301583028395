import * as colors from '../../colors'
import {lvpPageGutters, mvpPadding, noPadding, svpPadding} from '../../spacing'
import {labels} from '../../base-styles/labels'
import {svpTheme} from '../../theme'

const {pxToRem} = svpTheme.typography
const separator = `1px solid ${colors.ruleGrey}`

export const container = {
  item: {
    marginTop: pxToRem(lvpPageGutters)
  }
}
export const test = {
  item: {
    [svpTheme.breakpoints.down('sm')]: {
      padding: [[noPadding, svpPadding]]
    }
  }
}

export const title = {
  root: {
    ...labels.leadingLabel,
    paddingBottom: mvpPadding
  }
}

export const panelHeader = {
  root: {
    color: colors.linkBlue,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(70)
  }
}

export const expansionPanel = {
  root: {
    borderBottom: separator,
    borderTop: separator,
    '&:not(:first-child)': {
      borderTop: 0
    },
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: 'auto'
  }
}

export const expansionPanelSummary = {
  root: {
    justifyContent: 'flex-start',
    width: '100%',
    borderBottom: separator,
    paddingLeft: pxToRem(11),
    marginBottom: -1,
    height: pxToRem(70),
    '&$expanded': {
      minHeight: pxToRem(70)
    }
  },
  expanded: {}
}

export const expansionPanelDetails = {
  root: {
    display: 'block',
    padding: `${pxToRem(29)} ${pxToRem(44)} ${pxToRem(29)} ${pxToRem(11)}`
  }
}

export const list = {
  root: {
    padding: 0
  }
}

export const listItem = {
  root: {
    padding: 0
  }
}

export const subHeader = {
  root: {
    color: colors.black,
    fontWeight: 'bolder'
  }
}

export const subSection = {
  root: {
    color: colors.black,
    '&:last-child': {
      marginBottom: 0
    },
    marginBottom: pxToRem(29)
  }
}
