import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import dictionary from '../dictionary/en'
import {getKey} from '../libs/dictionary'
import {noResultsStyles} from '../styles/component-styles/grid'
import {withStyles} from '@material-ui/core/styles'

class NoResults extends PureComponent {
    static propTypes = {
      classes: PropTypes.shape({
        header: PropTypes.string
      })
    }

    render () {
      const {
        classes
      } = this.props
      return (
        <Grid container direction="column">
          <Grid className={classes.header}
            item component={Typography} variant="h4" align="center">
            {getKey(dictionary, 'sections.activity.paths.resultList.noResultsHeader')}
          </Grid>
          <Grid item component={Typography} align="center">
            {getKey(dictionary, 'sections.activity.paths.resultList.noResultsSubHeader1')}
          </Grid>
          <Grid item component={Typography} align="center">
            {getKey(dictionary, 'sections.activity.paths.resultList.noResultsSubHeader2')}
          </Grid>
        </Grid>
      )
    }
}

export default withStyles(noResultsStyles)(NoResults)
