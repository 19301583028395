import {bold, smallTextLetterSpacing} from '../font'
import {mediumPadding, mvpMargins, svpMargins} from '../spacing'
import crossSellImage from '../../img/cross-sell.png'
import svpCrossSellImage from '../../img/svp-cross-sell.png'

export const bannerContainer = ({breakpoints, palette, typography}) => ({
  root: {
    marginTop: mediumPadding,
    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%), url(${crossSellImage})`,
    backgroundPosition: 'center',
    width: '100%',
    maxWidth: '73rem',
    height: typography.pxToRem(432),
    paddingTop: typography.pxToRem(48),
    [breakpoints.down('sm')]: {
      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 100%), url(${svpCrossSellImage})`,
      backgroundSize: `100% ${typography.pxToRem(407)}`,
      height: typography.pxToRem(407),
      paddingTop: '2rem'
    }
  },
  titleContainerItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  topTitle: {
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    fontWeight: bold,
    letterSpacing: 2,
    margin: [[0, 0, typography.pxToRem(30), 0]],
    fontSize: typography.body2.fontSize,
    [breakpoints.down('sm')]: {
      fontSize: typography.body.fontSize,
      margin: [[0, 0, mvpMargins, 0]]
    }
  },
  mainSubTitle: {
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    fontWeight: bold,
    fontSize: typography.headline.fontSize,
    [breakpoints.down('sm')]: {
      fontSize: typography.h4.fontSize
    }
  },
  separationLine: {
    boxSizing: 'border-box',
    margin: [[typography.pxToRem(32), 'auto']],
    border: `0.1rem solid ${palette.common.white}`,
    height: 1,
    width: typography.pxToRem(110),
    [breakpoints.down('sm')]: {
      margin: [[mvpMargins, 'auto']],
      width: typography.pxToRem(75)
    }
  },
  descriptionTitle: {
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    fontWeight: bold,
    letterSpacing: smallTextLetterSpacing,
    marginBottom: mvpMargins,
    fontSize: typography.body1.fontSize,
    [breakpoints.down('sm')]: {
      marginBottom: svpMargins
    }
  },
  descriptionText: {
    color: palette.common.white,
    [breakpoints.down('sm')]: {
      fontSize: typography.caption.fontSize,
      lineHeight: 2
    }
  },
  button: {
    color: palette.common.white,
    margin: [[typography.pxToRem(48), 0, typography.pxToRem(43)]],
    width: typography.pxToRem(250),
    height: typography.pxToRem(50),
    border: `0.125rem solid ${palette.common.white}`,
    [breakpoints.down('md')]: {
      margin: [[typography.pxToRem(32), 0, typography.pxToRem(43)]]
    },
    [breakpoints.down('sm')]: {
      width: typography.pxToRem(260),
      height: typography.pxToRem(48)
    }
  }
})
