import PropTypes from 'prop-types'
import React from 'react'
import {SearchBar} from '@iseatz/ams-activities'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'

// eslint-disable-next-line
export const SvpSearchBarBase = ({ autocompleteProps, classes, disabled = false, ...rest }) => (<SearchBar
  className={classes.searchbar}
  label={getKey(dictionary, 'sections.activity.components.searchbar.label')}
  direction="column"
  justify="space-between"
  alignItems="stretch"
  selectDates={false}
  showButton={false}
  spacing={16}
  buttonLabel=
    {
      getKey(dictionary,
        'sections.activity.components.activitiesSearchHeroHome.searchModal.svpButtonLabel')
    }
  AutocompleteProps={{
    ...autocompleteProps,
    InputProps: {
      disabled,
      autoFocus: true
    },
    PopoverProps: {
      PaperProps: {
        square: true,
        className: classes.popover
      }
    },
    TextProps: {
      autoFocus: true,
      fullWidth: true
    }
  }}
  elevation={0}
  {...rest}
/>)

SvpSearchBarBase.propTypes = {
  classes: PropTypes.shape({
    popover: PropTypes.string,
    searchbar: PropTypes.string
  })
}
