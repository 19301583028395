import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {componentType} from '@iseatz/framework'
import {destinationsTitle as styles} from '../../styles/component-styles/featured-destinations'
import {withStyles} from '@material-ui/core/styles'

export class DestinationTitle extends PureComponent {
    static propTypes = {
      classes: PropTypes.object.isRequired,
      MainIconComponent: componentType,
      title: PropTypes.string.isRequired
    }
    render () {
      const {classes, MainIconComponent: DestinationIcon, title} = this.props
      return (
        <Grid container direction="column" justify="space-around" alignItems="center" className={classes.container}>
          <Grid item>
            <DestinationIcon/>
          </Grid>
          <Grid>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
        </Grid>
      )
    }
}
export default withStyles(styles)(DestinationTitle)
