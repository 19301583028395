import {backgroundLightGrey, bodyGrey, linkBlue, linkLightBlue, white} from '../colors'
import {buttonTextSize, regular} from '../font'
import {minimalPadding, mvpPadding, svpPadding} from '../spacing'
import {buttons} from '../base-styles/buttons'
import {labels} from '../base-styles/labels'
import {svpTheme} from '../theme'

const {pxToRem} = svpTheme.typography

export const totalCountLabel = {
  ...labels.leadingLabel,
  fontSize: buttonTextSize,
  paddingLeft: mvpPadding
}
export const searchFilterLabel = {
  ...labels.leadingLabel,
  textTransform: 'uppercase',
  paddingTop: minimalPadding
}
export const searchFilterContainer = {
  paddingLeft: svpPadding,
  paddingRight: svpPadding,
  [svpTheme.breakpoints.up('md')]: {
    paddingLeft: mvpPadding
  }
}
export const iconStyles = {
  color: bodyGrey,
  padding: [[minimalPadding, minimalPadding, minimalPadding, 0]]
}
export const listItemStyles = {
  padding: 5
}

export const subheaderStyles = {
  ...totalCountLabel,
  paddingTop: svpPadding,
  paddingLeft: 0,
  fontWeight: regular
}

export const searchButton = {
  ...buttons.clear
}

export const svpClearButton = {
  color: linkBlue,
  backgroundColor: white,
  border: `2px solid ${linkLightBlue}`,
  fontSize: buttonTextSize,
  height: pxToRem(38),
  margin: [[pxToRem(15), pxToRem(35), pxToRem(5), 0]],
  padding: 0,
  textTransform: 'uppercase',
  width: pxToRem(104)
}

export const svpUpdateButton = {
  backgroundColor: linkLightBlue,
  color: linkBlue,
  height: pxToRem(38),
  margin: [[pxToRem(15), pxToRem(15), pxToRem(5), 0]],
  padding: 0,
  width: pxToRem(104)
}

export const filterButtonStyles = {
  width: '85%',
  margin: [['.75rem', 0, '1rem', 0]],
  color: linkBlue,
  padding: [[10, 15]],
  '&, &:hover': {
    backgroundColor: linkLightBlue
  }
}

export const buttonContainer = {
  position: 'sticky',
  bottom: 0,
  backgroundColor: 'white',
  paddingTop: '1rem'
}

export const searchFilterLayout = {
  container: {
    backgroundColor: backgroundLightGrey,
    padding: '2rem',
    [svpTheme.breakpoints.down('md')]: {
      padding: '1.5rem 1rem 2rem 1rem'
    }
  }
}

export const searchFilter = {
  root: {
    margin: '1rem 2rem 0 0',
    [svpTheme.breakpoints.down('md')]: {
      margin: '1rem .5rem 0 0'
    }
  }
}

export const filterLabel = {
  fontSize: pxToRem(14),
  letterSpacing: 0.67
}
