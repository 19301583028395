import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {api} from '../../config'
// import {componentType} from '@iseatz/react-utils'
import {resolve} from '@iseatz/framework'

const {baseUrl} = api
const resolvedRoute = resolve('route:api.foodDelivery.goToDoordash')
class FoodDeliveryOrderNowForm extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
    onSubmit: PropTypes.func
  }

  render () {
    const {children, onSubmit} = this.props
    return (
      <form
        action={`${baseUrl}${resolvedRoute}`}
        method="POST"
        onSubmit={onSubmit}>
        {children}
      </form>
    )
  }
}

export default FoodDeliveryOrderNowForm
