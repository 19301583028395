import {CorePoints, CorePrice} from '@iseatz/ams-core'
import {Injectable, componentType} from '@iseatz/framework'
import React, {Fragment, PureComponent} from 'react'
import {
  ReservationPriceBreakdown,
  ReservationProductBreakdown,
  ReservationProductHeader,
  formatNumberToLocale
} from '@iseatz/ams-activities'
import {itemType, payWithPointsConverter, paymentTypeKeys} from '../utils'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

@Injectable()
class CartBreakdownBase extends PureComponent {
  static propTypes = {
    ContainerComponent: componentType,
    ContainerProps: PropTypes.shape({}),
    DividerComponent: componentType,
    DividerProps: PropTypes.shape({}),
    DividerContainerComponent: componentType,
    DividerContainerProps: PropTypes.shape({}),
    HeaderComponent: componentType,
    HeaderProps: PropTypes.shape({}),
    FooterContainerComponent: componentType,
    FooterContainerProps: PropTypes.shape({}),
    FooterTextProps: PropTypes.shape({}),
    FooterTextComponent: componentType,
    ProductComponent: componentType,
    ProductProps: PropTypes.shape({}),
    PriceBreakdownComponent: componentType,
    PriceBreakdownProps: PropTypes.shape({}),
    PriceComponent: componentType,
    PriceProps: PropTypes.shape({}),
    footerText: PropTypes.string,
    item: itemType,
    price: PropTypes.shape({
      cents: PropTypes.number,
      currency: PropTypes.string,
      symbol: PropTypes.string
    }),
    paymentMethod: PropTypes.string,
    PaymentIconComponent: componentType,
    PointsComponent: componentType,
    PaymentIconComponentProps: PropTypes.shape({}),
    pointsSuffix: PropTypes.string
  }

  static defaultProps = {
    ContainerComponent: Grid,
    ContainerProps: {},
    DividerComponent: Divider,
    DividerProps: {},
    DividerContainerComponent: Grid,
    DividerContainerProps: {},
    HeaderComponent: ReservationProductHeader,
    HeaderProps: {},
    FooterContainerComponent: Grid,
    FooterContainerProps: {},
    FooterTextProps: {},
    FooterTextComponent: Typography,
    ProductComponent: ReservationProductBreakdown,
    ProductProps: {},
    PriceBreakdownComponent: ReservationPriceBreakdown,
    PriceBreakdownProps: {},
    PriceComponent: CorePrice,
    PriceProps: {},
    footerText: 'Balance Paid Today',
    headerText: 'My Activity Summary',
    paymentMethod: paymentTypeKeys.creditCard,
    PaymentIconComponent: null,
    PointsComponent: CorePoints,
    PaymentIconComponentProps: {},
    pointsSuffix: 'PTS'
  }

  render () {
    const {
      ContainerComponent,
      ContainerProps,
      DividerComponent,
      DividerProps,
      DividerContainerComponent,
      DividerContainerProps,
      HeaderComponent,
      HeaderProps,
      footerText,
      FooterContainerComponent,
      FooterContainerProps,
      FooterTextProps,
      FooterTextComponent,
      ProductComponent,
      ProductProps,
      PriceBreakdownComponent,
      PriceBreakdownProps,
      PriceComponent,
      PriceProps,
      price,
      item,
      paymentMethod,
      PaymentIconComponent,
      PointsComponent,
      PaymentIconComponentProps,
      pointsSuffix
    } = this.props

    let paymentIcon = null
    if (PaymentIconComponent) {
      paymentIcon = <PaymentIconComponent {...PaymentIconComponentProps} />
    }

    const points = formatNumberToLocale(payWithPointsConverter(price.cents))
    let totalPaymentComponent = null
    if (paymentMethod === paymentTypeKeys.creditCard) {
      totalPaymentComponent = (
        <Fragment>
          <FooterTextComponent {...FooterTextProps}>
            {footerText}
          </FooterTextComponent>
          <PriceComponent price={price} {...PriceProps} />
        </Fragment>
      )
    } else if (paymentMethod === paymentTypeKeys.points) {
      totalPaymentComponent = (
        <Fragment>
          <Grid item xs={11}>
            <FooterTextComponent {...FooterTextProps}>
              {footerText}
            </FooterTextComponent>
            <PointsComponent formattedPointsAmount={points} suffix={pointsSuffix} {...PriceProps} />
          </Grid>
          <Grid item xs={1} >
            {paymentIcon}
          </Grid>
        </Fragment>
      )
    }

    return (
      <ContainerComponent container {...ContainerProps} className="ams-activties-cart-breakdown__container">
        <HeaderComponent {...HeaderProps} />
        <DividerContainerComponent item xs={12} {...DividerContainerProps}>
          <DividerComponent variant="fullWidth" {...DividerProps} />
        </DividerContainerComponent>
        <ProductComponent
          {...ProductProps} />
        <DividerContainerComponent item xs={12} {...DividerContainerProps}>
          <DividerComponent variant="fullWidth" {...DividerProps} />
        </DividerContainerComponent>
        <PriceBreakdownComponent item={item} {...PriceBreakdownProps} />
        <DividerContainerComponent item xs={12} {...DividerContainerProps}>
          <DividerComponent variant="fullWidth" {...DividerProps} />
        </DividerContainerComponent>
        <FooterContainerComponent container {...FooterContainerProps}>
          {totalPaymentComponent}
        </FooterContainerComponent>
      </ContainerComponent>
    )
  }
}

export {CartBreakdownBase}
