import {labels} from '../base-styles/labels'
import {ruleGrey} from '../colors'
import {svpMargins} from '../spacing'

export const dialogTitleStyles = {
  ...labels.leadingLabel,
  textTransform: 'uppercase'
}

export const dialogHeaderStyles = {
  position: 'relative',
  flex: '0 0 auto',
  alignItems: 'center',
  margin: 0,
  padding: [[svpMargins, svpMargins]],
  '&::after': {
    content: `" "`,
    borderBottom: `1px solid ${ruleGrey}`,
    position: 'absolute',
    top: '100%',
    left: svpMargins,
    right: svpMargins
  }
}
