/* eslint-disable max-lines */
import {
  bodyTextSize,
  breakdownPoints,
  breakdownPrice,
  collapseButton,
  collapseText,
  detailsLayout,
  detailsSection,
  dividerContainer,
  expansionSummary,
  highlights,
  labelContainer,
  listPrice,
  mask,
  mvpMargins,
  productDetails,
  productFilter,
  productImage,
  rewardsLabel,
  ticketList,
  ticketListItem,
  ticketListLabel,
  tourBreakdown,
  tourBreakdownExpansion,
  tourBreakdownExpansionLabel,
  tourBreakdownTitle,
  tourCard,
  tourCardButton,
  tourCardDescription,
  tourCardTitle,
  tourGridTitle
} from '../styles'

import {ReactComponent as CollapseBreakdown} from '../img/CollapseBreakdown.svg'
import {Container} from '@iseatz/framework'
import {DetailsCalendarWrapper} from '../layout/calendar-wrapper/index'
import {DropdownAdd} from '../img/dropdownAdd.js'
import {DropdownMinus} from '../img/dropdownMinus.js'
import {ReactComponent as ExpandBreakdown} from '../img/ExpandBreakdown.svg'
import Grid from '@material-ui/core/Grid'
import {ReactComponent as Highlights} from '../img/Highlights.svg'
import NoResultsComponent from '../layout/no-results'
import Paper from '@material-ui/core/Paper'
import {ParticipantsRange} from '../layout/participants-range'
import SizeAwareActivityProductFilter from '../layout/size-aware-activity-product-filter'
import Typography from '@material-ui/core/Typography'
import {WHGTourGradeBreakdown} from '../components'
import {WhenWidthProps} from '../layout/when-width-props/when-width-props'
import dictionary from '../dictionary/en'
import {getKey} from '../libs/dictionary'
import {priceWithLocale} from '../utils'
import {svpTheme} from '../styles/theme'

const {pxToRem} = svpTheme.typography
// Lang
Container.bind('lang:ams.activities.details.label', 'Details')

// Details Page
Container.bind('styles:ams.activities.details.layout', detailsLayout)

Container.bind('props:ams.activities.details.contentContainer', {
  spacing: 24
})
Container.bind('props:ams.activities.details.highlightsContainer', {
  container: true,
  item: true,
  lg: 3,
  md: 12
})

const swapItemTrue = props => ({...props, item: false})
const withWidthWrapper = WhenWidthProps({sm: swapItemTrue, xs: swapItemTrue})

Container.bind('component:ams.activities.details.highlightsContainer',
  withWidthWrapper(Grid))

Container.bind('styles:ams.activities.details.highlightsContainer', highlights.outerContainer)

Container.bind('component:ams.activities.details.tourGradeContainer',
  withWidthWrapper(Grid))

Container.bind('props:ams.activities.details.tourGradeContainer', {
  item: true,
  lg: 9,
  md: 12
})

Container.bind('styles:ams.activities.details.tourGradeContainer', {
  root: {
    [svpTheme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
})
// Product Summary Container
Container.bind('styles:ams.activities.productSummary.container', productDetails.summaryContainer)

Container.bind('props:ams.activities.productSummary', {
  elevation: 0
})
Container.bind('styles:ams.activities.productSummary.detailsFilterItem', productFilter.filterContainer)
Container.bind('styles:ams.activities.productSummary.filter', productFilter.searchForm)
Container.bind('props:ams.activities.productSummary.detailsFilterContainer', {
  direction: 'column',
  justify: 'space-between'
})

Container.bind('component:ams.activities.productSummary.filter', SizeAwareActivityProductFilter)
Container.bind('props:ams.activities.productSummary.filter', {
  DateContainerProps: {
    xs: 12
  },
  ContainerProps: {
    elevation: 0,
    spacing: mvpMargins
  },
  direction: 'column'
})

// Product Image
Container.bind('component:ams.activities.product-image', Paper)
Container.bind('props:ams.activities.productSummary.image', {
  showBadge: true
})
Container.bind('styles:ams.activities.product-image', productImage.image)
Container.bind('styles:ams.activities.bookmark', productImage.bookmark)
Container.bind('styles:ams.activities.bookmark.text', productImage.bookmarkText)
Container.bind('styles:ams.activities.productImage.logo', productImage.imageContainer)
Container.bind('props:ams.activities.details.product', {
  item: true,
  imageStyles: productImage.image,
  DetailsFilterContainerProps: {
    container: false,
    xs: 12
  }
})
Container.bind('styles:ams.activities.details.product', productImage.container)

// Product Details
Container.bind('props:ams.activities.productSummary.details', {
  PriceContainerProps: {
    md: 3,
    xs: 12
  },
  TitleContainerProps: {
    md: 9,
    xs: 12
  }
})
Container.bind('component:ams.activities.productSummary.participants', ParticipantsRange)
Container.bind('styles:ams.activities.productDetails.title', productDetails.title)
Container.bind('styles:ams.activities.productDetails.subtitle', productDetails.subtitle)
Container.bind('props:ams.activities.productDetails.price', {
  showCents: true,
  showCurrency: true,
  label: 'FROM'
})
Container.bind('styles:ams.activities.productDetails.price', productDetails.price)

// Price
Container.bind('styles:ams.activities.productDetails.price.priceLabel', productDetails.priceLabel)
Container.bind('styles:ams.activities.productDetails.price.amount', productDetails.priceAmount)
Container.bind('styles:ams.activities.productDetails.price.cents', productDetails.priceCents)
Container.bind('styles:ams.activities.productDetails.price.prefix', productDetails.pricePrefix)
Container.bind('styles:ams.activities.productDetails.price.suffix', productDetails.priceSuffix)
Container.bind('styles:ams.activities.productDetails.price.coreContainer', productDetails.priceContainer)

// Product Filter
Container.bind('styles:ams.activities.productFilter.container', productFilter.container)
Container.bind('props:ams.activities.productFilter.date', {
  numberOfMonths: 1,
  readOnly: true,
  WrapperComponent: DetailsCalendarWrapper
})
Container.bind('props:ams.activities.productDetails.dropdown.button', {
  fullWidth: true,
  variant: 'outlined'
})
Container.bind('styles:ams.activities.productDetails.dropdown.button', productFilter.dropdownButtonComponent.button)
Container.bind('styles:ams.activities.productDetails.dropdown.button.content',
  productFilter.dropdownButtonComponent.content)
Container.bind('styles:ams.activities.productDetails.dropdown.button.icon', productFilter.dropdownButtonComponent.icon)
Container.bind('styles:ams.activities.productFilter.button', productFilter.updateComponent)
Container.bind('props:ams.activities.productFilter.button', {
  fullWidth: true
})
Container.bind('props:ams.activities.productFilter.dropdown', {
  svpScrollDown: true,
  svpScrollDownPX: 240
})
Container.bind('styles:ams.activities.productFilter.dropdown.container', productFilter.popoverContainer)
Container.bind('props:ams.activities.productDetails.dropdown.popover', {
  disablePortal: true,
  style: {
    ...productFilter.popoverComponent.styles
  }
})
Container.bind('styles:ams.activities.productDetails.dropdown.popover', productFilter.popoverComponent.css)
Container.bind('styles:ams.activities.productDetails.dropdown.list', productFilter.popoverMenuList)
Container.bind('props:ams.activities.productDetails.dropdown.menu.item', {
  disableRipple: true
})
Container.bind('styles:ams.activities.productDetails.dropdown.menu.item', productFilter.popoverMenuItem)
Container.bind('styles:ams.activities.productDetails.dropdown.select.button', productFilter.popoverButton)
Container.bind('styles:ams.activities.productDetails.dropdown.text', productFilter.popoverMenuText)
Container.bind('styles:ams.activities.productDetails.dropdown.select.button', productFilter.incrementButtonComponent)
Container.bind('component:ams.activities.productDetails.dropdown.less.icon', DropdownMinus)
Container.bind('component:ams.activities.productDetails.dropdown.more.icon', DropdownAdd)
Container.bind('props:ams.activities.productDetails.dropdown.select.button', {
  disableRipple: true
})

// Highlights
Container.bind('props:ams.activities.highlights', {
  ContainerProps: highlights.container,
  titleCopy: getKey(dictionary, 'sections.activity.components.highlights.title'),
  troubleCopy: getKey(dictionary, 'sections.activity.components.highlights.trouble'),
  ExpansionProps: {
    defaultExpanded: true
  }
})
// TODO: Contact container removed temporarily under CO WHG-585 for the first launch
Container.bind('props:ams.activities.details.highlights', {
  showContact: false
})
Container.bind('styles:ams.activities.description.expansionSummaryComponent', highlights.titleSpace)
Container.bind('styles:ams.activities.description.expansionDetailsComponent', highlights.detailsSpace)

Container.bind('styles:ams.activities.highlights.contact', highlights.contactText)
Container.bind('props:ams.activities.highlights.contactContainer', {
  md: 6,
  lg: 12
})

Container.bind('props:ams.activities.highlights.descriptionContainer', {
  xs: 12,
  md: 6,
  lg: 12
})

Container.bind('styles:ams.activities.highlights.descriptionContainer',
  highlights.descriptionContainer)

Container.bind('styles:ams.activities.highlights.header', highlights.header)
Container.bind('props:ams.activities.highlights.header', {
  md: 12,
  lg: 12
})
Container.bind('component:ams.activities.highlights.headerIcon', Highlights)
Container.bind('props:ams.activities.highlights.headerIcon', {
  style: {
    display: 'inline-block',
    verticalAlign: 'sub',
    marginRight: pxToRem(10)
  }
})
Container.bind('styles:ams.activities.highlights.listItemIcon', highlights.icon)
Container.bind('styles:ams.activities.highlights.listItemText', highlights.itemText)
Container.bind('props:ams.activities.highlights.title', {variant: 'subtitle2'})
Container.bind('styles:ams.activities.highlights.title', highlights.highlightsTitle)
Container.bind('styles:ams.activities.highlights.trouble', highlights.contactTitle)

// Activity Options (Tourgrades)
/* Tour Grid */
Container.bind('component:ams.activities.tourGrid.noResults', NoResultsComponent)
Container.bind('props:ams.activities.tourGrid.grid', {
  CardProps: {
    BreakdownContainerProps: {
      md: 5,
      lg: 5
    },
    BreakdownProps: {
      title: getKey(dictionary, 'sections.activity.components.tourGrade.total')
    }
  },
  cols: 1,
  cellHeight: 'auto'
})
Container.bind('styles:ams.activities.tourGrid.title', tourGridTitle)

/* Tour Card */
Container.bind('styles:ams.activities.tourCard.card', tourCard)
Container.bind('styles:ams.activities.tourCard.title', tourCardTitle)
Container.bind('styles:ams.activities.tourCard.description', tourCardDescription)
Container.bind('props:ams.activities.tourCard.description', {
  container: true,
  direction: 'column',
  item: true,
  md: 7,
  lg: 7
})
Container.bind('props:ams.activities.tourCard.button', {
  variant: 'outlined'
})
Container.bind('styles:ams.activities.tourCard.button', tourCardButton)
Container.bind('props:ams.activities.tourCard.subtitle', {
  collapsedMask: getKey(dictionary, 'sections.activity.components.tourGrade.seeMore'),
  expandedMask: getKey(dictionary, 'sections.activity.components.tourGrade.seeLess'),
  lvpTruncate: 200,
  mvpTruncate: 165
})

Container.bind('component:ams.activities.tourCard.breakdown', WHGTourGradeBreakdown)

/* See more/See less collapse */
Container.bind('styles:ams.activities.details.tourCardCollapse.button', collapseButton)
Container.bind('component:ams.activities.details.tourCardCollapse.downIconComponent', ExpandBreakdown)
Container.bind('styles:ams.activities.details.tourCardCollapse.mask', mask)
Container.bind('component:ams.activities.details.tourCardCollapse.upIconComponent', CollapseBreakdown)
Container.bind('styles:ams.activities.details.tourCardCollapse.content', collapseText)

/* Tour Breakdown */
Container.bind('styles:ams.activities.tourBreakdown', tourBreakdown)
Container.bind('styles:ams.activities.tourBreakdown.expansion', tourBreakdownExpansion)
Container.bind('props:ams.activities.tourBreakdown.expansion', {
  defaultExpanded: true
})
Container.bind('styles:ams.activities.tourBreakdown.expansionLabel', tourBreakdownExpansionLabel)
Container.bind('styles:ams.activities.tourBreakdown.expansionSummary', expansionSummary)
Container.bind('component:ams.activities.tourBreakdown.icon', ExpandBreakdown)
Container.bind('styles:ams.activities.tourBreakdown.title', tourBreakdownTitle)
Container.bind('props:ams.activities.tourBreakdown.title', {variant: 'subtitle1'})
Container.bind('props:ams.activities.tourBreakdown.price', {
  priceConverter: priceWithLocale,
  showCurrency: false
})

Container.bind('props:ams.activities.tourBreakdown.points', {
  clientLabel: getKey(dictionary, 'sections.activity.components.tourGrade.points')
})

/* Breakdown Price */
/* Ticket Price */
Container.bind('props:ams.activities.details.tourBreakdownPrice.amount', {
  variant: 'subtitle2'
})
Container.bind('styles:ams.activities.details.tourBreakdownPrice.cents', breakdownPrice.cents)
Container.bind('styles:ams.activities.details.tourBreakdownPrice.prefix', breakdownPrice.prefix)

/* Tour Ticket List */
Container.bind('styles:ams.activities.tourTicketList', ticketList)
Container.bind('component:ams.activities.tourTicketList.listItem', Grid)
Container.bind('props:ams.activities.tourTicketList.listItem', {
  container: true,
  justify: 'space-between'
})
Container.bind('styles:ams.activities.tourTicketList.listItem', ticketListItem)
Container.bind('component:ams.activities.tourTicketList.label', Grid)
Container.bind('props:ams.activities.tourTicketList.label', {
  component: Typography,
  item: true,
  md: true,
  lg: true,
  style: {
    fontSize: bodyTextSize
  }
})

Container.bind('props:ams.activities.tourTicketList.totalPrice', {
  priceConverter: priceWithLocale,
  showCurrency: true,
  showSymbol: false
})

/* Ticket Price */
Container.bind('styles:ams.activities.details.ticketListPrice.amount', listPrice.amount)
Container.bind('styles:ams.activities.details.ticketListPrice.cents', listPrice.cents)
Container.bind('styles:ams.activities.details.ticketListPrice.suffix', listPrice.suffix)

/* Tour Points */
Container.bind('styles:ams.activities.details.tourPoints', breakdownPoints)
Container.bind('props:ams.activities.details.tourPoints.points', {
  variant: 'subtitle2'
})
Container.bind('props:ams.activities.details.tourPoints.labelContainer', {
  xs: 3
})
Container.bind('styles:ams.activities.details.tourPoints.labelContainer', labelContainer)
Container.bind('styles:ams.activities.details.tourPoints.label', rewardsLabel)
Container.bind('props:ams.activities.details.tourPoints.pointsContainer', {
  container: true,
  xs: 9,
  justify: 'flex-end'
})

/* Divider */
Container.bind('props:ams.activities.details.tourGradeDivider', {
  alignItems: 'center',
  justify: 'space-between',
  item: true
})
Container.bind('styles:ams.activities.details.tourGradeDivider', dividerContainer)

Container.bind('styles:ams.activities.details.tourGradeDivider.label', ticketListLabel)
Container.bind('props:ams.activities.details.tourGradeDivider.labelContainer', {
  container: true,
  justify: 'center'
})

Container.bind('styles:ams.activities.details.activitiesDetailsContainer', detailsSection.container)
Container.bind('styles:ams.activities.details.section', detailsSection.test)
Container.bind('props:ams.activities.details.section', {
  item: true
})
Container.bind('styles:ams.activities.details.section.title', detailsSection.title)
Container.bind('styles:ams.activities.details.section.panel', detailsSection.expansionPanel)
Container.bind('styles:ams.activities.details.section.panel.header', detailsSection.panelHeader)
Container.bind('styles:ams.activities.details.section.panel.summary', detailsSection.expansionPanelSummary)
Container.bind('styles:ams.activities.details.section.panel.details', detailsSection.expansionPanelDetails)
Container.bind('styles:ams.activities.details.section.additionalInfo.subHeader', detailsSection.subHeader)
Container.bind('styles:ams.activities.details.section.overview.subHeader', detailsSection.subHeader)
Container.bind('styles:ams.activities.details.section.schedule.subHeader', detailsSection.subHeader)
Container.bind('styles:ams.activities.details.section.overview.list', detailsSection.list)
Container.bind('styles:ams.activities.details.section.overview.listItem', detailsSection.listItem)
Container.bind('styles:ams.activities.details.section.additionalInfo.subSection',
  detailsSection.subSection)
Container.bind('styles:ams.activities.details.section.overview.subSection', detailsSection.subSection)
Container.bind('styles:ams.activities.details.section.schedule.subSection', detailsSection.subSection)
Container.bind('styles:ams.activities.details.section.cancellationPolicy.subSection', detailsSection.subSection)
