import React, {PureComponent} from 'react'
import {CoreLink} from '@iseatz/ams-core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {disclamerStyles as styles} from '../../styles/component-styles/payment-from-disclamer'
import {withStyles} from '@material-ui/core/styles'

class PaymentFormDisclamer extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      disclamerText: PropTypes.string
    })
  }
  render () {
    const {classes} = this.props
    return (
      <Typography className={classes.disclamerText}>
        By clicking "Book Now" you agree
           to the Viator <CoreLink
          target="_blank"
          rel="noopener noreferrer" to="https://www.viator.com/support/termsAndConditions" >
          Terms & Conditions.</CoreLink>  In addition, you agree the earning or redeeming of any Wyndham Rewards points
        pursuant to the completion of this purchase is subject to the Wyndham Rewards Program Terms and Conditions.
      </Typography>
    )
  }
}

export default withStyles(styles)(PaymentFormDisclamer)
