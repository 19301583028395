import {backgroundGrey, wyzardSuitBlue} from '../colors'
import {forms, labels} from '../base-styles'
import {
  mvpPadding,
  svpMargins,
  svpPadding
} from '../spacing'

export const paymentToggle = {
  container: {
    container: {
      ...forms.cardForm,
      padding: [[svpPadding, mvpPadding]]
    }
  },
  messageContainer: {
    container: {
      backgroundColor: backgroundGrey,
      width: '100%',
      padding: [[svpPadding, mvpPadding]]
    }
  },
  title: {
    root: {
      ...labels.leadingLabel,
      marginTop: svpMargins
    }
  },
  description: {
    root: {
      width: '100%'
    }
  },
  divider: {
    root: {
      width: '100%'
    }
  },
  radio: {
    root: {
      color: wyzardSuitBlue,
      '&$checked': {
        color: wyzardSuitBlue
      }
    },
    checked: {}
  }
}
