import {
  linkLightBlue,
  wyzardSuitBlue
} from '../colors'
import {
  minimalMargin,
  mvpPadding,
  svpPadding
} from '../spacing'
import {banner as activitiesBanner} from './banner'

export const banner = ({typography, breakpoints}) => ({
  container: {
    marginTop: '2rem',
    background: wyzardSuitBlue,
    [breakpoints.down('sm')]: {
      marginTop: '1rem'
    }
  },
  title: {
    ...activitiesBanner.title.root,
    fontSize: typography.subtitle1.fontSize,
    letterSpacing: typography.subtitle2.letterSpacing,
    lineHeight: typography.body.lineHeight,
    padding: 0
  },
  description: {
    ...activitiesBanner.description.root,
    fontSize: typography.subtitle1.fontSize,
    lineHeight: typography.body.lineHeight,
    padding: 0
  },
  contentContainer: {
    flexBasis: 'auto'
  }
})

export const foodDeliveryStartBanner = theme => {
  const baseBanner = banner(theme)
  return {
    ...baseBanner,
    container: {
      ...baseBanner.container,
      padding: [[mvpPadding, mvpPadding, 0]],
      [theme.breakpoints.down('sm')]: {
        padding: [[mvpPadding, svpPadding, 0]]
      }
    },
    description: {
      ...baseBanner.description,
      padding: 0,
      [theme.breakpoints.up('xs')]: {
        padding: 0
      }
    },
    title: {
      ...baseBanner.title,
      padding: [[0, 0, minimalMargin]],
      [theme.breakpoints.up('xs')]: {
        padding: 0
      }
    }
  }
}

export const foodDeliveryMidBanner = theme => {
  const baseBanner = banner(theme)
  return {
    ...baseBanner,
    container: {
      ...baseBanner.container,
      marginTop: 0,
      padding: [[mvpPadding, mvpPadding, 0, mvpPadding]],
      [theme.breakpoints.down('sm')]: {
        padding: [[mvpPadding, svpPadding, 0, svpPadding]]
      }
    },
    description: {
      ...baseBanner.description,
      padding: 0,
      [theme.breakpoints.up('xs')]: {
        padding: 0
      }
    },
    link: {
      color: linkLightBlue
    },
    title: {
      ...baseBanner.title,
      padding: [[0, 0, minimalMargin]],
      [theme.breakpoints.up('xs')]: {
        padding: 0
      }
    }
  }
}

export const foodDeliveryEndBanner = theme => {
  const baseBanner = banner(theme)
  return {
    ...baseBanner,
    container: {
      ...baseBanner.container,
      marginTop: 0,
      padding: [mvpPadding],
      [theme.breakpoints.down('sm')]: {
        padding: [[mvpPadding, svpPadding, mvpPadding]]
      }
    },
    description: {
      ...baseBanner.description,
      padding: 0,
      [theme.breakpoints.up('xs')]: {
        padding: 0
      }
    },
    link: {
      color: linkLightBlue
    },
    title: {
      ...baseBanner.title,
      padding: [[0, 0, minimalMargin]],
      [theme.breakpoints.up('xs')]: {
        padding: 0
      }
    }
  }
}
