import {TinyTextLetterSpacing, globalFont, smallTextLetterSpacing} from '../font'
import {lvpGutters, lvpMargins, lvpPadding, minimalPadding, mvpPadding, svpPadding} from '../spacing'
import {
  ruleGrey,
  titaniumMember,
  wyzardSuitBlue
} from '../colors'

const elementBorder = `1px solid ${ruleGrey}`

export const checkoutRewards = {
  pointsContainer: {
    container: {
      width: 'auto',
      display: 'block',
      marginBottom: lvpMargins
    }
  },
  points: {
    root: {
      color: wyzardSuitBlue,
      fontSize: 15,
      letterSpacing: smallTextLetterSpacing,
      display: 'inline'
    }
  },
  amount: {
    root: {
      color: wyzardSuitBlue,
      fontSize: 15,
      letterSpacing: smallTextLetterSpacing,
      padding: [[0, minimalPadding]],
      display: 'inline'
    }
  },
  description: {
    root: {
      color: titaniumMember,
      fontSize: 15,
      letterSpacing: smallTextLetterSpacing,
      marginBottom: svpPadding
    }
  },
  container: {
    container: {
      marginTop: lvpGutters,
      widht: '100%',
      border: elementBorder,
      padding: [[mvpPadding, mvpPadding, lvpPadding, mvpPadding]]
    }
  },
  icon: {
    width: 200
  },
  iconContainer: {
    item: {
      marginBottom: lvpMargins
    }
  },
  signUp: {
    root: {
      color: titaniumMember,
      fontSize: 13,
      letterSpacing: TinyTextLetterSpacing,
      marginTop: lvpMargins
    }
  },
  signUpContainer: {
    item: {
      marginTop: svpPadding
    }
  },
  link: {
    root: {
      fontSize: 13,
      paddingLeft: minimalPadding,
      fontFamily: globalFont,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}
