import {matchPath} from 'react-router-dom'
import {resolve} from '@iseatz/framework'

export const modules = [{
  link: resolve('route:path.activities'),
  name: resolve('lang:ams.activities.label'),
  pathIndex: 0
},
{
  link: resolve('route:path.foodDelivery'),
  name: resolve('lang:ams.foodDelivery.label'),
  pathIndex: 0
}]

const pagesConfig = [
  {
    name: resolve('lang:ams.foodDelivery.label'),
    link: resolve('route:path.foodDelivery')
  }
]

const foodDeliveryCreateRoute = (history, {pathname}) => {
  const [homePage] = pagesConfig
  return [homePage]
}

/**
 * Takes a location object returns true if its the home page
 * for activities or the home page for foodDelivery
 * @param {location}
 */

export const isHomepage = ({pathname}) => matchPath(pathname, {
  path: resolve('route:path.activities'),
  exact: true
}) || matchPath(pathname, {
  path: resolve('route:path.foodDelivery'),
  exact: true
})

/**
 * Takes a location object returns true if its the home page
 * for activities or the home page for foodDelivery
 * @param {location}
 */

export const isSearch = ({pathname}) => matchPath(pathname, {
  path: resolve('route:path.activities.search'),
  exact: true
})

/**
 * Takes a location object returns true if its the home page
 * for activities or the home page for foodDelivery
 * @param {location}
 */

export const isDetails = ({pathname}) => matchPath(pathname, {
  path: resolve('route:path.activities.details'),
  exact: true
})

/**
 * takes a pathname and returns breadcrumb configuration for foodDelivery
 * if food delivery path. Else returns empty so that it takes default configuration
 * @param {pathname} current pathname
 */

export const maybeCreateRouteProps = ({pathname}) => {
  if (matchPath(pathname, {path: resolve('route:path.foodDelivery')})) {
    return {createRoutes: foodDeliveryCreateRoute}
  }
  return {}
}
