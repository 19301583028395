import {
  heavyPadding,
  lvpPadding,
  lvpPageGutters,
  mvpSvpPageGutters,
  noPadding,
  svpPadding
} from '../../spacing'
import {svpTheme} from '../../theme'

export const detailsLayout = {
  container: {
    padding: svpPadding,
    [svpTheme.breakpoints.down('md')]: {
      padding: [[lvpPadding, mvpSvpPageGutters]]
    },
    [svpTheme.breakpoints.up('lg')]: {
      marginLeft: lvpPageGutters,
      marginRight: lvpPageGutters,
      padding: [[lvpPadding, 0, heavyPadding, 0]]
    },
    [svpTheme.breakpoints.down('sm')]: {
      padding: [[svpPadding, noPadding, heavyPadding, noPadding]]
    }
  }
}
