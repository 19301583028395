import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {footerTextStyles as styles} from '../../styles'
import {withStyles} from '@material-ui/core/styles'

class FooterText extends PureComponent {
  render () {
    const {classes, text} = this.props
    return (
      <Grid className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Typography variant="subtitle1" component="p" align="center" className={classes.text}>
          &copy; 2019 Wyndham Rewards, Inc. All rights reserved.
          </Typography>
          <Typography variant="subtitle1" component="p" align="center" className={classes.text}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

FooterText.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string
  }),
  text: PropTypes.string
}

export default withStyles(styles)(FooterText)
