import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import {svpTheme} from '../../styles/theme'
import {withStyles} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'

// IE-11 Block
const GridBlock = withStyles({
  root: {
    [svpTheme.breakpoints.up('lg')]: {
      maxWidth: '55%'
    }
  }
})(({classes, children}) => (<div className={classes.root}>{children}</div>))

class Title extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.shape({
      container: PropTypes.string,
      titleContainerItem: PropTypes.string
    }),
    width: PropTypes.string
  }
  static defaultProps = {
    classes: {}
  }

  render () {
    const {
      children,
      classes,
      width
    } = this.props
    const GridComponent = width === 'xl' ? GridBlock : Grid
    return (
      <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
        <GridComponent item xs={9} md={7} lg={7} className={classes.titleContainerItem}>
          {children}
        </GridComponent>
      </Grid>
    )
  }
}
export default withWidth()(Title)
