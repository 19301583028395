import {NavLink, withRouter} from 'react-router-dom'
import React, {PureComponent} from 'react'
import {
  globalFont,
  linkBlue,
  navDropdownFooterStyles,
  navDropdownStyles,
  regular,
  signButtonTextSize,
  smallTextLetterSpacing,
  svpTheme} from '../styles'
import {BreadcrumbsContainer} from '@iseatz/ams-activities'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ImmutablePropTypes from 'react-immutable-proptypes'
import Link from '@material-ui/core/Link'
import {Profile} from './session/profile'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import {UserInformation} from './user-information'
import {backgroundGrey} from '../styles/colors'
import {breadcrumbs} from '../styles/component-styles/breadcrumbs'
import {compose} from 'redux'
import {connect} from 'react-redux'
import dictionary from '../dictionary/en'
import {getKey} from '../libs/dictionary'
import {isHomepage} from '../layout/header/util'
import {maybeCreateRouteProps} from './header/util'
import {resolve} from '@iseatz/framework'
import {selectors} from '@iseatz/ams-profile'
import {whenFoodDeliveryEnabled} from '../utils'
import {withStyles} from '@material-ui/core/styles'

const StyledGrid = withStyles(navDropdownFooterStyles)(Grid)
const StyledBreadcrumbs = withStyles({
  breadcrumbsContainer: {
    background: backgroundGrey,
    padding: [[2, 0]]
  },
  breadcrumbsLeadingItem: {
    ...breadcrumbs.breadcrumbsLeadingItem
  }
})(({classes, onClick, ...rest}) => (
  <div className={classes.breadcrumbsContainer}>
    <BreadcrumbsContainer BreadcrumbsProps={{
      onLinkClick: () => onClick(),
      initElement: <span className={classes.breadcrumbsLeadingItem}>&#8249;</span>
    }}
    {...rest}
    />
  </div>
))

const AccountButton = withStyles({
  root: {
    float: 'right',
    padding: 0
  },
  label: {
    ...svpTheme.typography.button,
    color: linkBlue,
    textTransform: 'capitalize',
    fontSize: signButtonTextSize,
    fontWeight: regular,
    letterSpacing: smallTextLetterSpacing,
    fontFamily: globalFont
  }
})(Button)

const WyndhamRewardsJoinUrl = 'https://www.wyndhamhotels.com/wyndham-rewards/join'

class NavDropdownComponent extends PureComponent {
  render () {
    const {
      classes,
      onClose,
      user,
      isLoggedIn,
      location
    } = this.props

    let userInformation = user || null
    let maybeJoinNowLinks = (<StyledGrid container alignItems="center" justify="center" direction="column">
      <Typography className={classes.heroRewardText}>Not a Wyndham Rewards Member?</Typography>
      <Link variant="body2" href={WyndhamRewardsJoinUrl} underline="always">Join Today</Link>
    </StyledGrid>)
    if (isLoggedIn) {
      userInformation = <UserInformation
        {...user.toJS()}
        containerProps={{justify: 'space-between'}}
        innerContainerProps={{direction: 'column'}}
        pointsLabel={getKey(dictionary, 'sections.activity.components.userInformation.points')}/>

      maybeJoinNowLinks = null
    }
    const maybeFoodDeliveryButton = whenFoodDeliveryEnabled(<NavLink
      to={resolve('route:path.foodDelivery')}
      className={classes.navLink}
      activeClassName={classes.navLinkSelected}
      onClick={onClose}>
      Food Delivery
    </NavLink>)

    return (
      <Grid container alignItems="stretch" direction="row" className={classes.container}>
        <Grid item xs={12} className={classes.userInfo}>
          <Profile
            ButtonComponent={AccountButton}
            onClose={onClose}
            signInOutContainerProps={{
              justify: 'flex-end'
            }}/>
          {userInformation}
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <NavLink
            to={resolve('route:path.activities')}
            className={classes.navLink}
            activeClassName={classes.navLinkSelected}
            onClick={onClose}>
            Tours & Activities
          </NavLink>
          {maybeFoodDeliveryButton}
          <StyledBreadcrumbs onClick={onClose} showRoot={isHomepage(location)} {...maybeCreateRouteProps(location)}/>
          {maybeJoinNowLinks}
        </Grid>
      </Grid>
    )
  }
}

NavDropdownComponent.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    heroRewardText: PropTypes.string,
    item: PropTypes.string,
    navLink: PropTypes.string,
    navLinkSelected: PropTypes.string,
    userInfo: PropTypes.string
  }),
  isLoggedIn: PropTypes.bool,
  onClose: PropTypes.func,
  user: ImmutablePropTypes.contains({}),
  location: PropTypes.shape({})
}

const mapStateToProps = state => ({
  isLoggedIn: selectors.isLoggedIn(state),
  user: selectors.getUser(state)
})

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withStyles(navDropdownStyles)
)

export const NavDropdown = enhance(NavDropdownComponent)
