import {
  backgroundGrey,
  black,
  bodyGrey,
  errorRed,
  legalGrey,
  linkBlue,
  linkLightBlue,
  ruleGrey,
  successGreen,
  titaniumMember,
  white,
  wyzardSuitBlue
} from './colors'
import {
  bodyTextSize,
  bold,
  buttonTextSize,
  globalFont,
  headerTextSize,
  heroLargeTextSize,
  highlightsTextSize,
  largeTextLetterSpacing,
  logoTextSize,
  offerTextSize,
  subheaderLineHeight,
  svpInputText
} from './font'
import {
  lvpPadding,
  mvpPadding,
  noPadding
} from './spacing'
import {createMuiTheme} from '@material-ui/core/styles'
import {enableSvp} from './../config'

export const svpTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: enableSvp ? 600 : 0,
      md: enableSvp ? 768 : 0,
      lg: 1200,
      xl: 1300
    }
  },
  overrides: {
    MuiCollapse: {
      wrapperInner: {
        color: bodyGrey,
        fontFamily: globalFont,
        fontSize: buttonTextSize,
        lineHeight: 1.375
      }
    },
    MuiFormControl: {
      root: {
        background: white,
        height: '3.125rem',
        '& > label': {
          color: legalGrey,
          fontSize: svpInputText
        },
        '& input': {
          fontWeight: bold,
          color: titaniumMember,
          fontSize: svpInputText
        }
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: noPadding
      }
    },
    MuiExpansionPanelSummary: {
      content: {
        display: 'inline',
        margin: 0,
        '&$disabled': {
          margin: 0
        },
        '& > :last-child': {
          padding: 0,
          margin: 0
        },
        '&$expanded': {
          margin: 0
        }
      },
      expandIcon: {
        color: linkBlue
      },
      root: {
        display: 'inline-grid',
        '&$disabled': {
          margin: 0,
          minHeight: 0,
          maxHeight: 16
        },
        '&$expanded': {
          margin: 0,
          minHeight: 0,
          maxHeight: 22
        },
        padding: [[0, 5, 0, 0]]
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingTop: 0
        }
      }
    },
    MuiDialogTitle: {
      root: {
        padding: 0
      }
    }
  },
  palette: {
    background: {
      paper: white,
      default: white,
      grey: backgroundGrey
    },
    common: {
      black,
      white
    },
    error: {
      main: errorRed
    },
    primary: {
      light: linkLightBlue,
      main: linkBlue,
      dark: wyzardSuitBlue,
      contrastText: white
    },
    secondary: {
      light: ruleGrey,
      main: legalGrey,
      dark: bodyGrey
    },
    success: {
      main: successGreen
    },
    text: {
      primary: bodyGrey,
      secondary: black,
      disabled: legalGrey
    }
  },
  shape: {
    borderRadius: 0
  },
  spacing: {
    unit: 8
  },
  props: {
    // TODO: Setting the variant through the theme isn't working for core/SingleMutliField
    MuiFormControl: {
      variant: 'outlined'
    },
    MuiSelect: {
      variant: 'outlined'
    }
  },
  typography: {
    body: {
      fontSize: bodyTextSize,
      lineHeight: 1.2
    },
    button: {
      fontWeight: bold,
      fontSize: buttonTextSize,
      textTransform: 'uppercase'
    },
    fontFamily: globalFont,
    headline: {
      fontSize: heroLargeTextSize,
      lineHeight: 1.2
    },
    rewardTitle: {
      fontFamily: globalFont,
      fontSize: subheaderLineHeight
    },
    rewardSubscript: {
      fontFamily: globalFont,
      fontSize: logoTextSize
    },
    subtitle1: {
      color: titaniumMember,
      fontSize: buttonTextSize
    },
    subtitle2: {
      color: wyzardSuitBlue,
      fontWeight: bold,
      fontSize: highlightsTextSize,
      letterSpacing: largeTextLetterSpacing
    },
    title: {
      fontSize: headerTextSize,
      lineHeight: 1.2
    },
    titleReward: {
      fontSize: logoTextSize
    },
    useNextVariants: true
  }
})

svpTheme.shadows = new Array(25)

export const mvpTheme = createMuiTheme({
  ...svpTheme,
  overrides: {
    ...svpTheme.overrides,
    MuiFormControl: {
      root: {
        background: white,
        height: '3.125rem',
        '& > label': {
          color: legalGrey,
          fontSize: offerTextSize
        },
        '& input': {
          fontWeight: bold,
          color: titaniumMember,
          fontSize: offerTextSize
        }
      }
    }
  },
  props: {
    ...svpTheme.props,
    MuiGridList: {
      cellHeight: 'auto',
      spacing: mvpPadding
    }
  },
  spacing: {unit: 16}
})
export const lvpTheme = createMuiTheme({
  ...svpTheme,
  overrides: {
    ...mvpTheme.overrides
  },
  props: {
    ...svpTheme.props,
    MuiGridList: {
      cellHeight: 'auto',
      spacing: lvpPadding
    }
  },
  spacing: {unit: 24}
})
