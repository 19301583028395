import * as config from '../../config'
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {linkTitle} from '../../styles/component-styles/branding'
import {withStyles} from '@material-ui/core/styles'

class BrandingTitleComponent extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
      super: PropTypes.string
    }),
    goForItLink: PropTypes.string
  }

  render () {
    const {classes, goForItLink} = this.props

    return (
      <a href={goForItLink} className={classes.root}>
        <span>{config.siteName}<sup className={classes.super}>&#8480;</sup></span>
      </a>
    )
  }
}

export default withStyles(linkTitle)(BrandingTitleComponent)
