import {bold, smallTitleTextSize} from '../font'
import {lvpMargins, lvpPadding} from '../spacing'
import {ruleGrey} from '../colors'

const footerImageStyles = ({breakpoints}) => ({
  root: {
    margin: '3rem 0',
    [breakpoints.up('md')]: {
      margin: '6rem 0'
    },
    width: 'auto',
    height: 60
  }
})
const footerLinkStyles = ({typography, breakpoints}) => ({
  root: {
    fontFamily: typography.fontFamily,
    margin: 'auto',
    [breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '2rem'
    }
  },
  termsAndConditionsContainer: {
    [breakpoints.up('md')]: {
      borderRight: `.125rem solid ${ruleGrey}`,
      paddingRight: lvpPadding,
      textAlign: 'right'
    }
  },
  termsOfUseContainer: {
    [breakpoints.up('md')]: {
      textAlign: 'left',
      paddingLeft: lvpPadding
    },
    [breakpoints.down('sm')]: {
      padding: '1rem'
    }
  },
  wrapper: {
    margin: '1.75rem auto 0',
    [breakpoints.down('sm')]: {
      margin: '0 auto 0'
    }
  },
  dialogTitle: {
    margin: 'auto 0',
    fontSize: typography.button.fontSize,
    [breakpoints.up('sm')]: {
      fontSize: typography.title.fontSize
    }
  }
})
const footerLinkTermHeader = {
  container: {
    flex: 'none',
    padding: [[lvpPadding, lvpPadding, 0]]
  }
}
const footerLinkTermBody = {
  container: {
    padding: [[0, lvpPadding, lvpPadding]]
  }
}
const footerTextStyles = ({typography, breakpoints}) => ({
  root: {
    margin: [[lvpMargins, 0]],
    padding: '0 2.25rem',
    [breakpoints.up('md')]: {
      padding: '0 9rem 6rem 9rem'
    }
  },
  text: {
    maxWidth: '100%',
    [breakpoints.down('sm')]: {
      fontSize: smallTitleTextSize
    }
  },
  copyright: {
    fontFamily: typography.button.fontFamily,
    fontWeight: bold
  }
})
const footerTermsOfUseStyles = {
  root: {
    padding: 0,
    '&:after': { // Fixes Ie bug no padding
      content: '""',
      display: 'block',
      paddingBottom: '2rem'
    }
  }
}

const footerDividerStyles = ({breakpoints}) => ({
  divider: {
    [breakpoints.down('sm')]: {
      margin: [[0, 10]]
    }
  }
})

export {
  footerDividerStyles,
  footerImageStyles,
  footerLinkStyles,
  footerLinkTermHeader,
  footerLinkTermBody,
  footerTextStyles,
  footerTermsOfUseStyles
}
