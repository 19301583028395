/* eslint-disable max-lines */
import {ReservationCartBreakdown, ReservationTourBreakdown} from '../components'
import {
  confirmationBookingInfo,
  confirmationHeader,
  pendingReservationFooter,
  reservationBillingInfo,
  reservationButtons,
  reservationPage
} from '../styles'
import Button from '@material-ui/core/Button'
import {ReactComponent as Calendar} from '../img/Calendar.svg'
import {ReactComponent as Clock} from '../img/Clock.svg'
import {Container} from '@iseatz/framework'
import {ReactComponent as Earn} from '../img/EarnCongrats.svg'
import {ReactComponent as Email} from '../img/Email.svg'
import {ReactComponent as ErrorIcon} from '../img/Error.svg'
import {ReactComponent as ExpandBreakdown} from '../img/ExpandBreakdown.svg'
import Grid from '@material-ui/core/Grid'
import {ReactComponent as Group} from '../img/Group.svg'
import {ReactComponent as Print} from '../img/Print.svg'
import {ReservationEmailCompactModal} from '../layout/svp'
import {ResizedClearIcon} from '../img/clearResize'
import UserTierBar from '../layout/tier-bar'
import dictionary from '../dictionary/en'
import {getKey} from '../libs/dictionary'
import {priceWithLocale} from '../utils'

// Reservation Reservation Layout
Container.bind('props:ams.activities.reservation.layout.header', {
  ConfirmationHeaderProps: {
    title: 'Thank you for your booking!',
    subtitle: 'Look out for a confirmation email from Viator with your activity details.'
  },
  PendingConfirmationHeaderProps: {
    title: getKey(dictionary, 'sections.activity.reservation.pendingConfirmation.title'),
    subtitle: getKey(dictionary, 'sections.activity.reservation.pendingConfirmation.subtitle')
  },
  CancellationHeaderProps: {
    title: 'Your Booking Has Been Cancelled',
    subtitle: `You've successfully cancelled your booking with Viator.`
  }
})

Container.bind('component:ams.activities.reservation.layout.cartBreakdown', ReservationCartBreakdown)
Container.bind('props:ams.activities.reservation.layout.cartBreakdown', {
  footerText: 'Balance Paid Today'
})
Container.bind('styles:ams.activities.reservation.layout.layout', reservationPage.layout)
Container.bind('styles:ams.activities.reservation.layout.rightSideBar', reservationPage.rightContainer)

Container.bind('props:ams.activities.reservation.footer.earn', {
  isCongrats: true,
  supplier: 'Wyndham'
})
Container.bind('props:ams.activities.reservation.layout.cancellationModal', {
  reasonsForCancellation: [
    {label: 'Airline Flight Cancellation', id: 1, value: 1},
    {label: 'Airline Schedule Change', id: 2, value: 2},
    {label: 'Death', id: 3, value: 3},
    {label: 'Jury Duty/Court Summons', id: 4, value: 4},
    {label: 'Medical Emergency/Hospitalization', id: 5, value: 5},
    {label: 'Military Service', id: 6, value: 6},
    {label: 'National Disaster (Insurrection, Terrorism, War)', id: 7, value: 7},
    {label: 'Natural Disaster (Earthquake, Fire, Flood)', id: 8, value: 8},
    {label: 'Transport Strike/Labor Dispute', id: 9, value: 9},
    {label: 'Credit Card Fraud', id: 10, value: 10},
    {label: 'Car Segment Cancellation', id: 11, value: 11},
    {label: 'Package Segment Cancellation', id: 12, value: 12},
    {label: 'Hotel Segment Cancellation', id: 13, value: 13},
    {label: 'Re-book', id: 14, value: 14},
    {label: 'Duplicate Purchase', id: 15, value: 15},
    {label: 'Honest Mistake', id: 16, value: 16}
  ],
  modalText: getKey(dictionary, 'sections.activity.reservation.cancelationModal.modalText'),
  listTitle: getKey(dictionary, 'sections.activity.reservation.cancelationModal.listTitle'),
  listContent: [getKey(dictionary, 'sections.activity.reservation.cancelationModal.listFirstItem'),
    getKey(dictionary, 'sections.activity.reservation.cancelationModal.listSecondItem')],
  reasonText: getKey(dictionary, 'sections.activity.reservation.cancelationModal.reasonCopy')
})

Container.bind('styles:ams.activities.reservation.cancelModal.icon', reservationPage.cancellationModal.icon)
Container.bind('styles:ams.activities.reservation.cancelModal.list', reservationPage.cancellationModal.list)
Container.bind('styles:ams.activities.reservation.cancelModal.listText',
  reservationPage.cancellationModal.listItem)
Container.bind('styles:ams.activities.reservation.cancelModal.dialog',
  reservationPage.cancellationModal.dialog)
Container.bind('styles:ams.activities.reservation.cancelModal.title',
  reservationPage.cancellationModal.dialogTitle)
Container.bind('styles:ams.activities.reservation.cancelModal.subtitle',
  reservationPage.cancellationModal.dialogSubtitle)
Container.bind('styles:ams.activities.reservation.cancelModal.commentInput',
  reservationPage.cancellationModal.commentInput)
Container.bind('styles:ams.activities.reservation.cancelModal.selectContainer',
  reservationPage.cancellationModal.selectContainer)
Container.bind('styles:ams.activities.reservation.cancelModal.button',
  reservationPage.cancellationModal.modalButton)
Container.bind('styles:ams.activities.reservation.cancelModal.buttonContainer',
  reservationPage.cancellationModal.buttonContainer)
Container.bind('component:ams.activities.reservation.cancelModal.clearIcon', ResizedClearIcon)
Container.bind('styles:ams.activities.reservation.cancelModal.clearContainer',
  reservationPage.cancellationModal.clearContainer)
Container.bind('styles:ams.activities.reservation.cancelModal.inputLabel',
  reservationPage.cancellationModal.inputLabel)
Container.bind('styles:ams.activities.reservation.cancelModal.dropdown',
  reservationPage.cancellationModal.dropdown)

// Language
Container.bind('lang:ams.activities.reseration.label', 'Reservation Page')

// Reservation Terms Modal
Container.bind('styles:ams.activities.reservation.termsModal.dialog', reservationPage.cancellationModal.dialog)
Container.bind('styles:ams.activities.reservation.termsModal.title',
  reservationPage.cancellationModal.dialogTitle)
Container.bind('styles:ams.activities.reservation.termsModal.subtitle',
  reservationPage.cancellationModal.dialogTermsSubtitle)
Container.bind('component:ams.activities.reservation.termsModal.clearIcon', ResizedClearIcon)
Container.bind('styles:ams.activities.reservation.termsModal.headerContainer',
  reservationPage.cancellationModal.headerContainer)

// Reservation Email Modal
Container.bind('props:ams.activities.reservation.layout.emailModal', {
  CloseContainerProps: {
    justify: 'flex-end'
  },
  EmailProps: {
    fullWidth: false
  }
})
Container.bind('props:ams.activities.reservation.emailModal.email', {
  variant: 'outlined'
})
Container.bind('props:ams.activities.reservation.emailModal.dialog', {
  fullWidth: true
})
Container.bind('props:ams.activities.reservation.emailModal.buttonContainer', {
  justify: 'flex-end'
})
Container.bind('props:ams.activities.reservation.emailModal.close', {
  disableRipple: true
})
Container.bind('styles:ams.activities.reservation.emailModal.dialog',
  reservationPage.emailModal.dialog)
Container.bind('styles:ams.activities.reservation.emailModal.title',
  reservationPage.emailModal.dialogTitle)
Container.bind('styles:ams.activities.reservation.emailModal.email',
  reservationPage.emailModal.emailInput)
Container.bind('styles:ams.activities.reservation.emailModal.button',
  reservationPage.emailModal.modalButton)
Container.bind('styles:ams.activities.reservation.emailModal.buttonContainer',
  reservationPage.emailModal.buttonContainer)
Container.bind('component:ams.activities.reservation.emailModal.closeIcon', ResizedClearIcon)
Container.bind('styles:ams.activities.reservation.emailModal.close',
  reservationPage.emailModal.closeButton)
Container.bind('component:ams.activities.reservation.layout.emailModalCompact', ReservationEmailCompactModal)

// Reservation Confirmation Header
Container.bind('styles:ams.activities.reservation.confirmationHeader.container', confirmationHeader.container)
Container.bind('styles:ams.activities.reservation.confirmationHeader.title', confirmationHeader.title)
Container.bind('styles:ams.activities.reservation.confirmationHeader.subtitle', confirmationHeader.subtitle)
Container.bind('component:ams.activities.reservation.confirmationHeader.printIcon', Print)
Container.bind('component:ams.activities.reservation.confirmationHeader.emailIcon', Email)
Container.bind('styles:ams.activities.reservation.confirmationHeader.buttonText', confirmationHeader.buttonText)
Container.bind('styles:ams.activities.reservation.confirmationHeader.buttonContainer',
  confirmationHeader.buttonContainer)
Container.bind('component:ams.activities.reservation.confirmationHeader.emailButton', Button)

// Reservation Pending Confirmation Header
Container.bind('styles:ams.activities.reservation.pendingConfirmationHeader.container', confirmationHeader.container)
Container.bind('styles:ams.activities.reservation.pendingConfirmationHeader.title', confirmationHeader.title)
Container.bind('styles:ams.activities.reservation.pendingConfirmationHeader.subtitle', confirmationHeader.subtitle)
Container.bind('component:ams.activities.reservation.pendingConfirmationHeader.printIcon', Print)
Container.bind('styles:ams.activities.reservation.pendingConfirmationHeader.buttonText', confirmationHeader.buttonText)
Container.bind('props:ams.activities.reservation.pendingConfirmationHeader.buttonContainer', {
  justify: 'flex-end'
})
Container.bind('styles:ams.activities.reservation.pendingConfirmationHeader.buttonContainer',
  confirmationHeader.buttonContainer)

// Reservation Pending Confirmation Footer
Container.bind('props:ams.activities.reservation.layout.footer', {
  pendingConfirmationContent: getKey(dictionary, 'sections.activity.reservation.pendingConfirmation.footer')
})

Container.bind('styles:ams.activities.reservation.footer.pendingConfirmation', {root: pendingReservationFooter.footer})

// Reservation Cancellation Header
Container.bind('component:ams.activities.reservation.cancellationHeader.emailButton', Button)
Container.bind('styles:ams.activities.reservation.cancellationHeader.container',
  reservationPage.cancellationHeader.container)
Container.bind('styles:ams.activities.reservation.cancellationHeader.title',
  reservationPage.cancellationHeader.title)
Container.bind('component:ams.activities.reservation.cancellationHeader.printIcon', Print)
Container.bind('styles:ams.activities.reservation.cancellationHeader.subtitle',
  confirmationHeader.subtitle)
Container.bind('styles:ams.activities.reservation.cancellationHeader.buttonText',
  confirmationHeader.buttonText)
Container.bind('props:ams.activities.reservation.footer.cancelInfo', {
  cancelPoints: [
    'Refunds, whether paid with Wyndham Rewards points or a credit card, will appear after your cancellation request has been processed.', // eslint-disable-line max-len
    'Some activities offer a partial refund, based on the merchant’s cancellation policy.']
})
Container.bind('component:ams.activities.reservation.cancellationHeader.emailIcon', Email)
Container.bind('styles:ams.activities.reservation.cancellationHeader.buttonContainer', {})

// Confirmation Booking Info
Container.bind('styles:ams.activities.reservation.cartBreakdown.container', confirmationBookingInfo.container)

// Confirmation Booking Header
Container.bind('props:ams.activities.reservation.cartBreakdown.header', {
  ContainerProps: {justify: 'space-between'},
  leftTitle: 'Trip ID/Supplier ID',
  supplierId: '123456789' // TODO Replace mock data when Confirmation epic is built
})
Container.bind('styles:ams.activities.reservation.cartBreakdown.header', confirmationBookingInfo.header)
Container.bind('styles:ams.activities.reservation.productHeader.container',
  confirmationBookingInfo.productHead.container)
Container.bind('styles:ams.activities.reservation.productHeader.leftTitle',
  confirmationBookingInfo.productHead.leftTitle)
Container.bind('styles:ams.activities.reservation.productHeader.leftInfo',
  confirmationBookingInfo.productHead.leftInfo)

// Confirmation Product Breakdown
Container.bind('component:ams.activities.reservation.productBreakdown.calendarIcon', Calendar)
Container.bind('component:ams.activities.reservation.productBreakdown.clockIcon', Clock)
Container.bind('component:ams.activities.reservation.productBreakdown.groupIcon', Group)
Container.bind('styles:ams.activities.reservation.productBreakdown.container',
  confirmationBookingInfo.productBreakdown.container)
Container.bind('styles:ams.activities.reservation.productBreakdown.title',
  confirmationBookingInfo.productBreakdown.title)
Container.bind('styles:ams.activities.reservation.productBreakdown.titleContainer',
  confirmationBookingInfo.productBreakdown.titleContainer)
Container.bind('styles:ams.activities.reservation.productBreakdown.listItem',
  confirmationBookingInfo.productBreakdown.listItems)
Container.bind('styles:ams.activities.reservation.productBreakdown.traveler',
  confirmationBookingInfo.productBreakdown.iconItem)
Container.bind('styles:ams.activities.reservation.productBreakdown.time',
  confirmationBookingInfo.productBreakdown.iconItem)
Container.bind('styles:ams.activities.reservation.productBreakdown.date',
  confirmationBookingInfo.productBreakdown.iconItem)
Container.bind('styles:ams.activities.reservation.productBreakdown.bodyContainer',
  confirmationBookingInfo.productBreakdown.titleContainer)
Container.bind('styles:ams.activities.reservation.productBreakdown.link',
  confirmationBookingInfo.productBreakdown.link)

// Confirmation Price Breakdown
Container.bind('component:ams.activities.reservation.priceBreakdown.breakdown', ReservationTourBreakdown)
Container.bind('props:ams.activities.reservation.priceBreakdown.breakdown', {
  title: 'Total Booking Cost',
  expansionLabel: 'Traveler(s)',
  ListProps: {md: 12}
})
Container.bind('props:ams.activities.reservation.cartBreakdown.priceBreakdown', {
  title: 'Price Details',
  taxes: 'No additional taxes and fees.'
})
Container.bind('props:ams.activities.reservation.priceBreakdown.taxesContainer', {
  justify: 'flex-end'
})
Container.bind('styles:ams.activities.reservation.priceBreakdown.container',
  confirmationBookingInfo.priceBreakdown.container)
Container.bind('props:ams.activities.reservation.priceBreakdown.container', {
  direction: 'column'
})
Container.bind('styles:ams.activities.reservation.priceBreakdown.header',
  confirmationBookingInfo.priceBreakdown.header)
Container.bind('styles:ams.activities.reservation.priceBreakdown.taxes',
  confirmationBookingInfo.priceBreakdown.taxes)
Container.bind('styles:ams.activities.reservation.cartBreakdown.footerText',
  confirmationBookingInfo.footer.text)
Container.bind('styles:ams.activities.reservation.cartBreakdown.footerContainer',
  confirmationBookingInfo.footer.container)
Container.bind('props:ams.activities.reservation.cartBreakdown.footerContainer', {
  alignContent: 'stretch',
  elevation: 0
})
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPrice.amount',
  confirmationBookingInfo.footer.priceAmount)
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPrice.prefix',
  confirmationBookingInfo.footer.prefix)
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPrice.suffix',
  confirmationBookingInfo.footer.suffix)
Container.bind('styles:ams.activities.reservation.cartBreakdownFooterPrice.cents',
  confirmationBookingInfo.footer.cents)
Container.bind('props:ams.activities.reservation.cartBreakdown.footerPrice', {
  showCurrency: true,
  ContainerProps: {
    justify: 'flex-start',
    alignItems: 'baseline'
  },
  priceConverter: priceWithLocale
})

// Confirmation Tour Breakdown
Container.bind('props:ams.activities.reservation.tourBreakdown.price', {
  showCurrency: true,
  ContainerProps: {
    direction: 'row'
  },
  priceConverter: priceWithLocale
})
Container.bind('props:ams.activities.reservation.tourBreakdown.expansion', {
  elevation: 0
})
Container.bind('styles:ams.activities.reservation.tourBreakdown.title',
  confirmationBookingInfo.tourBreakdown.title)
Container.bind('styles:ams.activities.reservation.tourBreakdownPrice.amount',
  confirmationBookingInfo.tourBreakdown.priceAmount)
Container.bind('styles:ams.activities.reservation.tourBreakdownPrice.prefix',
  confirmationBookingInfo.tourBreakdown.priceAmount)
Container.bind('styles:ams.activities.reservation.tourBreakdownPrice.cents',
  confirmationBookingInfo.tourBreakdown.cents)
Container.bind('styles:ams.activities.reservation.tourBreakdownPrice.suffix',
  confirmationBookingInfo.tourBreakdown.suffix)
Container.bind('styles:ams.activities.reservation.tourBreakdown.expansion',
  confirmationBookingInfo.tourBreakdown.expansion)
Container.bind('styles:ams.activities.reservation.tourBreakdown.expansionLabel',
  confirmationBookingInfo.tourBreakdown.expansionLabel)
Container.bind('component:ams.activities.reservation.tourBreakdown.expandIcon', ExpandBreakdown)
Container.bind('styles:ams.activities.reservation.tourBreakdown.expansionDetails',
  confirmationBookingInfo.tourBreakdown.expansionDetails)
Container.bind('styles:ams.activities.reservation.tourBreakdown.list',
  confirmationBookingInfo.tourBreakdown.list)
Container.bind('component:ams.activities.reservation.ticketList.list', Grid)
Container.bind('props:ams.activities.reservation.ticketList.list',
  confirmationBookingInfo.tourBreakdown.listProps)
Container.bind('component:ams.activities.reservation.ticketList.listItem', Grid)
Container.bind('props:ams.activities.reservation.ticketList.listItem', {
  container: true,
  justify: 'space-between'
})
Container.bind('styles:ams.activities.reservation.ticketList.listItem',
  confirmationBookingInfo.tourBreakdown.listItem)
Container.bind('styles:ams.activities.reservation.ticketList.price',
  confirmationBookingInfo.tourBreakdown.price)
Container.bind('props:ams.activities.reservation.ticketList.price', {
  showCurrency: true
})
Container.bind('styles:ams.activities.reservation.tourBreakdownPriceList.amount',
  confirmationBookingInfo.tourBreakdownList.price)
Container.bind('styles:ams.activities.reservation.tourBreakdownPriceList.prefix',
  confirmationBookingInfo.tourBreakdownList.price)
Container.bind('styles:ams.activities.reservation.tourBreakdownPriceList.cents',
  confirmationBookingInfo.tourBreakdownList.cents)
Container.bind('styles:ams.activities.reservation.tourBreakdownPriceList.suffix',
  confirmationBookingInfo.tourBreakdownList.suffix)
Container.bind('props:ams.activities.reservation.tourBreakdownPriceList.suffix', {
  direction: 'row'
})

// Reservation Billing Info
Container.bind('styles:ams.activities.reservation.billingInfo.container', {
  container: reservationBillingInfo.container
})
Container.bind('styles:ams.activities.reservation.billingInfo.title', reservationBillingInfo.title)
Container.bind('props:ams.activities.reservation.billingInfoSection.container', {
  direction: 'column'
})
Container.bind('styles:ams.activities.reservation.billingInfoSection.container',
  reservationBillingInfo.subsection.container)
Container.bind('styles:ams.activities.reservation.billingInfoSection.listContainer',
  reservationBillingInfo.subsection.listContainer)
Container.bind('styles:ams.activities.reservation.billingInfoSection.subtitle',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.billingInfoSection.cardHolderLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.billingInfoSection.cardTypeLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.billingInfoSection.cardEndingLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.billingInfoSection.addressLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.billingInfoSection.cardHolderName',
  reservationBillingInfo.subsection.text)
Container.bind('styles:ams.activities.reservation.billingInfoSection.cardType',
  reservationBillingInfo.subsection.text)
Container.bind('styles:ams.activities.reservation.billingInfoSection.cardEnding',
  reservationBillingInfo.subsection.text)
Container.bind('styles:ams.activities.reservation.billingInfoSection.address',
  reservationBillingInfo.subsection.text)
Container.bind('styles:ams.activities.reservation.billingInfo.creditCardWrapper',
  reservationBillingInfo.creditCardWrapper)

Container.bind('props:ams.activities.reservation.creditCardSection.container', {
  direction: 'column'
})
Container.bind('styles:ams.activities.reservation.creditCardSection.container',
  reservationBillingInfo.subsection.container)
Container.bind('styles:ams.activities.reservation.creditCardSection.listContainer',
  reservationBillingInfo.subsection.listContainer)
Container.bind('styles:ams.activities.reservation.creditCardSection.subtitle',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.creditCardSection.travelerLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.creditCardSection.phoneLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.creditCardSection.emailLabel',
  reservationBillingInfo.subsection.label)
Container.bind('styles:ams.activities.reservation.creditCardSection.travelerName',
  reservationBillingInfo.subsection.text)
Container.bind('styles:ams.activities.reservation.creditCardSection.phone',
  reservationBillingInfo.subsection.text)
Container.bind('styles:ams.activities.reservation.creditCardSection.email',
  reservationBillingInfo.subsection.text)
Container.bind('props:ams.activities.reservation.getTicketsButton.button', {
  fullWidth: true
})
Container.bind('styles:ams.activities.reservation.getTicketsButton.button',
  reservationButtons.getTickets.button)
Container.bind('styles:ams.activities.reservation.cancelBookingButton.button',
  reservationButtons.cancelBookingButton.button)

// Reservation Terms And Conditions
Container.bind('styles:ams.activities.reservation.termsConditions.panel',
  reservationPage.terms.panel)
Container.bind('styles:ams.activities.reservation.termsConditions.header',
  reservationPage.terms.header)
Container.bind('styles:ams.activities.reservation.termsConditions.container',
  reservationPage.terms.container)
Container.bind('styles:ams.activities.reservation.termsConditions.details',
  reservationPage.terms.details)
Container.bind('styles:ams.activities.reservation.termsConditions.summary',
  reservationPage.terms.summary)

// Reservation Earn
Container.bind('styles:ams.activities.reservation.earn.container',
  reservationPage.earn.container)
Container.bind('styles:ams.activities.reservation.earn.text',
  reservationPage.earn.text)
Container.bind('component:ams.activities.reservation.earn.icon', Earn)
Container.bind('styles:ams.activities.reservation.earn.iconContainer',
  reservationPage.earn.iconContainer)
Container.bind('styles:ams.activities.reservation.earn.loyaltyLine',
  reservationPage.earn.loyaltyLine)
Container.bind('component:ams.activities.reservation.earn.tierBar', UserTierBar)

// Cancellation Info
Container.bind('styles:ams.activities.reservation.cancellationInfo.container', reservationPage.cancelInfo.container)
Container.bind('styles:ams.activities.reservation.cancellationInfo.title', reservationPage.cancelInfo.title)
Container.bind('styles:ams.activities.reservation.cancellationInfo.content', reservationPage.cancelInfo.content)
Container.bind('styles:ams.activities.reservation.cancellationInfo.errorContainer',
  reservationPage.cancelInfo.errorContainer)
Container.bind('component:ams.activities.reservation.cancellationInfo.errorIcon', ErrorIcon, false)
Container.bind('styles:ams.activities.reservation.error.cancelModel.container', reservationPage.errorContainer, false)
Container.bind('styles:ams.activities.reservation.error.cancelModel.icon', reservationPage.errorIcon, false)
Container.bind('styles:ams.activities.reservation.error.cancelModel.text', reservationPage.errorText, false)
Container.bind('props:ams.activities.reservation.error.cancelModel.text', {
  variant: 'body1'
})
