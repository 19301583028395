import {buttons} from '../base-styles'

export const profileButton = ({breakpoints}) => ({
  root: {
    [breakpoints.up('md')]: {
      ...buttons.invertedHover
    },
    [breakpoints.down('md')]: {
      width: 'auto'
    }
  }
})
