import {svpMargins} from '../spacing'
import {svpTheme} from '../theme'

export const navMenuIconStyles = ({palette}) => ({
  root: {
    color: palette.primary.main
  }
})

export const navMenuBodyStyles = {
  container: {
    padding: 0
  }
}

export const navMenuHeaderStyles = {
  container: {
    padding: [[svpMargins / 1.8, svpMargins]],
    [svpTheme.breakpoints.down('sm')]: {
      padding: 0
    }
  }
}
