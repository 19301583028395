import React, {PureComponent} from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import {OrderNowButtonForm} from '../food-delivery-order-now-button/index'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import dictionary from '../../dictionary/en'
import {getKey} from '../../libs/dictionary'
import {loggedTitles} from '../../styles/component-styles/food-delivery-logged-main-content'
import {withStyles} from '@material-ui/core/styles'

class LoggedMainContent extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape({
      container: PropTypes.string,
      buttonContainer: PropTypes.string,
      smallTitle: PropTypes.string,
      subTitleContainer: PropTypes.string,
      title: PropTypes.string
    }),
    user: PropTypes.shape({})
  }

  render () {
    const {classes, user} = this.props
    return (
      <Grid container direction="column" spacing={0} justify="center" className={classes.container}>
        <Grid container className={classes.subTitleContainer}>
          <Grid item xs={12}>
            <Typography className={classes.title}>{getKey(dictionary,
              'sections.activity.components.foodDeliverySearchHeroHome.titleSubTitle.lineOne'
            )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>{getKey(dictionary,
              'sections.activity.components.foodDeliverySearchHeroHome.titleSubTitle.lineTwo'
            )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>{getKey(dictionary,
              'sections.activity.components.foodDeliverySearchHeroHome.titleSubTitle.lineThree'
            )}
            </Typography>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} className={classes.buttonContainer}>
            <OrderNowButtonForm user={user}/>
          </Grid>
        </Hidden>
      </Grid>)
  }
}
export default withStyles(loggedTitles)(LoggedMainContent)
