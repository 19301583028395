import {ActivityProductFilter} from '@iseatz/ams-activities'
import {WhenWidthProps} from './when-width-props/when-width-props'
const directionRowAndFitFormtoRow = originalProps => (
  {
    ...originalProps,
    direction: 'row',
    DateContainerProps: {
      ...originalProps.DateContainerProps,
      xs: 6
    },
    DropdownContainerProps: {
      ...originalProps.DropdownContainerProps,
      xs: 6
    },
    ButtonContainerProps: {
      ...originalProps.ButtonContainerProps,
      xs: 12
    }
  })
const enhancer = WhenWidthProps({sm: directionRowAndFitFormtoRow, xs: directionRowAndFitFormtoRow})
export default enhancer(ActivityProductFilter)
