import {
  bold,
  extraBold,
  mediumTextLetterSpacing
} from '../font'
import {buttons, labels} from '../base-styles/'
import {linkBlue, ruleGrey} from '../colors'
import {
  lvpColumns,
  lvpMargins,
  lvpPageGutters,
  mvpColumns,
  mvpSvpPageGutters,
  svpMargins,
  svpPadding
} from '../spacing'

const heroStyling = ({palette, typography, breakpoints}) => ({
  heroSearchContainer: {
    [breakpoints.up('lg')]: {
      padding: [[0, lvpPageGutters]]
    },
    [breakpoints.only('md')]: {
      paddingLeft: mvpSvpPageGutters
    }
  },

  dialogHeader: {
    padding: [[lvpMargins, svpMargins, 0, 0]]
  },

  searchbar: {
    marginTop: svpMargins,
    padding: lvpColumns,
    width: '50rem'
  },

  heroContainer: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },

  mainHeroContainer: {
    [breakpoints.down('sm')]: {
      flexGrow: 1,
      alignItems: 'flex-start'
    }
  },

  title: {
    marginTop: '1.75rem',
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    textTransform: 'uppercase',
    fontSize: typography.h6.fontSize,
    fontWeight: bold,
    [breakpoints.down('sm')]: {
      fontWeight: extraBold,
      marginTop: '1rem',
      fontSize: typography.fontSize
    }
  },

  button: {
    padding: '0.7rem',
    backgroundColor: palette.primary.main
  },

  subtitle: {
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    textTransform: 'uppercase',
    fontSize: typography.pxToRem(typography.headline.fontSize),
    fontWeight: bold,
    [breakpoints.down('sm')]: {
      fontWeight: extraBold,
      fontSize: typography.pxToRem(30)
    }
  },

  searchContainer: {
    maxWidth: '23rem',
    backgroundColor: palette.common.white,
    padding: svpPadding,
    marginTop: '1.5rem'
  },

  homeTitleContainer: {
    marginLeft: 56,
    display: 'block',
    [breakpoints.up('md')]: {
      paddingRight: '1.5rem'
    },
    [breakpoints.down('md')]: {
      marginLeft: 8
    },
    [breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: '1rem',
      display: 'block',
      textAlign: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      flexGrow: 1
    }
  },

  searchBarBase: {
    border: `solid 1px ${ruleGrey}`,
    borderBottom: 'none',
    margin: [[0, mvpSvpPageGutters]]
  },

  homeTitle: {
    fontSize: typography.headline.fontSize + 8,
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    lineHeight: 1,
    fontWeight: bold,
    [breakpoints.down('md')]: {
      fontSize: 20,
      letterSpacing: 2
    },
    [breakpoints.down('sm')]: {
      fontSize: 14,
      letterSpacing: 1.2
    }
  },

  homeSubtitle: {
    fontSize: typography.headline.fontSize + 8,
    color: palette.common.white,
    fontFamily: typography.button.fontFamily,
    lineHeight: 1,
    fontWeight: bold,
    [breakpoints.down('md')]: {
      fontSize: typography.headline.fontSize
    },
    [breakpoints.down('sm')]: {
      fontSize: 30
    }
  },

  homeHeroSearchTitle: {
    color: palette.primary.dark,
    fontFamily: typography.button.fontFamily,
    fontWeight: bold,
    fontSize: typography.body1.fontSize,
    padding: mvpColumns
  },

  divider: {
    margin: [[lvpMargins, 'auto']],
    maxWidth: '10rem'
  },

  link: {
    ...labels.hoverLink,
    fontSize: 12,
    color: palette.primary,
    fontFamily: typography.fontFamily
  },

  homeSearchButton: {
    ...buttons.actionHover,
    height: '3rem',
    fontFamily: typography.button,
    color: linkBlue,
    backgroundColor: palette.primary.light
  },

  heroRewardText: {
    fontSize: typography.caption.fontSize,
    letterSpacing: mediumTextLetterSpacing
  },
  heroSearchItem: {
    [breakpoints.only('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
})

export {heroStyling}
