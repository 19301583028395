import {lvpGutters, svpGutters} from '../spacing'
import {backgroundGrey} from '../colors'

export const forms = {
  cardForm: {
    backgroundColor: backgroundGrey,
    marginTop: svpGutters,
    width: '100%'
  },
  groupContainerForm: {
    marginTop: lvpGutters
  }
}
