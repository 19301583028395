import {bodyTextSize, bold, highlightsTextSize, subheaderTextSize} from '../font'
import {linkBlue, titaniumMember, wyzardSuitBlue} from '../colors'
import {lvpPadding, mvpGutters, noPadding, svpGutters} from '../spacing'
import {svpTheme} from '../theme'

export const confirmationHeader = {
  text: {
    item: {
      paddingLeft: 42,
      [svpTheme.breakpoints.down('sm')]: {
        paddingLeft: '1rem'
      }
    }
  },
  buttonContainer: {
    container: {
      [svpTheme.breakpoints.up('md')]: {
        justifyContent: 'flex-end'
      }
    }
  },
  container: {
    container: {
      padding: [[lvpPadding, 56, noPadding, 56]],
      [svpTheme.breakpoints.down('md')]: {
        margin: 0,
        padding: mvpGutters
      },
      [svpTheme.breakpoints.down('sm')]: {
        margin: 0,
        padding: '1rem'
      }
    }
  },
  title: {
    root: {
      color: wyzardSuitBlue,
      fontSize: highlightsTextSize,
      fontWeight: bold,
      [svpTheme.breakpoints.down('sm')]: {
        fontSize: subheaderTextSize,
        paddingTop: lvpPadding
      }
    }
  },
  subtitle: {
    root: {
      color: titaniumMember,
      fontSize: svpGutters,
      [svpTheme.breakpoints.down('sm')]: {
        color: wyzardSuitBlue,
        fontSize: bodyTextSize
      }
    }
  },
  buttonText: {
    root: {
      color: linkBlue,
      padding: [[noPadding, mvpGutters, noPadding, 10]]
    }
  }
}
