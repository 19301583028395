import {CartBreakdownBase} from './cart-breakdown'
import {withDependencies} from '@iseatz/framework'

const reservationDependencies = {
  ContainerComponent: 'node:ams.activities.reservation.cartBreakdown.container',
  DividerComponent: 'node:ams.activities.reservation.cartBreakdown.divider',
  DividerContainerComponent: 'node:ams.activities.reservation.cartBreakdown.dividerContainer',
  HeaderComponent: 'node:ams.activities.reservation.cartBreakdown.header',
  FooterTextComponent: 'node:ams.activities.reservation.cartBreakdown.footerText',
  FooterContainerComponent: 'node:ams.activities.reservation.cartBreakdown.footerContainer',
  PriceComponent: 'node:ams.activities.reservation.cartBreakdown.footerPrice',
  PointsComponent: 'node:ams.activities.reservation.cartBreakdown.footerPoints',
  ProductComponent: 'node:ams.activities.reservation.cartBreakdown.product',
  PriceBreakdownComponent: 'node:ams.activities.reservation.cartBreakdown.priceBreakdown'
}

const ReservationCartBreakdown = withDependencies(reservationDependencies)(CartBreakdownBase)

export {ReservationCartBreakdown}
